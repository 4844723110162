/*
Author       : Dreamguys
Template Name: Doccure - Surgery Bootstrap Template
Version      : 1.0
*/

/*============================
 [Table of CSS]

1. General
2. Table
3. Bootstrap Classes
4. Avatar
5. Select2
6. Nav Tabs
7. Modal
8. Components
9. Slick Slider
10. Focus Label
11. Header
12. Mobile Menu
13. Footer
14. Login
15. Home
16. Search
17. Doctor Profile
18. Booking
19. Checkout
20. Booking Success
21. Invoice View
22. Schedule Timings
23. Doctor Dashboard
24. Patient Profile
25. Add Billing
26. Chat
27. Doctor Profile Settings
28. Calendar
29. Patient Dashboard
30. Profile Settings
31. Appoitment List
32. Reviews
33. Voice call
34. Video Call
35. Outgoing Call
36. Incoming Call
37. Terms and Conditions
38. Blog
39. Blog Details
40. Blog Grid
41. Map List & Grid
42. Loader
43. About Us
44. Contact Us
45. Responsive


========================================*/

/*-----------------
	1. General
-----------------------*/

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/MaterialIcons-Regular.eot);
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url(../fonts/MaterialIcons-Regular.woff2) format("woff2"),
    url(../fonts/MaterialIcons-Regular.woff) format("woff"),
    url(../fonts/MaterialIcons-Regular.ttf) format("truetype");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

html {
  height: 100%;
}

body {
  background-color: #fff;
  color: #363636;
  font-family: "Poppins", sans-serif;
  font-size: 0.9375rem;
  height: 100%;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #272b41;
  font-weight: 600;
}

.h1,
h1 {
  font-size: 2.25rem;
}

.h2,
h2 {
  font-size: 1.875rem;
}

.h3,
h3 {
  font-size: 1.5rem;
}

.h4,
h4 {
  font-size: 1.125rem;
}

.h5,
h5 {
  font-size: 1rem;
}

.h6,
h6 {
  font-size: 0.875rem;
}

p {
  line-height: 26px;
}

a {
  color: #2e3842;
  text-decoration: none;
}

a:hover {
  color: #2f7ba4;
}

a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

input,
button,
a {
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
}

button:focus {
  outline: 0;
}

input[type="text"],
input[type="password"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.btn.focus,
.btn:focus {
  box-shadow: inherit;
}

.form-control {
  border-color: #dcdcdc;
  color: #333;
  font-size: 15px;
  min-height: 46px;
  padding: 6px 15px;
}

.sm-width-25 .css-13cymwt-control {
  border-color: #dcdcdc;
  color: #333;
  font-size: 15px;
  min-height: 50px;
  padding: 6px 15px;
  border-radius: 7px;
}

.sm-width-25 .css-t3ipsp-control {
  min-height: 50px !important;
  border: 0px !important;
  box-shadow: inherit !important;
  outline: inherit !important;
  border-radius: 7px;
}

.table .form-control {
  font-size: 14px;
  min-height: 38px;
}

.container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

.form-control::-webkit-input-placeholder {
  color: #858585;
  font-size: 14px;
}

.form-control::-moz-placeholder {
  color: #858585;
  font-size: 14px;
}

.form-control:-ms-input-placeholder {
  color: #858585;
  font-size: 14px;
}

.form-control::-ms-input-placeholder {
  color: #858585;
  font-size: 14px;
}

.form-control::placeholder {
  color: #858585;
  font-size: 14px;
}

.list-group-item {
  border: 1px solid #f0f0f0;
}

.content {
  min-height: 200px;
  padding: 30px 20px;
}

label {
  margin-bottom: 0.5rem;
  display: inline-block;
}

/*-----------------
	2. Table
-----------------------*/

.table {
  color: #272b41;
  max-width: 100%;
  margin-bottom: 0;
  width: 100%;
}

.table-striped > tbody > tr:nth-of-type(2n + 1) {
  background-color: #f8f9fa;
}

.table.no-border > tbody > tr > td,
.table > tbody > tr > th,
.table.no-border > tfoot > tr > td,
.table.no-border > tfoot > tr > th,
.table.no-border > thead > tr > td,
.table.no-border > thead > tr > th {
  border-top: 0;
  padding: 10px 8px;
}

.table-nowrap td,
.table-nowrap th {
  white-space: nowrap;
}

.table.dataTable {
  border-collapse: collapse !important;
}

table.table td h2 {
  display: inline-block;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

table.table td h2.table-avatar {
  align-items: center;
  display: inline-flex;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  white-space: nowrap;
}

table.table td h2 a {
  color: #272b41;
}

table.table td h2 a:hover {
  color: #2f7ba4;
}

table.table td h2 span {
  color: #888;
  display: block;
  font-size: 12px;
  margin-top: 3px;
}

.table thead {
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}

.table thead tr th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.table tbody tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.table tbody tr:last-child {
  border-color: transparent;
}

.table.table-center td,
.table.table-center th {
  vertical-align: middle;
}

.table-hover tbody tr:hover {
  background-color: #f7f7f7;
}

.table-hover tbody tr:hover td {
  color: #474648;
}

.table-striped thead tr {
  border-color: transparent;
}

.table-striped tbody tr {
  border-color: transparent;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(255, 255, 255, 0.3);
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(235, 235, 235, 0.4);
}

.table-bordered {
  border: 1px solid rgba(0, 0, 0, 0.05) !important;
}

.table-bordered th,
.table-bordered td {
  border-color: rgba(0, 0, 0, 0.05);
}

.card-table .card-body {
  padding: 0;
}

.card-table .card-body .table > thead > tr > th {
  border-top: 0;
}

.card-table .table tr td:first-child,
.card-table .table tr th:first-child {
  padding-left: 1.5rem;
}

.card-table .table tr td:last-child,
.card-table .table tr th:last-child {
  padding-right: 1.5rem;
}

.card-table .table td,
.card-table .table th {
  border-top: 1px solid #e2e5e8;
  padding: 1rem 0.75rem;
  white-space: nowrap;
  font-weight: 600;
}

.table > :not(:first-child) {
  border-top: 1px solid currentcolor;
}

/*-----------------
	3. Bootstrap Classes
-----------------------*/

.btn.focus,
.btn:focus {
  box-shadow: unset;
}

.btn-white {
  background-color: #fff;
  border: 1px solid #ccc;
  color: #272b41;
}

.btn.btn-rounded {
  border-radius: 50px;
}

.activeBtn,
.badge-primary {
  background-color: #2f7ba4 !important;
}

a.activeBtn:focus,
a.activeBtn:hover,
button.activeBtn:focus,
button.activeBtn:hover {
  background-color: #2f7ba4 !important;
}

.bg-success,
.badge-success {
  background-color: #00e65b !important;
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #02c04d !important;
}

.bg-info,
.badge-info {
  background-color: #032c4f !important;
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #028ee1 !important;
}

.bg-warning,
.badge-warning {
  background-color: #ffbc34 !important;
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #e9ab2e !important;
}

.bg-danger,
.badge-danger {
  background-color: #ff0100 !important;
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #e63333 !important;
}

.bg-white {
  background-color: #fff;
}

.bg-purple,
.badge-purple {
  background-color: #9368e9 !important;
}

.text-primary,
.dropdown-menu > li > a.text-primary {
  color: #2f7ba4 !important;
}

.text-success,
.dropdown-menu > li > a.text-success {
  color: #00cc52 !important;
}

.text-danger,
.dropdown-menu > li > a.text-danger {
  color: #ff0100 !important;
}

.text-info,
.dropdown-menu > li > a.text-info {
  color: #2f7ba4 !important;
}

.text-warning,
.dropdown-menu > li > a.text-warning {
  color: #ffbc34 !important;
}

.text-purple,
.dropdown-menu > li > a.text-purple {
  color: #7460ee !important;
}

.text-muted {
  color: #757575 !important;
}

.btn-primary {
  background-color: #2f7ba4;
  border: 1px solid #2f7ba4;
  border-radius: 50px;
  color: #fff !important;
  padding: 12px 30px 9px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
  background-color: #47466c;
  border: 1px solid #47466c;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
  background-color: #47466c;
  border: 1px solid #47466c;
}

.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle {
  background-color: #47466c;
  border-color: #47466c;
  color: #fff;
}

.btn-primary.active:focus:not(:disabled):not(.disabled),
.btn-primary:active:focus:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #2f7ba4;
  border-color: #2f7ba4;
  color: #fff;
}

.btn-secondary.active:focus:not(:disabled):not(.disabled),
.btn-secondary:active:focus:not(:disabled):not(.disabled),
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-success {
  background-color: #00e65b;
  border: 1px solid #00e65b;
}

.btn-success:hover,
.btn-success:focus,
.btn-success.active,
.btn-success:active,
.open > .dropdown-toggle.btn-success {
  background-color: #00cc52;
  border: 1px solid #00cc52;
  color: #fff;
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover {
  background-color: #00cc52;
  border: 1px solid #00cc52;
}

.btn-success.active:not(:disabled):not(.disabled),
.btn-success:active:not(:disabled):not(.disabled),
.show > .btn-success.dropdown-toggle {
  background-color: #00cc52;
  border-color: #00cc52;
  color: #fff;
}

.btn-success.active:focus:not(:disabled):not(.disabled),
.btn-success:active:focus:not(:disabled):not(.disabled),
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-success.disabled,
.btn-success:disabled {
  background-color: #00e65b;
  border-color: #00e65b;
  color: #fff;
}

.btn-info {
  background-color: #009efb;
  border: 1px solid #009efb;
}

.btn-info:hover,
.btn-info:focus,
.btn-info.active,
.btn-info:active,
.open > .dropdown-toggle.btn-info {
  background-color: #028ee1;
  border: 1px solid #028ee1;
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover {
  background-color: #028ee1;
  border: 1px solid #028ee1;
}

.btn-info.active:not(:disabled):not(.disabled),
.btn-info:active:not(:disabled):not(.disabled),
.show > .btn-info.dropdown-toggle {
  background-color: #028ee1;
  border-color: #028ee1;
  color: #fff;
}

.btn-info.active:focus:not(:disabled):not(.disabled),
.btn-info:active:focus:not(:disabled):not(.disabled),
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-info.disabled,
.btn-info:disabled {
  background-color: #009efb;
  border-color: #009efb;
  color: #fff;
}

.btn-warning {
  background-color: #ffbc34;
  border: 1px solid #ffbc34;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning.active,
.btn-warning:active,
.open > .dropdown-toggle.btn-warning {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e;
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e;
}

.btn-warning.active:not(:disabled):not(.disabled),
.btn-warning:active:not(:disabled):not(.disabled),
.show > .btn-warning.dropdown-toggle {
  background-color: #e9ab2e;
  border-color: #e9ab2e;
  color: #fff;
}

.btn-warning.active:focus:not(:disabled):not(.disabled),
.btn-warning:active:focus:not(:disabled):not(.disabled),
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-warning.disabled,
.btn-warning:disabled {
  background-color: #ffbc34;
  border-color: #ffbc34;
  color: #fff;
}

.btn-danger {
  background-color: #ff0100;
  border: 1px solid #ff0100;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger.active,
.btn-danger:active,
.open > .dropdown-toggle.btn-danger {
  background-color: #e63333;
  border: 1px solid #e63333;
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover {
  background-color: #e63333;
  border: 1px solid #e63333;
}

.btn-danger.active:not(:disabled):not(.disabled),
.btn-danger:active:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle {
  background-color: #e63333;
  border-color: #e63333;
  color: #fff;
}

.btn-danger.active:focus:not(:disabled):not(.disabled),
.btn-danger:active:focus:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-danger.disabled,
.btn-danger:disabled {
  background-color: #f62d51;
  border-color: #f62d51;
  color: #fff;
}

.btn-light.active:focus:not(:disabled):not(.disabled),
.btn-light:active:focus:not(:disabled):not(.disabled),
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-dark.active:focus:not(:disabled):not(.disabled),
.btn-dark:active:focus:not(:disabled):not(.disabled),
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-outline-primary {
  color: #2f7ba4;
  border: 2px solid #2f7ba4;
  border-radius: 50px;
  color: #2f7ba4 !important;
  padding: 12px 30px 9px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
}

.btn-outline-primary:hover {
  background-color: #2f7ba4;
  border-color: #2f7ba4;
  color: #fff !important;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: none;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #2f7ba4;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: #2f7ba4;
  border-color: #2f7ba4;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-success {
  color: #00e65b;
  border-color: #00e65b;
}

.btn-outline-success:hover {
  background-color: #00e65b;
  border-color: #00e65b;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: none;
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #00e65b;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  background-color: #00e65b;
  border-color: #00e65b;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-info {
  color: #009efb;
  border-color: #009efb;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #009efb;
  border-color: #009efb;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: none;
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  background-color: transparent;
  color: #009efb;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  background-color: #009efb;
  border-color: #009efb;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-warning {
  color: #ffbc34;
  border-color: #ffbc34;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffbc34;
  border-color: #ffbc34;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: none;
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  background-color: transparent;
  color: #ffbc34;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffbc34;
  border-color: #ffbc34;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-danger {
  color: #ff0100;
  border-color: #ff0100;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #ff0100;
  border-color: #ff0100;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: none;
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  background-color: transparent;
  color: #ff0100;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  background-color: #ff0100;
  border-color: #ff0100;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-light {
  color: #ababab;
  border-color: #e6e6e6;
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #ababab;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background-color: #2f7ba4;
  border-color: #2f7ba4;
}

.pagination > li > a,
.pagination > li > span {
  color: #2f7ba4;
}

.page-link:hover {
  color: #2f7ba4;
}

.page-link:focus {
  box-shadow: unset;
}

.page-item.active .page-link {
  background-color: #2f7ba4;
  border-color: #2f7ba4;
}

.dropdown-menu {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  box-shadow: inherit;
  font-size: 14px;
  transform-origin: left top 0;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #0de0fe;
}

.navbar-nav .open .dropdown-menu {
  border: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.card {
  border: 1px solid #f0f0f0;
  margin-bottom: 1.875rem;
}

.card-body {
  padding: 1.5rem;
}

.card-title {
  margin-bottom: 15px;
}

.card-header {
  border-bottom: 1px solid #f0f0f0;
  padding: 1rem 1.5rem;
}

.card-footer {
  background-color: #fff;
  border-top: 1px solid #f0f0f0;
  padding: 1rem 1.5rem;
}

.card .card-header {
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
}

.card .card-header .card-title {
  margin-bottom: 0;
}

.btn-light {
  border-color: #e6e6e6;
  color: #a6a6a6;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #2f7ba4;
  text-shadow: unset;
}

.bootstrap-datetimepicker-widget table td.today::before {
  border-bottom-color: #2f7ba4;
}

.bg-info-light {
  background-color: rgb(226 62 86 / 0.1) !important;
  color: #2f7ba4 !important;
}

.activeBtn-light {
  background-color: rgba(17, 148, 247, 0.12) !important;
  color: #2196f3 !important;
}

.bg-danger-light {
  background-color: rgba(242, 17, 54, 0.12) !important;
  color: #e63c3c !important;
}

.bg-warning-light {
  background-color: rgba(255, 152, 0, 0.12) !important;
  color: #f39c12 !important;
}

.bg-success-light {
  background-color: rgba(15, 183, 107, 0.12) !important;
  color: #26af48 !important;
}

.bg-purple-light {
  background-color: rgba(197, 128, 255, 0.12) !important;
  color: #c580ff !important;
}

.bg-default-light {
  background-color: rgba(40, 52, 71, 0.12) !important;
  color: #283447 !important;
}

.text-xs {
  font-size: 0.75rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
}

.text-lg {
  font-size: 1.25rem !important;
}

.text-xl {
  font-size: 1.5rem !important;
}

.form-control:focus {
  border-color: #bbb;
  box-shadow: none;
  outline: 0 none;
}

.form-group {
  margin-bottom: 1.25rem;
}

.form-group label {
  font-weight: 600;
}

/*-----------------
	4. Avatar
-----------------------*/

.avatar {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 3rem;
}

.avatar > img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.avatar-title {
  width: 100%;
  height: 100%;
  background-color: #2f7ba4;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.avatar-away::before,
.avatar-offline::before,
.avatar-online::before {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 25%;
  height: 25%;
  border-radius: 50%;
  content: "";
  border: 2px solid #fff;
}

.avatar-online::before {
  background-color: #00e65b;
}

.avatar-offline::before {
  background-color: #ff0100;
}

.avatar-away::before {
  background-color: #ffbc34;
}

.avatar .border {
  border-width: 3px !important;
}

.avatar .rounded {
  border-radius: 6px !important;
}

.avatar .avatar-title {
  font-size: 18px;
}

.avatar-xs {
  width: 1.65rem;
  height: 1.65rem;
}

.avatar-xs .border {
  border-width: 2px !important;
}

.avatar-xs .rounded {
  border-radius: 4px !important;
}

.avatar-xs .avatar-title {
  font-size: 12px;
}

.avatar-xs.avatar-away::before,
.avatar-xs.avatar-offline::before,
.avatar-xs.avatar-online::before {
  border-width: 1px;
}

.avatar-sm {
  width: 2.5rem;
  height: 2.5rem;
}

.avatar-sm .border {
  border-width: 3px !important;
}

.avatar-sm .rounded {
  border-radius: 4px !important;
}

.avatar-sm .avatar-title {
  font-size: 15px;
}

.avatar-sm.avatar-away::before,
.avatar-sm.avatar-offline::before,
.avatar-sm.avatar-online::before {
  border-width: 2px;
}

.avatar-lg {
  width: 3.75rem;
  height: 3.75rem;
}

.avatar-lg .border {
  border-width: 3px !important;
}

.avatar-lg .rounded {
  border-radius: 8px !important;
}

.avatar-lg .avatar-title {
  font-size: 24px;
}

.avatar-lg.avatar-away::before,
.avatar-lg.avatar-offline::before,
.avatar-lg.avatar-online::before {
  border-width: 3px;
}

.avatar-xl {
  width: 5rem;
  height: 5rem;
}

.avatar-xl .border {
  border-width: 4px !important;
}

.avatar-xl .rounded {
  border-radius: 8px !important;
}

.avatar-xl .avatar-title {
  font-size: 28px;
}

.avatar-xl.avatar-away::before,
.avatar-xl.avatar-offline::before,
.avatar-xl.avatar-online::before {
  border-width: 4px;
}

.avatar-xxl {
  width: 5.125rem;
  height: 5.125rem;
}

.avatar-xxl .border {
  border-width: 6px !important;
}

.avatar-xxl .rounded {
  border-radius: 8px !important;
}

.avatar-xxl .avatar-title {
  font-size: 30px;
}

.avatar-xxl.avatar-away::before,
.avatar-xxl.avatar-offline::before,
.avatar-xxl.avatar-online::before {
  border-width: 4px;
}

.avatar-group {
  display: inline-flex;
}

.avatar-group .avatar + .avatar {
  margin-left: -0.75rem;
}

.avatar-group .avatar-xs + .avatar-xs {
  margin-left: -0.40625rem;
}

.avatar-group .avatar-sm + .avatar-sm {
  margin-left: -0.625rem;
}

.avatar-group .avatar-lg + .avatar-lg {
  margin-left: -1rem;
}

.avatar-group .avatar-xl + .avatar-xl {
  margin-left: -1.28125rem;
}

.avatar-group .avatar:hover {
  z-index: 1;
}

/*-----------------
	5. Select2
-----------------------*/

.select2-results__option {
  padding: 6px 15px;
}

.select2-container .select2-selection--single {
  border: 1px solid #dcdcdc;
  height: 46px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 44px;
  right: 7px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #dcdcdc transparent transparent;
  border-style: solid;
  border-width: 6px 6px 0;
  height: 0;
  left: 50%;
  margin-left: -10px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #dcdcdc;
  border-width: 0 6px 6px;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 30px;
  padding-left: 15px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #272b41;
  font-size: 15px;
  font-weight: normal;
  line-height: 44px;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #2f7ba4;
}

.select2-container--default .select2-selection--multiple {
  border: 1px solid #dcdcdc;
  min-height: 46px;
}

.select2-dropdown {
  border-color: #dcdcdc;
}

/*-----------------
	6. Nav Tabs
-----------------------*/

.nav-tabs {
  border-bottom: 1px solid #f0f0f0;
}

.card-header-tabs {
  border-bottom: 0;
}

.nav-tabs > li > a {
  margin-right: 0;
  color: #888;
  border-radius: 0;
}

.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  border-color: transparent;
  color: #272b41;
}

.nav-tabs.nav-tabs-solid > li > a {
  color: #272b41;
}

.nav-tabs.nav-tabs-solid > .active > a,
.nav-tabs.nav-tabs-solid > .active > a:hover,
.nav-tabs.nav-tabs-solid > .active > a:focus {
  background-color: #2f7ba4;
  border-color: #2f7ba4;
  color: #fff;
}

.tab-content {
  padding-top: 20px;
}

.nav-tabs .nav-link {
  border-radius: 0;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  background-color: #eee;
  border-color: transparent;
  color: #272b41;
}

.nav-tabs.nav-justified > li > a {
  border-radius: 0;
  margin-bottom: 0;
}

.nav-tabs.nav-justified > li > a:hover,
.nav-tabs.nav-justified > li > a:focus {
  border-bottom-color: #ddd;
}

.nav-tabs.nav-justified.nav-tabs-solid > li > a {
  border-color: transparent;
}

.nav-tabs.nav-tabs-solid > li > a {
  color: #272b41;
}

.nav-tabs.nav-tabs-solid > li > a.active,
.nav-tabs.nav-tabs-solid > li > a.active:hover,
.nav-tabs.nav-tabs-solid > li > a.active:focus {
  background-color: #2f7ba4;
  border-color: #2f7ba4;
  color: #fff;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
  border-radius: 50px;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a {
  border-radius: 50px;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:hover,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:focus {
  border-radius: 50px;
}

.nav-tabs-justified > li > a {
  border-radius: 0;
  margin-bottom: 0;
}

.nav-tabs-justified > li > a:hover,
.nav-tabs-justified > li > a:focus {
  border-bottom-color: #ddd;
}

.nav-tabs-justified.nav-tabs-solid > li > a {
  border-color: transparent;
}

.nav-tabs.nav-justified.nav-tabs-top {
  border-bottom: 1px solid #ddd;
}

.nav-tabs.nav-justified.nav-tabs-top > li > a,
.nav-tabs.nav-justified.nav-tabs-top > li > a:hover,
.nav-tabs.nav-justified.nav-tabs-top > li > a:focus {
  border-width: 2px 0 0 0;
}

.nav-tabs.nav-tabs-top > li {
  margin-bottom: 0;
}

.nav-tabs.nav-tabs-top > li > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
  border-width: 2px 0 0 0;
}

.nav-tabs.nav-tabs-top > li.open > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
  border-top-color: #ddd;
}

.nav-tabs.nav-tabs-top > li + li > a {
  margin-left: 1px;
}

.nav-tabs.nav-tabs-top > li > a.active,
.nav-tabs.nav-tabs-top > li > a.active:hover,
.nav-tabs.nav-tabs-top > li > a.active:focus {
  border-top-color: #032c4f;
}

.nav-tabs.nav-tabs-bottom > li > a.active,
.nav-tabs.nav-tabs-bottom > li > a.active:hover,
.nav-tabs.nav-tabs-bottom > li > a.active:focus {
  border-bottom-width: 2px;
  border-color: transparent;
  border-bottom-color: #032c4f;
  background-color: transparent;
  transition: none 0s ease 0s;
  -moz-transition: none 0s ease 0s;
  -o-transition: none 0s ease 0s;
  -ms-transition: none 0s ease 0s;
  -webkit-transition: none 0s ease 0s;
}

.nav-tabs.nav-tabs-solid {
  background-color: #fafafa;
  border: 0;
}

.nav-tabs.nav-tabs-solid > li {
  margin-bottom: 0;
}

.nav-tabs.nav-tabs-solid > li > a {
  border-color: transparent;
}

.nav-tabs.nav-tabs-solid > li > a:hover,
.nav-tabs.nav-tabs-solid > li > a:focus {
  background-color: #f5f5f5;
}

.nav-tabs.nav-tabs-solid > .open:not(.active) > a {
  background-color: #f5f5f5;
  border-color: transparent;
}

.nav-tabs-justified.nav-tabs-top {
  border-bottom: 1px solid #ddd;
}

.nav-tabs-justified.nav-tabs-top > li > a,
.nav-tabs-justified.nav-tabs-top > li > a:hover,
.nav-tabs-justified.nav-tabs-top > li > a:focus {
  border-width: 2px 0 0 0;
}

/*-----------------
	7. Modal
-----------------------*/

.modal {
  -webkit-overflow-scrolling: touch;
}

.modal-footer.text-center {
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.modal-footer.text-left {
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
}

.custom-modal .modal-content {
  border: 0;
  border-radius: 10px;
}

.custom-modal .modal-header {
  padding: 1.25rem;
}

.custom-modal .modal-footer {
  padding: 1.25rem;
}

.custom-modal .modal-body {
  padding: 1.25rem;
}

.custom-modal .close {
  background-color: #a0a0a0;
  border-radius: 50%;
  color: #fff;
  font-size: 17px;
  height: 20px;
  line-height: 20px;
  margin: 0;
  opacity: 1;
  padding: 0;
  position: absolute;
  right: 20px;
  top: 26px;
  width: 20px;
  z-index: 99;
}

.custom-modal .modal-title {
  font-size: 20px;
}

.modal-backdrop.show {
  opacity: 0.4;
  transition-duration: 400ms;
}

.modal .card {
  box-shadow: unset;
}

/*-----------------
	8. Components
-----------------------*/

.comp-header {
  margin-bottom: 1.875rem;
}

.comp-header .comp-title {
  color: #272b41;
}

.line {
  background-color: #2f7ba4;
  height: 2px;
  margin: 0;
  width: 60px;
}

.comp-buttons .btn {
  margin-bottom: 5px;
}

.pagination-box .pagination {
  margin-top: 0;
}

.comp-dropdowns .btn-group {
  margin-bottom: 5px;
}

.progress-example .progress {
  margin-bottom: 1.5rem;
}

.progress-xs {
  height: 4px;
}

.progress-sm {
  height: 15px;
}

.progress.progress-sm {
  height: 6px;
}

.progress.progress-md {
  height: 8px;
}

.progress.progress-lg {
  height: 18px;
}

/*-----------------
	9. Slick Slider
-----------------------*/
/*
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
} */

/* .slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
} */

/* .slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
} */

/* .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
} */

/* .slick-initialized .slick-slide {
  display: block;
  margin:0 10px;
} */

/* .slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-prev,
.slick-next {
  font-size: 20px;
  line-height: 0;
  color: #7f8384;
  position: absolute;
  display: block;
  width: auto;
  height: auto; */
/* padding: 0; */
/* -webkit-transform: translate(0, -50%); */
/* -ms-transform: translate(0, -50%); */
/* transform: translate(0, -50%); */
/* box-shadow: 1px 6px 14px rgba(0, 0, 0, 0.2); */
/* background: #fff; */
/* border-radius: 5px; */
/* cursor: pointer; */
/* border: none; */
/* outline: none; */
/* background: #e3455c; */
/* }

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  background-color: translate;
  color: #fff;
  opacity: 1;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-disabled {
  display: none !important;
}
.slick-prev {
}

.slick-prev:before,
.slick-next:before {
  display: none;
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #6c757d;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: auto;
  z-index: 1;
  left: -8px;
  right: infinite;
}

[dir="rtl"] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  display: none;
  content: "→";
}

.slick-next {
  right: -8;
}

[dir="rtl"] .slick-next {
  right: auto;
  left: -25px;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
} */

/*-----------------
	10. Focus Label
-----------------------*/

.form-focus {
  height: 50px;
  position: relative;
}

.form-focus .focus-label {
  font-size: 14px;
  font-weight: 400;
  pointer-events: none;
  position: absolute;
  transform: translate3d(0, 22px, 0) scale(1);
  transform-origin: left top;
  transition: 240ms;
  left: 12px;
  top: -8px;
  z-index: 1;
  color: #b8b8b8;
  margin-bottom: 0;
}

.form-focus.focused .focus-label {
  opacity: 1;
  top: -18px;
  font-size: 12px;
  z-index: 1;
}

.form-focus .form-control:focus ~ .focus-label,
.form-focus .form-control:-webkit-autofill ~ .focus-label {
  opacity: 1;
  font-weight: 400;
  top: -18px;
  font-size: 12px;
  z-index: 1;
}

.form-focus .form-control {
  height: 50px;
  padding: 21px 12px 6px;
}

.form-focus .form-control::-webkit-input-placeholder {
  color: transparent;
  -webkit-transition: 240ms;
  transition: 240ms;
}

.form-focus .form-control:focus::-webkit-input-placeholder {
  -webkit-transition: none;
  transition: none;
}

.form-focus.focused .form-control::-webkit-input-placeholder {
  color: #bbb;
}

.form-focus.select-focus .focus-label {
  opacity: 1;
  font-weight: 300;
  top: -20px;
  font-size: 12px;
  z-index: 1;
}

.form-focus .select2-container .select2-selection--single {
  border: 1px solid #e3e3e3;
  height: 50px;
}

.form-focus
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 48px;
  right: 7px;
}

.form-focus
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #ccc transparent transparent;
  border-style: solid;
  border-width: 6px 6px 0;
  height: 0;
  left: 50%;
  margin-left: -10px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.form-focus
  .select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #ccc;
  border-width: 0 6px 6px;
}
.ct-cancel-spacing {
  /* margin-right: 10px; */
  min-width: 195px;
}

.form-focus
  .select2-container
  .select2-selection--single
  .select2-selection__rendered {
  padding-right: 30px;
  padding-left: 12px;
  padding-top: 10px;
}

.form-focus
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #676767;
  font-size: 14px;
  font-weight: normal;
  line-height: 38px;
}

.form-focus
  .select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #fc6075;
}

/*-----------------
	11. Header
-----------------------*/

.top-header {
  background-color: #2f7ba4;
  padding: 12px 15px;
}

.top-header ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
  color: #fff;
}

.top-header ul li:not(:last-child) {
  margin-right: 10px;
}

.top-header ul li .fas {
  margin-right: 10px;
}

.top-header .form-group {
  margin: 0px;
}

.top-header .form-control {
  font-size: 13px;
  min-height: 34px;
  padding: 6px;
  border-radius: 50px;
  width: 90px;
}

.top-header .social-icon ul {
  display: inline-flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.top-header .social-icon ul li a {
  color: #fff;
  display: inline-flex;
  font-size: 13px;
  transition: all 0.4s ease 0s;
  width: 30px;
  height: 30px;
  background-color: #2f7ba4;
  border-radius: 50%;
  padding: 9px;
}

.top-header .social-icon ul li a:hover {
  color: #2f7ba4;
  background-color: #fff;
}

.header-nav {
  align-items: center;
  background-color: #fff;
  border: 0;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  position: relative;
  height: 70px;
  padding: 0 30px;
  margin-bottom: 0;
}

.main-nav {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.main-nav > li.active > a {
  color: #2f7ba4;
}

.main-nav > li .submenu li a {
  display: block;
  padding: 10px 15px;
  clear: both;
  white-space: nowrap;
  font-size: 14px;
  color: #2d3b48;
  transition: all 0.35s ease;
  width: 100%;
  border-top: 1px solid #f0f0f0;
}

.main-nav > li .submenu > li.has-submenu > a::after {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  right: 15px;
  top: 12px;
  font-size: 13px;
}

.main-nav > li .submenu li {
  position: relative;
}

.main-nav li a {
  display: block;
  font-size: 15px;
  font-weight: 600;
  color: #000;
  transition: all 0.6s ease;
}

.main-nav li a:hover {
  color: #2f7ba4;
}

.main-nav li.login-link {
  display: none;
}

.logo {
  display: inline-block;
  margin-right: 30px;
  width: 130px;
}

.header-contact-img {
  display: inline-block;
}

.header-contact-img i {
  color: rgba(0, 0, 0, 0.5);
  font-size: 30px;
}

.header-contact-detail {
  display: inline-block;
  padding-left: 10px;
}

.header-contact-detail p.contact-header {
  color: #484848;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 2px;
  text-align: left;
}

.header-contact-detail p.contact-info-header {
  color: #000;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0;
}

.header-navbar-rht {
  margin: 0;
  padding: 0;
}

.header-navbar-rht li {
  align-items: center;
  display: inline-flex;
  padding-right: 20px;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.header-navbar-rht li:last-child {
  padding-right: 0px;
}

.header-navbar-rht li .dropdown-menu {
  border: 0;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}

.header-navbar-rht .dropdown-toggle::after {
  display: none;
}

.header-navbar-rht li .dropdown-menu::before {
  content: "";
  position: absolute;
  top: 2px;
  right: 0;
  border: 7px solid #fff;
  border-color: transparent transparent #ffffff #ffffff;
  transform-origin: 0 0;
  transform: rotate(135deg);
  box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
}

.header-navbar-rht li .dropdown-menu .dropdown-item {
  border-top: 1px solid #f0f0f0;
  padding: 10px 15px;
}

.header-navbar-rht li .dropdown-menu .dropdown-item:first-child {
  border-top: 0;
  border-radius: 5px 5px 0 0;
}

.header-navbar-rht li .dropdown-menu .dropdown-item:last-child {
  border-radius: 0 0 5px 5px;
}

.header-navbar-rht li a.header-login:hover {
  background-color: #2f7ba4;
  border-color: #2f7ba4;
  color: #fff;
  border-radius: 0px;
}

.header-navbar-rht li .dropdown-menu a:hover {
  color: #2f7ba4;
  letter-spacing: 0.5px;
  padding-left: 20px;
  background-color: #fff;
}

.header-navbar-rht li a.header-login {
  background-color: #2f7ba4;
  border: 2px solid #2f7ba4;
  padding: 10px 15px !important;
  text-align: center;
  font-size: 15px;
  color: #fff;
  font-weight: 500;
}

.header .has-arrow .dropdown-toggle:after {
  border-top: 0;
  border-left: 0;
  border-bottom: 2px solid #032c4f;
  border-right: 2px solid #032c4f;
  content: "";
  height: 8px;
  display: inline-block;
  pointer-events: none;
  transform-origin: 66% 66%;
  transform: rotate(45deg);
  transition: all 0.15s ease-in-out;
  width: 8px;
  vertical-align: 2px;
  margin-left: 10px;
}

.header .has-arrow .dropdown-toggle[aria-expanded="true"]:after {
  transform: rotate(-135deg);
}

.user-menu {
  float: right;
  margin: 0;
  position: relative;
  z-index: 99;
}

.user-menu.nav > li > a {
  color: #fff;
  font-size: 14px;
  line-height: 58px;
  padding: 0 15px;
  height: 60px;
}

.user-menu.nav > li > a:hover,
.user-menu.nav > li > a:focus {
  background-color: rgba(0, 0, 0, 0.2);
}

.user-menu.nav > li > a:hover i,
.user-menu.nav > li > a:focus i {
  color: #fff;
}

.user-img {
  display: inline-block;
  position: relative;
}

.user-img > img {
  height: 31px;
  -o-object-fit: cover;
  object-fit: cover;
  width: 31px;
}

.user-menu.nav > li > a.mobile_btn {
  border: 0;
  position: relative;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.header-navbar-rht .dropdown-menu {
  min-width: 200px;
  padding: 0;
}

.header-navbar-rht .dropdown-menu .dropdown-item {
  padding: 7px 15px;
}

.header-navbar-rht .dropdown-menu .dropdown-item {
  align-items: center;
  display: flex;
  border-top: 1px solid #e3e3e3;
  padding: 10px 15px;
}

.header-navbar-rht .dropdown-menu .dropdown-item:hover {
  color: #2f7ba4;
}

.user-header {
  display: flex;
  padding: 10px 15px;
}

.user-header .user-text {
  margin-left: 10px;
}

.user-header .user-text h6 {
  font-size: 15px;
  margin-bottom: 2px;
}

.header-navbar-rht .logged-item .nav-link {
  align-items: center;
  display: inline-flex;
  font-size: 14px;
  line-height: 70px;
  padding: 0 10px;
}

/*-----------------
	12. Mobile Menu
-----------------------*/

.sidebar-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1040;
}

.menu-opened .main-menu-wrapper {
  transform: translateX(0);
}

.menu-header {
  align-items: center;
  background-color: #fff;
  height: 60px;
  padding: 0 20px;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  border-bottom: 1px solid #f0f0f0;
  display: none;
}

.menu-logo img {
  height: 40px;
}

.menu-close {
  font-size: 18px;
  color: #2f7ba4;
}

.bar-icon {
  display: inline-block;
  width: 31px;
}

.bar-icon span {
  background-color: #2f7ba4;
  display: block;
  float: left;
  height: 3px;
  margin-bottom: 7px;
  width: 31px;
  border-radius: 2px;
}

.bar-icon span:nth-child(2) {
  width: 16px;
}

.bar-icon span:nth-child(3) {
  margin-bottom: 0;
}

#mobile_btn {
  display: none;
  margin-right: 30px;
}

html.menu-opened body {
  overflow: hidden;
}

/*-----------------
	13. Footer
-----------------------*/

.footer {
  background-color: #47466c;
}

.footer .footer-top {
  padding: 1px 0;
}

.footer-title {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin: 15px 0;
  text-transform: capitalize;
}

.footer .footer-widget.footer-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  outline: none;
}

.footer .footer-widget .footer-logo {
  margin-bottom: 20px;
}

.footer .footer-widget .footer-logo img {
  width: 140px;
}

.footer .footer-widget .footer-about-content p {
  color: #fff;
}

.footer .footer-widget .footer-about-content p:last-child {
  margin-bottom: 0;
}

.footer .footer-menu ul li {
  margin-bottom: 10px;
  position: relative;
}

.footer .footer-menu ul li:last-child {
  margin-bottom: 0;
}

.footer .footer-menu ul li a {
  color: #fff;
  font-size: 14px;
  position: relative;
  transition: all 0.4s ease 0s;
}

.footer .footer-widget.footer-menu ul li a:hover {
  color: #2f7ba4;
  letter-spacing: 0.5px;
  padding-left: 5px;
}

.footer .footer-menu p {
  font-size: 13px;
  color: #fff;
  line-height: 20px;
}

.footer-menu .form-control {
  border-color: #fff;
  color: #333;
  font-size: 15px;
  min-height: 46px;
  padding: 6px 15px;
}

.footer-menu .btn-outline-secondary {
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  background-color: #2f7ba4;
  border-color: #2f7ba4;
}

.footer .social-icon ul {
  display: inline-flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .social-icon ul li {
  margin-right: 15px;
}

.footer .social-icon ul li:last-child {
  margin-right: 0;
}

.footer .social-icon ul li a {
  color: #fff;
  display: inline-flex;
  font-size: 20px;
  transition: all 0.4s ease 0s;
  width: 40px;
  height: 40px;
  background-color: #2f7ba4;
  border-radius: 50%;
  padding: 10px;
}

.footer .social-icon ul li a .fa-facebook-f {
  padding-left: 3px;
}

.footer .social-icon ul li a:hover {
  background-color: #fff;
  color: #2f7ba4;
}

.footer-bottom {
  padding: 20px 0px 10px;
  background-color: #3c3b5b;
}

.footer-bottom p {
  color: #fff;
  margin: 0px;
}

.download-app ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.download-app ul li {
  padding-right: 10px;
  color: #fff;
}

.download-app ul li img {
  width: 110px;
}

/*-----------------
	14. Login
-----------------------*/

.account-page {
  background-color: #fff;
}

.account-page .content {
  padding: 50px 0;
}

.login-right {
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  padding: 25px;
}

.login-header {
  margin-bottom: 20px;
}

.login-header p {
  margin-bottom: 0;
}

.login-header h3 {
  font-size: 18px;
  margin-bottom: 3px;
}

.login-header h3 a {
  color: #2f7ba4;
  float: right;
  font-size: 15px;
  margin-top: 2px;
}

.login-right .dont-have {
  color: #3d3d3d;
  margin-top: 20px;
  font-size: 13px;
}

.login-right .dont-have a {
  color: #2f7ba4;
}

.login-btn {
  font-size: 18px;
  font-weight: 500;
}

.login-or {
  color: #d4d4d4;
  margin-bottom: 20px;
  margin-top: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
  position: relative;
}

.or-line {
  background-color: #e5e5e5;
  height: 1px;
  margin-bottom: 0;
  margin-top: 0;
  display: block;
}

.span-or {
  background-color: #fff;
  display: block;
  left: 50%;
  margin-left: -20px;
  position: absolute;
  text-align: center;
  top: -3px;
  width: 42px;
}

.forgot-link {
  color: #3d3d3d;
  display: inline-block;
  font-size: 13px;
  margin-bottom: 10px;
  font-weight: 400;
}

.btn-facebook {
  background-color: #3a559f;
  color: #fff;
  font-size: 13px;
  padding: 8px 12px;
}

.btn-google {
  background-color: #dd4b39;
  color: #fff;
  font-size: 13px;
  padding: 8px 12px;
}

.social-login .btn:hover,
.social-login .btn:focus {
  color: #fff;
}

/*-----------------
	15. Home
-----------------------*/

.home-banner {
  background-image: url("../images/home-bg.png");
  min-height: 450px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding: 150px 0;
  margin-top: -1px;
}

.section-header {
  margin-bottom: 60px;
}

.section-header h2 {
  font-size: 36px;
  margin-bottom: 0;
  font-weight: 500;
}

.section-header .sub-title {
  color: #757575;
  font-size: 16px;
  max-width: 600px;
  margin: 15px auto 0;
}

.section-header p {
  color: #757575;
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 15px;
}

.banner-wrapper {
  align-items: center;
}

.banner-wrapper .banner-header {
  margin-bottom: 30px;
  height: 100%;
  width: 60%;
  margin: 0 auto;
  text-align: center;
  background-color: rgb(255 255 255 / 0.1);
  padding: 50px 30px;
}

.banner-wrapper .banner-header h3 {
  font-weight: 500;
  color: #fff;
}

.banner-wrapper .banner-header h1 {
  margin: 15px 0px;
  font-size: 56px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  line-height: 55px;
}

.banner-wrapper .banner-header h1 > span {
  font-weight: 700;
  color: #2f7ba4;
}

.banner-wrapper .banner-header p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 0;
}

.banner-bottom {
  position: relative;
  padding: 60px 0px 30px;
}

.banner-bottom .card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: none;
}

.banner-bottom .card img {
  width: 60px;
}

.banner-bottom .care-text {
  margin-left: 20px;
}

.banner-bottom .card h2 {
  color: #232323;
  font-size: 20px;
  font-weight: 600 !important;
}

.banner-bottom .card p {
  margin: 0px;
  line-height: 20px;
  font-size: 14px;
}

.title-icon {
  font-size: 36px;
  color: #ececec;
}

.title-top {
  font-size: 16px;
  color: #2f7ba4;
  text-transform: uppercase;
  font-weight: 900;
  margin: 8px 0px 5px;
}

.section-title {
  font-size: 36px;
  color: #47466c;
  font-weight: 600;
  text-transform: uppercase;
}

.section-title:before {
  content: "";
  border-top: 3px solid #2f7ba4;
  width: 50px;
  height: 0px;
  display: block;
  position: relative;
  top: -10px;
}

.blue-text {
  color: #47466c;
  font-weight: 600;
}

.about-us {
  padding: 60px 0px;
  background-image: url(../images/about-us-bg.png);
  background-size: 35% 100%;
  background-repeat: no-repeat;
  position: relative;
  margin-right: auto;
  background-position: right;
}

.about-us img {
  width: 90%;
}

.about-us .media {
  background-color: #2f7ba4;
  padding: 20px 30px;
  width: 85%;
  position: absolute;
  margin-top: -90px;
  color: #fff;
  right: 0;
}

.about-us .media img {
  width: 80px;
}

.about-us .media-body h5 {
  color: #fff;
  font-weight: 600;
}

.about-us .media-body p {
  font-size: 14px;
  margin-bottom: 10px;
}

.about-us .media-body a {
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
}

.about-us h3 {
  font-weight: 600;
  color: #47466c;
}

.health-care {
  background-image: url("../images/health-care-bg.png");
  padding: 30px 0;
  background-size: cover;
}

.health-care img {
  width: 80px;
  margin-right: 25px;
}

.health-care .btn-primary {
  background-color: #fff;
  border-color: #fff;
  color: #202020 !important;
  font-size: 14px;
}

.health-care .btn-primary.active:not(:disabled):not(.disabled),
.health-care .btn-primary:active:not(:disabled):not(.disabled),
.health-care .show > .btn-primary.dropdown-toggle {
  color: #fff !important;
}

.health-care h3 {
  color: #fff;
  font-weight: 600;
}

.health-care p {
  color: #fff;
  margin: 0px;
}

.general-section {
  padding: 60px 0 50px;
  background-color: #f3f3f3;
}

.general-slider .slick-slide,
.surgeon-slider .slick-slide {
  display: block;
  margin-left: 0;
  padding: 10px;
  width: 280px;
}

.service-img {
  width: 85%;
  position: relative;
  z-index: 99;
}

.service-img img {
  width: 100%;
  border-radius: 5px;
}

.service-name {
  background-color: #fff;
  padding: 45px 30px 20px;
  position: relative;
  width: 90%;
  margin-left: auto;
  margin-top: -30px;
  text-align: center;
}

.service-name a {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
}

.service-name a > i {
  color: #2f7ba4;
}

.service-name a:hover {
  color: #2f7ba4;
}

.watch-video {
  padding-bottom: 60px 0px 0px;
}

.video-title {
  background-color: #47466c;
  padding: 60px 0 200px;
  position: relative;
}

.watch-video .section-title {
  color: #fff;
  text-align: center;
}

.watch-video .section-title:before {
  margin: 0 auto;
}

.watch-video p {
  width: 65%;
  color: #fff;
  margin: 0 auto;
  text-align: center;
}

.video-main {
  background-color: #f3f3f3;
  padding-bottom: 50px;
}

.video-sec {
  margin: -150px 0px 50px;
  position: relative;
}

.video-sec img {
  width: 60%;
  position: relative;
}

.video-sec .play-btn {
  position: absolute;
  top: 42%;
  left: 47%;
  background-color: #fff;
  border: 10px solid #f1f1f1;
  font-size: 30px;
  padding: 20px 31px;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  color: #47466c;
}

.video-sec .play-btn:hover {
  cursor: pointer;
}

.video-sec iframe {
  width: 60%;
  position: relative;
  height: 340px;
}

.counts h3 {
  text-align: center;
  color: #47466c;
}

.counts h4 {
  text-align: center;
  color: #202020;
  font-size: 20px;
  font-weight: 400;
  margin: 0px;
}

.our-surgeon {
  padding: 60px 0 50px;
}

.our-surgeon .card {
  border: none;
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 0.1);
}

.our-surgeon .card-img-top {
  border-radius: 0px;
}

.our-surgeon .card-body {
  padding: 20px;
  text-align: center;
}

.our-surgeon .card-title {
  text-transform: uppercase;
  color: #47466c;
  font-weight: 600;
}

.our-surgeon h6 {
  background-color: #2f7ba4;
  color: #fff;
  font-weight: 400;
  position: relative;
  margin: 15px auto;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 6px 25px;
  text-align: center;
}

.our-surgeon .card-text {
  font-size: 14px;
  line-height: 22px;
}

.our-surgeon .btn-primary {
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 0.1);
  color: #2f7ba4 !important;
  background-color: #fff;
  border-color: transparent;
  padding: 10px 15px;
  position: absolute;
  left: 40%;
}

.our-customer-says {
  padding: 60px 0 50px;
  background-color: #fafafa;
}

.our-customer-says img {
  width: 85%;
}

.our-customer-says h5 {
  font-size: 15px;
  color: #2f7ba4;
  margin-top: 30px;
  font-weight: 500;
}

.our-customer-says h5 > span {
  color: #47466c;
}

.our-customer-says h5::after {
  content: "";
  border: 2px solid #2f7ba4;
  width: 40px;
  display: block;
  margin-top: 60px;
}

.our-blog {
  padding: 60px 0 50px;
}

.our-blog img {
  width: 200px;
}

.our-blog .media-body h5 {
  font-size: 12px;
  color: #2f7ba4;
}

.our-blog .media-body p {
  font-size: 12px;
  color: #47466c;
  font-weight: 600;
  line-height: 18px;
}

.blog-img img {
  width: 100%;
}

.blog-text {
  background-color: #fff;
  padding: 15px;
  width: 90%;
  margin-left: auto;
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 0.1);
  margin-top: -50px;
  z-index: 99;
  position: relative;
}

.blog-text h6 {
  font-size: 12px;
  color: #2f7ba4;
}

.our-blog h5 {
  font-size: 14px;
  color: #47466c;
  font-weight: 600;
  line-height: 18px;
}

.our-blog p {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

.search-box form {
  display: flex;
}

.search-box .form-control {
  border: 1px solid #ccc;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075);
  border-radius: 5px;
  padding-left: 35px;
}

.search-box .search-location {
  flex: 0 0 240px;
  margin-right: 12px;
  position: relative;
  width: 240px;
}

.search-location .form-control {
  background: #fff url(../images/location.png) no-repeat 10px center;
}

.search-box .search-info {
  flex: 0 0 490px;
  margin-right: 12px;
  position: relative;
  width: 490px;
}

.search-info .form-control {
  background: #fff url(../images/search.png) no-repeat 10px center;
}

.search-box .search-btn {
  width: 46px;
  flex: 0 0 46px;
  height: 46px;
}

.search-box .search-btn span {
  display: none;
  font-weight: 500;
}

.search-box .form-text {
  color: #757575;
  font-size: 13px;
}

.section-specialities {
  background-color: #fff;
  padding: 60px 0 50px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 5px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 5px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: #c0c0c0;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  display: none;
  font-family: "slick";
  font-size: 76px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "-";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button {
  background-color: #0de0fe;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #0de0fe;
}

.slick-slide {
  outline: none !important;
}

.speicality-img {
  position: relative;
  height: 150px;
  box-shadow: 2px 2px 13px rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  width: 150px;
  background: #fff;
}

.speicality-img img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
}

.speicality-img span {
  align-items: center;
  position: absolute;
  bottom: 10px;
  right: 10px;
  box-shadow: 1px 6px 14px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  padding: 5px;
  background-color: #fff;
  display: inline-flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.speicality-img span i {
  font-size: 15px;
  color: #0de0fe;
}

.speicality-item p {
  font-size: 16px;
  font-weight: 500;
  margin: 30px 0 0;
}

.section-doctor {
  background-color: #f8f9fa;
  padding: 60px 0px;
}

.section-doctor .section-header {
  margin-bottom: 30px;
}

.section-doctor .section-header p {
  margin-top: 10px;
}

.profile-widget {
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  margin-bottom: 30px;
  position: relative;
  transition: all 0.3s ease 0s;
  padding: 15px;
}

.doc-img {
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: 4px;
}

.doc-img img {
  border-radius: 4px;
  transform: translateZ(0);
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  width: 100%;
}

.doc-img:hover img {
  transform: scale(1.15);
}

.profile-widget .fav-btn {
  align-items: center;
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #fff;
  width: 30px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border-radius: 3px;
  color: #2e3842;
  transform: translate3d(100%, 0, 0);
  opacity: 0;
  visibility: hidden;
  z-index: 99;
}

.profile-widget:hover .fav-btn {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.profile-widget .fav-btn:hover {
  background-color: #fb1612;
  color: #fff;
}

.pro-content {
  padding: 13px 0 0;
}

.pro-content .title {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 5px;
}

.profile-widget .pro-content .title a {
  display: inline-block;
  font-weight: 600;
}

.profile-widget .verified {
  color: #28a745;
  margin-left: 3px;
}

.verify-btn:hover {
  color: white;
}

.profile-widget p.speciality {
  font-size: 13px;
  color: #757575;
  margin-bottom: 5px;
  min-height: 40px;
}

.rating {
  list-style: none;
  margin: 0 0 10px;
  padding: 0;
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.rating i {
  color: #dedfe0;
}

.rating i.filled {
  color: #2f7ba4;
}

.profile-widget .rating {
  color: #858585;
  font-size: 14px;
  margin-bottom: 15px;
}

.profile-widget .rating i {
  font-size: 14px;
}

.available-info {
  font-size: 13px;
  color: #858585;
  font-weight: 400;
  list-style: none;
  padding: 0;
  margin-bottom: 15px;
}

.available-info li + li {
  margin-top: 5px;
}

.available-info li i {
  width: 22px;
  color: #032c4f;
}

.btn-view {
  color: #fff;
  font-size: 13px;
  text-align: center;
  font-weight: 600;
  padding: 10px 6px;
  width: 100%;
  background-color: #2f7ba4;
  border-radius: 0px;
}

.btn-view:hover {
  border-color: #2f7ba4;
  color: #2f7ba4;
  background-color: transparent;
}

.btn-book-now {
  color: #fff;
  font-size: 13px;
  text-align: center;
  font-weight: 600;
  padding: 10px 6px;
  width: 100%;
  background-color: #032c4f;
  border-radius: 0px;
}

.btn-book-now:hover {
  border-color: #032c4f;
  color: #032c4f;
  background-color: transparent;
}

.row.row-sm {
  margin-left: -3px;
  margin-right: -3px;
}

.row.row-sm > div {
  padding-left: 3px;
  padding-right: 3px;
}

.view-btn {
  color: #2f7ba4;
  font-size: 13px;
  border: 2px solid #2f7ba4;
  text-align: center;
  display: block;
  font-weight: 500;
  padding: 6px;
}

.view-btn:hover,
.view-btn:focus {
  background-color: #2f7ba4;
  color: #fff;
}

.book-btn {
  background-color: #032c4f;
  border: 2px solid #032c4f;
  color: #fff;
  font-size: 13px;
  text-align: center;
  display: block;
  font-weight: 500;
  padding: 6px;
}

.book-btn:hover,
.book-btn:focus {
  background-color: transparent;
  border-color: #032c4f;
  color: #032c4f;
}

.section-doctor .profile-widget {
  box-shadow: 2px 2px 13px rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
}

.about-content p {
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
}

.about-content p + p {
  margin-top: 20px;
}

.section-features {
  background-color: #fff;
  padding: 60px 0 50px;
}

.feature-item img {
  border-radius: 100%;
  box-shadow: 1px 6px 14px rgba(0, 0, 0, 0.2);
  height: 115px;
  -o-object-fit: cover;
  object-fit: cover;
  width: 115px;
}

.feature-item p {
  font-weight: 500;
  margin: 20px 0 0;
}

.section-blogs {
  background-color: #f8f9fa;
  padding: 60px 0 50px;
}

.view-all .btn {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 30px;
}

/*-----------------
	16. Search
-----------------------*/

.breadcrumb-bar {
  background-color: #fff;
  padding: 15px 0;
  min-height: 560px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 2;
  position: relative;
  overflow: hidden;
}

.breadcrumb-bar:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(../../../../public/assets/images/doctors/breadcrumb-bg-image.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  -webkit-filter: blur(3px);
  filter: blur(3px);
}

.breadcrumb-bar::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #2f7ba4;
  opacity: 0.4;
  z-index: 0;
}

.breadcrumb-bar > div {
  z-index: 2;
}

.breadcrumb-bar .breadcrumb-title {
  color: #242424;
  font-size: 48px;
  font-weight: 600;
  margin: 15px 0 0;
}

.page-breadcrumb ol {
  background-color: transparent;
  font-size: 18px;
  margin-bottom: 0;
  padding: 0;
  justify-content: center;
}

.page-breadcrumb ol li {
  position: relative;
  padding: 0 15px;
}

.page-breadcrumb ol li a {
  color: #242424;
}

.page-breadcrumb ol li.active {
  color: #2f7ba4;
}

.page-breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  color: #2f7ba4;
  font-size: 14px;
  top: 50%;
  position: absolute;
  left: -7px;
  transform: translateY(-50%);
  padding: 0;
}

.sort-by {
  float: right;
}

.sort-title {
  color: #fff;
  font-size: 14px;
  margin-right: 10px;
}

.sortby-fliter {
  display: inline-block;
  width: 120px;
}

.cal-icon {
  position: relative;
  width: 100%;
}

.cal-icon:after {
  color: #979797;
  content: "\f073";
  display: block;
  font-family: "Font Awesome 5 Free";
  font-size: 16px;
  font-weight: 400;
  margin: auto;
  position: absolute;
  right: 15px;
  top: 10px;
}

.custom_check {
  color: #666;
  display: inline-block;
  position: relative;
  font-size: 14px;
  font-size: 0.9375rem;
  padding-left: 30px;
  margin-bottom: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom_check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom_check input:checked ~ .checkmark {
  background-color: #fff;
}

.custom_check .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #dcdcdc;
  background-color: #fff;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
}

.custom_check .checkmark::after {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  display: none;
  left: 4px;
  top: 0;
  color: #2f7ba4;
  font-size: 11px;
}

.custom_check input:checked ~ .checkmark:after {
  display: block;
}

.custom_radio {
  color: #555;
  display: inline-block;
  position: relative;
  font-size: 14px;
  font-size: 0.9375rem;
  padding-left: 30px;
  margin-bottom: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio_input .custom_radio + .custom_radio {
  margin-left: 15px;
}

.custom_radio input {
  position: absolute;
  opacity: 0;
}

.custom_radio input:checked ~ .checkmark:after {
  opacity: 1;
}

.custom_radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
}

.custom_radio .checkmark:after {
  display: block;
  content: "";
  position: absolute;
  opacity: 0;
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ff9b44;
  transition: all 0.3s ease-in-out;
}

.filter-widget {
  margin-bottom: 20px;
}

.filter-widget h4 {
  font-size: 1rem;
  margin-bottom: 15px;
}

.filter-widget .custom_check {
  line-height: 18px;
}

.btn-search .btn {
  background-color: #2f7ba4;
  border: 1px solid #2f7ba4;
  color: #fff;
  height: 46px;
  font-weight: 500;
  font-size: 16px;
}

.doctor-widget {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  max-width: 1200px;
}

.doc-info-left {
  display: -ms-flexbox;
  margin-right: 30px;
  display: flex;
  margin-top: 15px;
}

.doc-info-right {
  margin-left: auto;
  flex: 0 0 600px;
  max-width: 600px;
}

.doctor-img {
  flex: 0 0 150px;
  margin-right: 20px;
  width: 150px;
}

.doctor-img img {
  border-radius: 5px;
}

.doc-department {
  color: #2f7ba4;
  font-size: 14px;
  margin-bottom: 8px;
}

.doc-department img {
  width: 19px;
  display: inline-block;
  margin-right: 10px;
}

.doc-location {
  color: #757575;
  font-size: 14px;
  margin-bottom: 25px;
}

.doc-location a {
  color: #2f7ba4;
  font-weight: 500;
}

.doctor-widget .doc-name {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 3px;
}

.doc-speciality {
  font-size: 14px;
  color: #757575;
  margin-bottom: 15px;
}

.doctor-widget .rating i {
  font-size: 14px;
}

.doctor-widget .average-rating {
  font-size: 14px;
  font-weight: 500;
}

.clinic-details {
  margin-bottom: 15px;
}

.clinic-details h5 {
  font-weight: normal;
  color: #757575;
  margin-bottom: 25px;
}

.clinic-details ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.clinic-details ul li {
  display: inline-block;
  padding-right: 5px;
}

.clinic-details ul li:last-child {
  padding-right: 0;
}

.clinic-details ul li a {
  display: inline-block;
}

.clinic-details ul li a img {
  border-radius: 5px;
  width: 40px;
}

.clinic-services {
  color: #272b41;
  display: flex;
  font-size: 13px;
  flex-wrap: wrap;
}

.clinic-services span {
  border: 1px solid #ccc;
  border-radius: 4px;
  display: inline-block;
  font-size: 12px;
  padding: 3px 10px;
}

.clinic-services span + span {
  margin-left: 5px;
}

.clini-infos {
  margin-bottom: 15px;
}

.clini-infos ul {
  font-size: 14px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.clini-infos ul li {
  display: block;
  line-height: 30px;
  color: #4e4852;
}

.clini-infos ul li i {
  font-size: 15px;
  min-width: 30px;
}

.clinic-booking div + div {
  margin-top: 15px;
}

.clinic-booking div.view-pro-btn:hover,
.clinic-booking div.view-pro-btn:focus {
  background: #2f7ba4;
  color: #fff;
}

.lt-doctor-img {
  border: 3px solid #2f7ba4;
  border-radius: 50%;
}

.profile-img {
  width: 100px;
  height: auto;
  border-radius: 50%;
  object-fit: cover;
}
.title-text {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  margin-left: 15px;
}

.ct-apt-btn {
  background-color: #2f7ba4;
  color: #fff;
  padding: 10px;
  max-width: 240px;
  display: block;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 10px 20px;
  text-align: center;
  text-transform: capitalize;
  width: 100%;
  transition: all 0.6s ease;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #2f7ba4;
}

.ct-apt-btn:hover,
.ct-apt-btn:focus {
  background-color: transparent;
  border-color: #2f7ba4;
  padding: 10px;
  color: #000;
}

.load-more {
  margin-bottom: 30px;
}

/*-----------------
	17. Doctor Profile
-----------------------*/

.clinic-direction {
  color: #757575;
  font-size: 14px;
  margin-bottom: 25px;
}

.clinic-direction a {
  color: #2f7ba4;
  font-weight: 500;
}

.doctor-action {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.doctor-action a + a {
  margin-left: 8px;
}

.doctor-action .fav-btn:hover {
  background-color: #fb1612;
  border-color: #fb1612;
  color: #fff;
}

.msg-btn:hover,
.msg-btn:focus {
  background-color: #2f7ba4;
  border-color: #2f7ba4;
  color: #fff;
}

.call-btn:hover,
.call-btn:focus {
  background-color: #2f7ba4;
  border-color: #2f7ba4;
  color: #fff;
}

.user-tabs .nav-tabs > li > a {
  border: 0;
  border-bottom: 3px solid transparent;
  color: #3e3e3e;
  font-weight: 700;
  padding: 20px;
}

.user-tabs .nav-tabs.nav-tabs-bottom > li > a.active,
.user-tabs .nav-tabs.nav-tabs-bottom > li > a.active:hover,
.user-tabs .nav-tabs.nav-tabs-bottom > li > a.active:focus {
  border-bottom-width: 3px;
  color: #032c4f;
}

.user-tabs .med-records {
  display: inline-block;
  min-width: 130px;
}

.user-tabs .nav-tabs > li > a:hover {
  background-color: unset;
  color: #032c4f;
}

.widget {
  margin-bottom: 30px;
}

.widget-title {
  margin-bottom: 15px;
  font-weight: 600;
}

.service-list h4 {
  font-weight: 600;
}

.experience-box {
  position: relative;
}

.experience-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.experience-list::before {
  background: #ddd;
  bottom: 0;
  content: "";
  left: 8px;
  position: absolute;
  top: 8px;
  width: 2px;
}

.experience-list > li {
  position: relative;
}

.experience-list > li:last-child .experience-content {
  margin-bottom: 0;
}

.experience-user .avatar {
  height: 32px;
  line-height: 32px;
  margin: 0;
  width: 32px;
}

.experience-list > li .experience-user {
  background: #fff;
  height: 10px;
  left: 4px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 4px;
  width: 10px;
}

.experience-list > li .experience-content {
  background-color: #fff;
  margin: 0 0 20px 40px;
  padding: 0;
  position: relative;
}

.experience-list > li .experience-content .timeline-content {
  color: #757575;
}

.experience-list > li .experience-content .timeline-content a.name {
  font-weight: 500;
}

.experience-list > li .time {
  color: #757575;
  display: block;
  font-size: 13px;
}

.before-circle {
  background-color: #fff;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  border: 2px solid #2f7ba4;
}

.exp-year {
  color: #2f7ba4;
  margin-bottom: 2px;
}

.exp-title {
  font-size: 16px;
}

.awards-widget .experience-list > li:last-child p {
  margin-bottom: 0;
}

.service-list {
  margin-bottom: 30px;
}

.service-list:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.service-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.service-list ul li {
  float: left;
  margin: 6px 0;
  padding-left: 25px;
  position: relative;
  width: 33%;
}

.service-list ul li::before {
  color: #ccc;
  content: "\f30b";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  left: 0;
  position: absolute;
}

.location-list {
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  padding: 20px;
}

.location-list + .location-list {
  margin-top: 20px;
}

.clinic-content .clinic-name {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 3px;
}

.clinic-content .clinic-direction a {
  display: inline-block;
  margin-top: 8px;
}

.timings-days {
  font-weight: bold;
  color: #272b41;
  margin-bottom: 5px;
}

.timings-times span {
  display: block;
}

.location-list .consult-price {
  font-size: 20px;
  font-weight: 500;
  color: #272b41;
}

.review-listing {
  border-bottom: 1px solid #f5f7fc;
  margin-top: 20px;
  padding-bottom: 30px;
}

.review-listing > ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.review-listing > ul li + li {
  margin-top: 20px;
  border-top: 1px dashed #f0f0f0;
  padding-top: 20px;
}

.review-listing > ul li .comment {
  display: flex;
  margin-bottom: 30px;
}

.review-listing > ul li .comment:last-child {
  margin-bottom: 0;
}

.review-listing > ul li .comment .comment-body {
  margin-left: 16px;
}

.review-listing > ul li .comment .comment-body .meta-data {
  position: relative;
  margin-bottom: 10px;
}

.review-listing > ul li .comment .comment-body .meta-data span {
  display: block;
  font-size: 16px;
  color: #757575;
}

.review-listing > ul li .comment .comment-body .meta-data span.comment-author {
  font-weight: 600;
  color: #272b41;
  text-transform: capitalize;
}

.review-listing > ul li .comment .comment-body .meta-data span.comment-date {
  font-size: 14px;
}

.review-listing > ul li .comment .comment-body .meta-data .review-count {
  display: flex;
  position: absolute;
  top: 3px;
  right: 0;
  width: auto;
}

.review-listing > ul li .comment .comment-body .comment-content {
  color: #757575;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 14px;
}

.review-listing > ul li .comment .comment-body .comment-reply .comment-btn {
  color: #2f7ba4;
  display: inline-block;
  font-weight: 500;
  font-size: 15px;
}

.review-listing .recommend-btn {
  float: right;
  color: #757575;
  font-size: 14px;
  padding: 5px 0;
  margin-bottom: 0;
}

.review-listing .recommend-btn a {
  border: 1px solid rgba(128, 137, 150, 0.4);
  border-radius: 4px;
  display: inline-block;
  padding: 4px 12px;
  color: #757575;
  margin-left: 3px;
  margin-right: 3px;
  transition: all 0.3s;
}

.review-listing .recommend-btn a.like-btn:hover {
  background-color: #28a745;
  border: 1px solid #28a745;
  color: #fff;
}

.review-listing .recommend-btn a.dislike-btn:hover {
  background-color: #dc3545;
  border: 1px solid #dc3545;
  color: #fff;
}

.review-listing .recommend-btn a i {
  font-size: 16px;
}

.review-listing > ul li .comments-reply {
  list-style: none;
  margin-left: 65px;
  padding: 0;
}

.recommended {
  color: #28a745;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}

.all-feedback {
  margin-top: 20px;
}

.star-rating {
  direction: rtl;
}

.star-rating input[type="radio"] {
  display: none;
}

.star-rating label {
  color: #bbb;
  cursor: pointer;
  font-size: 18px;
  padding: 0;
  transition: all 0.3s ease-in-out;
}

.star-rating label:hover,
.star-rating label:hover ~ label,
.star-rating input[type="radio"]:checked ~ label {
  color: #f2b600;
}

.terms-accept a {
  color: #2f7ba4;
  font-weight: 500;
}

.business-widget {
  background-color: #fcfcfc;
  border: 1px solid #f0f0f0;
  padding: 20px;
  margin-bottom: 0;
}

.listing-day {
  align-items: flex-start;
  color: #000;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 10px;
}

.listing-day:last-child {
  margin-bottom: 0;
}

.listing-day.current {
  border-bottom: 1px solid #ddd;
  padding-bottom: 13px;
  margin-bottom: 13px;
}

.listing-day .day {
  font-weight: 500;
}

.listing-day.current .day {
  font-weight: bold;
}

.listing-day.current .day span {
  display: block;
  font-weight: normal;
}

.time-items {
  color: #757575;
}

.time-items > span {
  display: block;
  text-align: right;
}

.time-items > span.open-status {
  margin-bottom: 3px;
}

.dropzone .dz-preview.dz-error:hover .dz-error-message {
  display: none;
}

/*-----------------
	18. Booking
-----------------------*/

.booking-doc-info {
  display: flex;
}

.booking-doc-info .booking-doc-img {
  width: 80px;
  margin-right: 15px;
}

.booking-doc-info .booking-doc-img img {
  border-radius: 4px;
  height: 80px;
  width: 80px;
  -o-object-fit: cover;
  object-fit: cover;
}

.booking-info h4 {
  font-weight: 600;
}

.schedule-widget {
  border-radius: 4px;
  min-height: 100px;
}

.schedule-header h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 3px 0 0;
}

.schedule-header {
  border-bottom: 1px solid #f0f0f0;
  border-radius: 4px 4px 0 0;
  padding: 10px 20px;
}

.day-slot ul {
  float: left;
  list-style: none;
  margin-bottom: 0;
  margin-left: -5px;
  margin-right: -5px;
  padding: 0;
  position: relative;
  width: 100%;
}

.day-slot li {
  float: left;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  width: 14.28%;
}

.day-slot li span {
  display: block;
  font-size: 18px;
  text-transform: uppercase;
}

.day-slot li span.slot-date {
  display: block;
  color: #757575;
  font-size: 14px;
}

.day-slot li small.slot-year {
  color: #757575;
  font-size: 14px;
}

.day-slot li.left-arrow {
  left: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 20px !important;
  transform: translateY(-50%);
}

.day-slot li.right-arrow {
  right: -11px;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 20px !important;
  transform: translateY(-50%);
}

.schedule-cont {
  padding: 20px;
}

.time-slot ul {
  list-style: none;
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: 0;
  padding: 0;
}

.time-slot li {
  float: left;
  width: 14.28%;
}

.time-slot li .timing {
  background-color: #e9e9e9;
  border: 1px solid #e9e9e9;
  border-radius: 6px;
  color: #757575;
  font-size: 14px;
  padding: 5px 5px;
  text-align: center;
  position: relative;
  transition: all 0.6s ease;
  margin: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.time-slot li .timing:hover {
  background-color: #2f7ba4;
  color: #fff;
}

.time-slot li .timing.selected {
  background-color: #2f7ba4;
  border: 1px solid #2f7ba4;
  color: #fff;
  padding-left: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.daterangepicker .ranges li.active {
  background-color: #2f7ba4;
  color: #fff;
}

.time-slot li .timing.selected::before {
  color: #fff;
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-size: 12px;
  font-weight: 900;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.schedule-list {
  border-bottom: 1px solid #cfcfcf;
  margin-bottom: 50px;
  padding-bottom: 50px;
}

.schedule-list:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.submit-section.proceed-btn {
  margin: 0 0 30px;
}

/*-----------------
	19. Checkout
-----------------------*/

.info-widget {
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.card-label > label {
  background-color: #fff;
  color: #959595;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  margin: 6px auto auto 8px;
  padding: 0 7px;
}

.date-picker {
  display: flex !important;
  width: 300px;
}

.date-picker-box {
  width: 90px;
}

.year-picker-box {
  width: 120px;
}

.card-label > input {
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
  display: block;
  height: 50px;
  margin-top: -13px;
  padding: 5px 15px 0;
  transition: border-color 0.3s;
  width: 100%;
}

.card-date-label > input {
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
  display: block;
  height: 50px;
  margin-top: -13px;
  padding: 5px 15px 0;
  transition: border-color 0.3s;
  width: 100%;
  background: none;
}

.error-border {
  border-color: #dc3545 !important;
}

.error-border:focus {
  box-shadow: none !important;
  background: none !important;
}

.form-control:valid {
  background: none !important;
}

.exist-customer a {
  color: #2f7ba4;
  font-weight: 500;
}

.payment-widget .payment-list + .payment-list {
  margin-bottom: 15px;
}

.payment-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 600;
  color: #272b41;
  text-transform: capitalize;
}

.payment-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.payment-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 19px;
  height: 19px;
  margin: 3px 0 0 0;
  border: 2px solid #ddd;
  border-top-color: rgb(221, 221, 221);
  border-right-color: rgb(221, 221, 221);
  border-bottom-color: rgb(221, 221, 221);
  border-left-color: rgb(221, 221, 221);
  border-radius: 50%;
  transition: all 0.3s;
}

.payment-radio input:checked ~ .checkmark {
  border-color: #032c4f;
}

.payment-radio .checkmark::after {
  position: absolute;
  left: 3px;
  top: 3px;
  content: "";
  width: 9px;
  height: 9px;
  background-color: #032c4f;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.1);
  border-radius: 50%;
  transition: all 0.3s;
}

.payment-radio input:checked ~ .checkmark::after {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #032c4f;
  background-color: #032c4f;
}

.booking-date {
  padding: 0;
  list-style: none;
}

.booking-date li {
  position: relative;
  font-size: 14px;
  font-weight: 500;
  color: #272b41;
  text-transform: capitalize;
  margin-bottom: 15px;
}

.booking-date li span {
  float: right;
  color: #757575;
  font-weight: 400;
  font-size: 15px;
}

.booking-fee {
  padding: 0;
  list-style: none;
}

.booking-fee li {
  position: relative;
  font-size: 14px;
  font-weight: 500;
  color: #272b41;
  text-transform: capitalize;
  margin-bottom: 15px;
}

.booking-fee li span {
  float: right;
  color: #757575;
  font-weight: 400;
  font-size: 15px;
}

.booking-total {
  border-top: 1px solid #e4e4e4;
  margin-top: 20px;
  padding-top: 20px;
}

.booking-total ul {
  padding: 0;
  list-style: none;
  margin: 0;
}

.booking-total ul li span {
  font-size: 18px;
  font-weight: 600;
  color: #272b41;
}

.booking-total ul li .total-cost {
  color: #2f7ba4;
  font-size: 16px;
  float: right;
  font-weight: 700;
}

/*-----------------
	20. Booking Success
-----------------------*/

.success-page-cont {
  align-items: center;
  display: flex;
}

.success-card .card-body {
  padding: 50px 20px;
}

.success-cont {
  text-align: center;
}

.success-cont i {
  align-items: center;
  color: #fff;
  width: 60px;
  height: 60px;
  border: 2px solid #2f7ba4;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  font-size: 30px;
  margin-bottom: 30px;
  background-color: #2f7ba4;
}

.success-cont h3 {
  font-size: 24px;
}

.success-cont p {
  margin-bottom: 30px;
}

.success-cont strong {
  font-weight: 600;
}

.view-inv-btn {
  font-size: 16px;
  font-weight: 600;
  padding: 12px 30px;
}

/*-----------------
	21. Invoice View
-----------------------*/

.invoice-content {
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  margin-bottom: 30px;
  padding: 30px;
}

.invoice-item .invoice-logo {
  margin-bottom: 30px;
}

.invoice-item .invoice-logo img {
  width: auto;
  max-height: 52px;
}

.invoice-item .invoice-text h2 {
  color: #272b41;
  font-size: 36px;
  font-weight: 600;
}

.invoice-item .invoice-details {
  text-align: right;
  color: #757575;
  font-weight: 500;
}

.invoice-item .invoice-details strong {
  color: #272b41;
}

.invoice-item .invoice-details-two {
  text-align: left;
}

.invoice-item .invoice-text {
  padding-top: 42px;
  padding-bottom: 36px;
}

.invoice-item .invoice-text h2 {
  font-weight: 400;
}

.invoice-info {
  margin-bottom: 30px;
}

.invoice-info p {
  margin-bottom: 0;
}

.invoice-info.invoice-info2 {
  text-align: right;
}

.invoice-item .customer-text {
  font-size: 18px;
  color: #272b41;
  font-weight: 600;
  margin-bottom: 8px;
  display: block;
}

.invoice-table tr th,
.invoice-table tr td,
.invoice-table-two tr th,
.invoice-table-two tr td {
  color: #272b41;
  font-weight: 600;
  padding: 10px 20px;
  line-height: inherit;
}

.invoice-table tr td,
.invoice-table-two tr td {
  color: #757575;
  font-weight: 500;
}

.invoice-table-two {
  margin-bottom: 0;
}

.invoice-table-two tr th,
.invoice-table-two tr td {
  border-top: 0;
}

.invoice-table-two tr td {
  text-align: right;
}

.invoice-info h5 {
  font-size: 16px;
  font-weight: 500;
}

.other-info {
  margin-top: 10px;
}

/*-----------------
	22. Schedule Timings
-----------------------*/

.tab-content.schedule-cont .card-title {
  margin-bottom: 10px;
}

.doc-times {
  display: flex;
  flex-wrap: wrap;
}

.doc-slot-list {
  background-color: #032c4f;
  border: 1px solid #032c4f;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  margin: 10px 10px 0 0;
  padding: 6px 15px;
}

.doc-slot-list a {
  color: #0a5391;
  display: inline-block;
  margin-left: 5px;
  top: 2px;
  position: relative;
}

.doc-slot-list a:hover {
  color: #fff;
}

.schedule-nav .nav-tabs {
  border: 0 !important;
  flex-wrap: wrap;
}

.schedule-nav .nav-tabs li {
  margin: 5px 15px 5px 0;
  display: inline-block;
}

.schedule-nav .nav-tabs li:last-child {
  margin-right: 0;
}

.schedule-nav .nav-tabs > li > a {
  border: 1px solid #dcddea;
  border-radius: 4px;
  padding: 6px 15px;
  text-transform: uppercase;
}

.schedule-nav .nav-tabs li a.active {
  background: #2f7ba4;
  border: 1px solid #2f7ba4 !important;
  color: #fff;
}

.hours-info .form-control {
  min-height: auto;
}

.hours-info .btn.btn-danger.trash {
  align-items: center;
  height: 38px;
  width: 100%;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.edit-link {
  color: #2f7ba4;
  font-size: 16px;
  margin-top: 4px;
}

/*-----------------
	23. Doctor Dashboard
-----------------------*/

.dash-widget {
  align-items: center;
  display: flex;
}

.circle-bar {
  margin-right: 15px;
}

.dct-border-rht {
  border-right: 1px solid #f0f0f0;
}

.dash-widget h6 {
  font-size: 16px;
  font-weight: 400;
}

.dash-widget h3 {
  font-size: 24px;
  margin-bottom: 5px;
}

.dash-widget p {
  color: #757575;
  font-size: 14px;
  margin-bottom: 0;
}

.circle-bar > div {
  display: inline-block;
  position: relative;
  text-align: center;
}

.circle-bar > div img {
  left: 0;
  position: absolute;
  top: 50%;
  right: 0;
  text-align: center;
  margin: 0 auto;
  transform: translateY(-50%);
}

.circle-bar > div canvas {
  width: 90px !important;
  height: 90px !important;
}

.dash-card .row {
  margin-left: -10px;
  margin-right: -10px;
}

.dash-card .row > div {
  padding-left: 10px;
  padding-right: 10px;
}

.appointment-tab {
  margin-bottom: 30px;
}

.appointment-tab .nav-tabs {
  background-color: #fff;
  padding: 1.5rem;
  border: 1px solid #f0f0f0;
  border-radius: 0.25rem 0.25rem 0 0 !important;
  border-bottom: 0;
}

.appointment-tab .tab-content {
  padding-top: 0;
}

.appointment-tab .card {
  border-radius: 0;
}

.submit-btn-bottom {
  margin-bottom: 30px;
}

/*-----------------
	24. Patient Profile
-----------------------*/

.add-new-btn {
  background-color: #2f7ba4;
  border-radius: 30px;
  color: #fff;
  display: inline-block;
  font-weight: 500;
  margin-bottom: 20px;
  padding: 10px 20px;
}

.add-new-btn:focus,
.add-new-btn:hover,
.add-new-btn:active {
  background-color: #2f7ba4;
  color: #fff;
}

.patient-info {
  margin-top: 15px;
}

.patient-info ul {
  padding: 0;
  list-style: none;
  font-size: 0.875rem;
  margin: 0;
}

.patient-info ul li {
  position: relative;
  font-size: 0.875rem;
  font-weight: 500;
  color: #272b41;
  text-transform: capitalize;
}

.patient-info ul li + li {
  margin-top: 15px;
}

.patient-info ul li i {
  width: 18px;
}

.patient-info ul li span {
  color: #757575;
  float: right;
  font-weight: 400;
}

/*-----------------
	25. Add Billing
-----------------------*/

.biller-info,
.billing-info {
  margin-bottom: 15px;
}

.add-more-item {
  margin-bottom: 10px;
}

.add-more-item a {
  color: #2f7ba4;
  font-weight: 500;
}

.signature-wrap {
  float: right;
  margin-bottom: 20px;
  text-align: center;
  width: 220px;
}

.signature {
  align-items: center;
  border: 2px dashed #ccc;
  border-radius: 4px;
  color: #272b41;
  cursor: pointer;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  height: 85px;
  margin-bottom: 15px;
  width: 100%;
}

.signature:hover {
  background-color: #fcfcfc;
}

.sign-name {
  width: 100%;
  float: right;
}

.pat-widget-profile .pro-widget-content {
  padding: 0 0 20px;
}

.pat-widget-profile .booking-date li {
  font-size: 0.875rem;
}

.pat-widget-profile .booking-date li span {
  font-size: 0.875rem;
}

/*-----------------
	26. Chat
-----------------------*/

.chat-page .footer {
  display: none;
}

.chat-window {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.chat-cont-left {
  border-right: 1px solid #f0f0f0;
  flex: 0 0 35%;
  left: 0;
  max-width: 35%;
  position: relative;
  z-index: 4;
}

.chat-cont-left .chat-header {
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
  color: #272b41;
  display: flex;
  height: 72px;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  padding: 0 15px;
}

.chat-cont-left .chat-header span {
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
}

.chat-cont-left .chat-header .chat-compose {
  color: #8a8a8a;
  display: inline-flex;
}

.chat-cont-left .chat-search {
  background-color: #f5f5f6;
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 15px;
  width: 100%;
}

.chat-cont-left .chat-search .input-group {
  width: 100%;
}

.chat-cont-left .chat-search .input-group .form-control {
  background-color: #fff;
  border-radius: 50px;
  padding-left: 36px;
}

.chat-cont-left .chat-search .input-group .form-control:focus {
  border-color: #ccc;
  box-shadow: none;
}

.chat-cont-left .chat-search .input-group .input-group-prepend {
  align-items: center;
  bottom: 0;
  color: #666;
  display: flex;
  left: 15px;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 4;
}

.chat-window .chat-scroll {
  min-height: 300px;
  max-height: calc(100vh - 224px);
  overflow-y: auto;
}

.chat-cont-left .chat-users-list {
  background-color: #fff;
}

.chat-cont-left .chat-users-list a.media {
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 15px;
  transition: all 0.2s ease 0s;
}

.chat-cont-left .chat-users-list a.media:last-child {
  border-bottom: 0;
}

.chat-cont-left .chat-users-list a.media .media-img-wrap {
  margin-right: 15px;
  position: relative;
}

.chat-cont-left .chat-users-list a.media .media-img-wrap .avatar {
  height: 45px;
  width: 45px;
}

.chat-cont-left .chat-users-list a.media .media-img-wrap .status {
  bottom: 7px;
  height: 10px;
  right: 4px;
  position: absolute;
  width: 10px;
  border: 2px solid #fff;
}

.chat-cont-left .chat-users-list a.media .media-body {
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.chat-cont-left
  .chat-users-list
  a.media
  .media-body
  > div:first-child
  .user-name,
.chat-cont-left
  .chat-users-list
  a.media
  .media-body
  > div:first-child
  .user-last-chat {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat-cont-left
  .chat-users-list
  a.media
  .media-body
  > div:first-child
  .user-name {
  color: #272b41;
  text-transform: capitalize;
}

.chat-cont-left
  .chat-users-list
  a.media
  .media-body
  > div:first-child
  .user-last-chat {
  color: #8a8a8a;
  font-size: 14px;
  line-height: 24px;
}

.chat-cont-left .chat-users-list a.media .media-body > div:last-child {
  text-align: right;
}

.chat-cont-left
  .chat-users-list
  a.media
  .media-body
  > div:last-child
  .last-chat-time {
  color: #8a8a8a;
  font-size: 13px;
}

.chat-cont-left .chat-users-list a.media:hover {
  background-color: #f5f5f6;
}

.chat-cont-left
  .chat-users-list
  a.media.read-chat
  .media-body
  > div:last-child
  .last-chat-time {
  color: #8a8a8a;
}

.chat-cont-left .chat-users-list a.media.active {
  background-color: #f5f5f6;
}

.chat-cont-right {
  flex: 0 0 65%;
  max-width: 65%;
}

.chat-cont-right .chat-header {
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  height: 72px;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  padding: 0 15px;
}

.chat-cont-right .chat-header .back-user-list {
  display: none;
  margin-right: 5px;
  margin-left: -7px;
}

.chat-cont-right .chat-header .media {
  align-items: center;
}

.chat-cont-right .chat-header .media .media-img-wrap {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.chat-cont-right .chat-header .media .media-img-wrap .avatar {
  height: 50px;
  width: 50px;
}

.chat-cont-right .chat-header .media .media-img-wrap .status {
  border: 2px solid #fff;
  bottom: 0;
  height: 10px;
  position: absolute;
  right: 3px;
  width: 10px;
}

.chat-cont-right .chat-header .media .media-body .user-name {
  color: #272b41;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}

.chat-cont-right .chat-header .media .media-body .user-status {
  color: #666;
  font-size: 14px;
}

.chat-cont-right .chat-header .chat-options {
  display: flex;
}

.chat-cont-right .chat-header .chat-options > a {
  align-items: center;
  border-radius: 50%;
  color: #8a8a8a;
  display: inline-flex;
  height: 30px;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin-left: 10px;
  width: 30px;
}

.chat-cont-right .chat-body {
  background-color: #f5f5f6;
}

.chat-cont-right .chat-body ul.list-unstyled {
  margin: 0 auto;
  padding: 15px;
  width: 100%;
}

.chat-cont-right .chat-body .media .avatar {
  height: 30px;
  width: 30px;
}

.chat-cont-right .chat-body .media .media-body {
  margin-left: 20px;
}

.chat-cont-right .chat-body .media .media-body .msg-box > div {
  padding: 10px 15px;
  border-radius: 0.25rem;
  display: inline-block;
  position: relative;
}

.chat-cont-right .chat-body .media .media-body .msg-box > div p {
  color: #272b41;
  margin-bottom: 0;
}

.chat-cont-right .chat-body .media .media-body .msg-box + .msg-box {
  margin-top: 5px;
}

.chat-cont-right .chat-body .media.received {
  margin-bottom: 20px;
}

.chat-cont-right .chat-body .media:last-child {
  margin-bottom: 0;
}

.chat-cont-right .chat-body .media.received .media-body .msg-box > div {
  background-color: #fff;
}

.chat-cont-right .chat-body .media.sent {
  margin-bottom: 20px;
}

.chat-cont-right .chat-body .media.sent .media-body {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  margin-left: 0;
}

.chat-cont-right .chat-body .media.sent .media-body .msg-box > div {
  background-color: #e3e3e3;
}

.chat-cont-right .chat-body .media.sent .media-body .msg-box > div p {
  color: #272b41;
}

.chat-cont-right .chat-body .chat-date {
  font-size: 14px;
  margin: 1.875rem 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-transform: capitalize;
}

.chat-cont-right .chat-body .chat-date:before {
  background-color: #e0e3e4;
  content: "";
  height: 1px;
  margin-right: 28px;
  position: absolute;
  right: 50%;
  top: 50%;
  width: 100%;
}

.chat-cont-right .chat-body .chat-date:after {
  background-color: #e0e3e4;
  content: "";
  height: 1px;
  left: 50%;
  margin-left: 28px;
  position: absolute;
  top: 50%;
  width: 100%;
}

.chat-cont-right .chat-footer {
  background-color: #fff;
  border-top: 1px solid #f0f0f0;
  padding: 10px 15px;
  position: relative;
}

.chat-cont-right .chat-footer .input-group {
  width: 100%;
}

.chat-cont-right .chat-footer .input-group .form-control {
  background-color: #f5f5f6;
  border: none;
  border-radius: 50px;
}

.chat-cont-right .chat-footer .input-group .form-control:focus {
  background-color: #f5f5f6;
  border: none;
  box-shadow: none;
}

.chat-cont-right .chat-footer .input-group .input-group-prepend .btn,
.chat-cont-right .chat-footer .input-group .input-group-append .btn {
  background-color: transparent;
  border: none;
  color: #9f9f9f;
}

.chat-cont-right
  .chat-footer
  .input-group
  .input-group-append
  .btn.msg-send-btn {
  background-color: #2f7ba4;
  border-color: #2f7ba4;
  border-radius: 50%;
  color: #fff;
  margin-left: 10px;
  min-width: 46px;
  font-size: 20px;
}

.msg-typing {
  width: auto;
  height: 24px;
  padding-top: 8px;
}

.msg-typing span {
  height: 8px;
  width: 8px;
  float: left;
  margin: 0 1px;
  background-color: #a0a0a0;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}

.msg-typing span:nth-of-type(1) {
  -webkit-animation: 1s blink infinite 0.33333s;
  animation: 1s blink infinite 0.33333s;
}

.msg-typing span:nth-of-type(2) {
  -webkit-animation: 1s blink infinite 0.66666s;
  animation: 1s blink infinite 0.66666s;
}

.msg-typing span:nth-of-type(3) {
  -webkit-animation: 1s blink infinite 0.99999s;
  animation: 1s blink infinite 0.99999s;
}

.chat-cont-right .chat-body .media.received .media-body .msg-box {
  position: relative;
}

.chat-cont-right
  .chat-body
  .media.received
  .media-body
  .msg-box:first-child:before {
  border-bottom: 6px solid transparent;
  border-right: 6px solid #fff;
  border-top: 6px solid transparent;
  content: "";
  height: 0;
  left: -6px;
  position: absolute;
  right: auto;
  top: 8px;
  width: 0;
}

.chat-cont-right .chat-body .media.sent .media-body .msg-box {
  padding-left: 50px;
  position: relative;
}

.chat-cont-right
  .chat-body
  .media.sent
  .media-body
  .msg-box:first-child:before {
  border-bottom: 6px solid transparent;
  border-left: 6px solid #e3e3e3;
  border-top: 6px solid transparent;
  content: "";
  height: 0;
  left: auto;
  position: absolute;
  right: -6px;
  top: 8px;
  width: 0;
}

.chat-msg-info {
  align-items: center;
  display: flex;
  clear: both;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 5px 0 0;
}

.chat-msg-info li {
  font-size: 13px;
  padding-right: 16px;
  position: relative;
}

.chat-msg-info li:not(:last-child):after {
  position: absolute;
  right: 8px;
  top: 50%;
  content: "";
  height: 4px;
  width: 4px;
  background: #d2dde9;
  border-radius: 50%;
  transform: translate(50%, -50%);
}

.chat-cont-right
  .chat-body
  .media.sent
  .media-body
  .msg-box
  .chat-msg-info
  li:not(:last-child)::after {
  right: auto;
  left: 8px;
  transform: translate(-50%, -50%);
  background: #aaa;
}

.chat-cont-right
  .chat-body
  .media.received
  .media-body
  .msg-box
  > div
  .chat-time {
  color: rgba(50, 65, 72, 0.4);
}

.chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-time {
  color: rgba(50, 65, 72, 0.4);
}

.chat-msg-info li a {
  color: #777;
}

.chat-msg-info li a:hover {
  color: #2c80ff;
}

.chat-seen i {
  color: #00d285;
  font-size: 16px;
}

.chat-msg-attachments {
  padding: 4px 0;
  display: flex;
  width: 100%;
  margin: 0 -1px;
}

.chat-msg-attachments > div {
  margin: 0 1px;
}

.chat-cont-right
  .chat-body
  .media.sent
  .media-body
  .msg-box
  > div
  .chat-msg-info {
  flex-direction: row-reverse;
}

.chat-cont-right
  .chat-body
  .media.sent
  .media-body
  .msg-box
  > div
  .chat-msg-attachments {
  flex-direction: row-reverse;
}

.chat-cont-right
  .chat-body
  .media.sent
  .media-body
  .msg-box
  > div
  .chat-msg-info
  li {
  padding-left: 16px;
  padding-right: 0;
  position: relative;
}

.chat-attachment img {
  max-width: 100%;
}

.chat-attachment {
  position: relative;
  max-width: 130px;
  overflow: hidden;
}

.chat-attachment {
  border-radius: 0.25rem;
}

.chat-attachment:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  content: "";
  opacity: 0.4;
  transition: all 0.4s;
}

.chat-attachment:hover:before {
  opacity: 0.6;
}

.chat-attach-caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  padding: 7px 15px;
  font-size: 13px;
  opacity: 1;
  transition: all 0.4s;
}

.chat-attach-download {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.4s;
  color: #fff;
  width: 32px;
  line-height: 32px;
  background: rgba(255, 255, 255, 0.2);
  text-align: center;
}

.chat-attach-download:hover {
  color: #495463;
  background: #fff;
}

.chat-attachment:hover .chat-attach-caption {
  opacity: 0;
}

.chat-attachment:hover .chat-attach-download {
  opacity: 1;
}

.chat-attachment-list {
  display: flex;
  margin: -5px;
}

.chat-attachment-list li {
  width: 33.33%;
  padding: 5px;
}

.chat-attachment-item {
  align-items: center;
  border: 5px solid rgba(230, 239, 251, 0.5);
  height: 100%;
  min-height: 60px;
  text-align: center;
  font-size: 30px;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.chat-cont-right
  .chat-body
  .media.sent
  .media-body
  .msg-box
  > div:hover
  .chat-msg-actions {
  opacity: 1;
}

.chat-msg-actions {
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: all 0.4s;
  z-index: 2;
}

.chat-msg-actions > a {
  padding: 0 10px;
  color: #495463;
  font-size: 24px;
}

.chat-msg-actions > a:hover {
  color: #2c80ff;
}

@-webkit-keyframes blink {
  50% {
    opacity: 1;
  }
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

.btn-file {
  align-items: center;
  display: inline-flex;
  font-size: 20px;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  overflow: hidden;
  padding: 0 0.75rem;
  position: relative;
  vertical-align: middle;
}

.btn-file input {
  cursor: pointer;
  filter: alpha(opacity=0);
  font-size: 23px;
  height: 100%;
  margin: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

/*-----------------
	27. Doctor Profile Settings
-----------------------*/

.profile-image img {
  margin-bottom: 1.5rem;
}

.change-photo-btn {
  background-color: #2f7ba4;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 13px;
  font-weight: 600;
  margin: 0 auto;
  padding: 10px 15px;
  position: relative;
  transition: 0.3s;
  text-align: center;
  width: 220px;
}

.change-photo-btn input.upload {
  bottom: 0;
  cursor: pointer;
  filter: alpha(opacity=0);
  left: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 220px;
}

.dropzone {
  background-color: #fbfbfb;
  border: 2px dashed rgba(0, 0, 0, 0.1);
}

.btn-icon {
  align-items: center;
  text-align: center;
  position: absolute;
  padding: 0;
  font-size: 10px;
  width: 20px;
  height: 20px;
  display: inline-flex;
  right: 3px;
  top: 3px;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.upload-images {
  position: relative;
  width: 80px;
}

.upload-images img {
  border-radius: 4px;
  height: 80px;
  width: auto;
}

.upload-wrap {
  display: flex;
}

.upload-wrap .upload-images + .upload-images {
  margin-left: 20px;
}

.contact-card .card-body {
  padding-bottom: 0.625rem;
}

.custom_price_cont {
  margin-top: 20px;
}

.btn.btn-danger.trash {
  align-items: center;
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.add-more a {
  color: #2f7ba4;
}

.bootstrap-tagsinput {
  border-color: #dcdcdc;
  box-shadow: inherit;
  min-height: 46px;
  width: 100%;
  border-radius: 0;
}

.bootstrap-tagsinput.focus {
  border-color: #bbb;
}

.bootstrap-tagsinput .tag {
  background-color: #2f7ba4;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  margin-right: 2px;
  padding: 11px 15px;
  border-radius: 0;
}

.services-card .bootstrap-tagsinput input {
  width: 160px;
}

.submit-section .submit-btn {
  padding: 12px 30px;
  font-weight: 600;
  font-size: 16px;
  min-width: 160px;
  border-radius: 0px;
}

.submit-section .submit-btn + .submit-btn {
  margin-left: 15px;
}

/*-----------------
	28. Calendar
-----------------------*/

#calendar-events {
  background-color: #fcfcfc;
}

.calendar-events {
  border: 1px solid transparent;
  cursor: move;
  padding: 10px 15px;
}

.calendar-events:hover {
  border-color: #e9e9e9;
  background-color: #fff;
}

.calendar-events i {
  margin-right: 8px;
}

.calendar {
  float: left;
  margin-bottom: 0;
}

.fc-toolbar.fc-header-toolbar {
  margin-bottom: 1.5rem;
}

.none-border .modal-footer {
  border-top: none;
}

.fc-toolbar h2 {
  font-size: 18px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  line-height: 30px;
  text-transform: uppercase;
}

.fc-day-grid-event .fc-time {
  font-family: "Roboto", sans-serif;
}

.fc-day {
  background: #fff;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0;
}

.fc th.fc-widget-header {
  background: #eeeeee;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0;
  text-transform: uppercase;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
  border-color: #f3f3f3;
}

.fc-basic-view .fc-day-number,
.fc-basic-view .fc-week-number {
  padding: 2px 5px;
}

.fc-button {
  background: #f1f1f1;
  border: none;
  color: #797979;
  text-transform: capitalize;
  box-shadow: none !important;
  border-radius: 3px !important;
  margin: 0 3px !important;
  padding: 6px 12px !important;
  height: auto !important;
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 16px;
}

.fc-state-hover {
  background: #f3f3f3;
}

.fc-state-highlight {
  background: #f0f0f0;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  background-color: #032c4f !important;
  color: #fff !important;
  text-shadow: none !important;
}

.fc-cell-overlay {
  background: #f0f0f0;
}

.fc-unthemed .fc-today {
  background: #fff;
}

.fc-event {
  border-radius: 2px;
  border: none;
  color: #fff !important;
  cursor: move;
  font-size: 13px;
  margin: 1px 7px;
  padding: 5px 5px;
  text-align: center;
}

.fc-basic-view td.fc-week-number span {
  padding-right: 8px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}

.fc-basic-view td.fc-day-number {
  padding-right: 8px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}

.event-form .input-group .form-control {
  height: 40px;
}

/*-----------------
	29. Patient Dashboard
-----------------------*/

.profile-sidebar {
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  margin-bottom: 30px;
  overflow: hidden;
}

.pro-widget-content {
  border-bottom: 1px solid #f0f0f0;
  padding: 20px;
  text-align: center;
}

.profile-info-widget {
  display: flex;
  text-align: left;
}

.profile-info-widget .booking-doc-img {
  margin-right: 15px;
}

.profile-info-widget .booking-doc-img img {
  border-radius: 4px;
  height: 90px;
  width: 90px;
  -o-object-fit: cover;
  object-fit: cover;
}

.profile-det-info {
  overflow: hidden;
}

.profile-det-info h3 {
  font-size: 17px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 600;
}

.patient-details h5 {
  color: #757575;
  font-size: 13px;
  font-weight: normal;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.patient-details h5 i {
  width: 18px;
}

.dashboard-menu ul {
  color: #757575;
  font-size: 14px;
  line-height: 17px;
  list-style: none;
  margin: 0;
  padding: 0;
  text-transform: capitalize;
}

.dashboard-menu ul li {
  line-height: inherit;
}

.dashboard-menu > ul > li {
  border-bottom: 1px solid #f0f0f0;
  position: relative;
}

.dashboard-menu > ul > li:last-child {
  border-bottom: 0;
}

.dashboard-menu ul li a span,
.dashboard-menu ul li a i {
  display: inline-block;
  vertical-align: middle;
}

.dashboard-menu > ul > li > a {
  color: #757575;
  display: block;
  padding: 16px 20px;
  font-weight: 600;
}

.dashboard-menu > ul > li:hover > a,
.dashboard-menu > ul > li.active > a {
  color: #2f7ba4;
}

.dashboard-menu ul li a i {
  font-size: 16px;
  margin-right: 10px;
  width: 16px;
}

.unread-msg {
  background-color: #032c4f;
  border-radius: 2px;
  color: #fff;
  font-size: 10px;
  font-style: normal;
  padding: 0 5px;
  position: absolute;
  right: 20px;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
}

/*-----------------
	30. Profile Settings
-----------------------*/

.change-avatar {
  align-items: center;
  display: flex;
}

.change-avatar .profile-img {
  margin-right: 15px;
}

.change-avatar .profile-img img {
  border-radius: 4px;
  height: 100px;
  width: 100px;
  -o-object-fit: cover;
  object-fit: cover;
}

.change-avatar .change-photo-btn {
  margin: 0 0 10px;
  width: 150px;
}

.widget-profile.pat-widget-profile .profile-info-widget .booking-doc-img {
  padding: 0;
}

.widget-profile.pat-widget-profile .profile-info-widget .booking-doc-img img {
  border-radius: 50%;
  height: 100px;
  width: 100px;
}

/*-----------------
	31. Appoitment List
-----------------------*/

.widget-profile {
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
}

.widget-profile .profile-info-widget {
  display: block;
  text-align: center;
}

.widget-profile .profile-info-widget .booking-doc-img {
  display: inline-block;
  margin: 0 0 15px;
  width: auto;
  padding: 8px;
  background-color: #f7f7f7;
  border-radius: 50%;
}

.widget-profile .profile-info-widget .booking-doc-img img {
  border-radius: 50%;
  height: 120px;
  width: 120px;
}

.appointment-list .profile-info-widget {
  display: flex;
  margin-right: auto;
  text-align: left;
}

.appointment-list .profile-info-widget .booking-doc-img img {
  border-radius: 4px;
  height: 120px;
  -o-object-fit: cover;
  object-fit: cover;
  width: 120px;
}

.appointments .appointment-list {
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding: 20px;
}

.appointments .appointment-list:last-child {
  margin-bottom: 30px;
}

.appointments .appointment-action {
  align-items: center;
  display: inline-flex;
}

.appointment-action a + a {
  margin-left: 5px;
}

.appointment-action a {
  font-size: 13px;
}

.info-details {
  list-style: none;
  margin: 0;
  padding: 0;
}

.info-details li .title {
  color: #272b41;
  font-weight: 500;
}

.info-details li .text {
  color: #757575;
  display: block;
  font-size: 16px;
  overflow: hidden;
}

.info-details li {
  margin-bottom: 10px;
}

.info-details li:last-child {
  margin-bottom: 0;
}

/*-----------------
	32. Reviews
-----------------------*/

.doc-review.review-listing {
  margin: 0;
}

.review-listing.doc-review > ul > li {
  background-color: #fff;
  border: 1px solid #f0f0f0;
  padding: 20px;
}

/*-----------------
	33. Voice call
-----------------------*/

.modal-open .main-wrapper {
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -o-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: blur(1px);
}

.call-main-row {
  bottom: 0;
  left: 0;
  overflow: auto;
  padding-bottom: inherit;
  padding-top: inherit;
  position: absolute;
  right: 0;
  top: 0;
}

.call-main-wrapper {
  display: table;
  height: 100%;
  table-layout: fixed;
  width: 100%;
}

.call-view {
  display: table-cell;
  height: 100%;
  float: none;
  padding: 0;
  position: static;
  vertical-align: top;
  width: 75%;
}

.call-window {
  display: table;
  height: 100%;
  table-layout: fixed;
  width: 100%;
  background-color: #fff;
  border: 1px solid #f0f0f0;
}

.fixed-header {
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 15px;
}

.fixed-header .navbar {
  border: 0 none;
  margin: 0;
  min-height: auto;
  padding: 0;
}

.fixed-header .user-info a {
  color: #272b41;
  font-weight: 500;
}

.typing-text {
  color: #2f7ba4;
  font-size: 12px;
  text-transform: lowercase;
}

.last-seen {
  color: #888;
  display: block;
  font-size: 12px;
}

.custom-menu {
  margin-top: 6px;
}

.fixed-header .custom-menu {
  margin: 0 0 1px;
}

.custom-menu.nav > li > a {
  color: #bbb;
  font-size: 26px;
  line-height: 32px;
  margin-left: 15px;
  padding: 0;
}

.custom-menu.navbar-nav > li > a:hover,
.custom-menu.navbar-nav > li > a:focus {
  background-color: transparent;
}

.custom-menu .dropdown-menu {
  left: auto;
  right: 0;
}

.call-contents {
  display: table-row;
  height: 100%;
}

.call-content-wrap {
  height: 100%;
  position: relative;
  width: 100%;
}

.voice-call-avatar {
  align-items: center;
  flex-direction: column;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  flex: 2;
}

.voice-call-avatar .call-avatar {
  margin: 15px;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 3px;
  background-color: #fff;
}

.call-duration {
  display: inline-block;
  font-size: 30px;
  margin-top: 4px;
  position: absolute;
  left: 0;
}

.voice-call-avatar .call-timing-count {
  padding: 5px;
}

.voice-call-avatar .username {
  font-size: 20px;
  font-weight: 500;
}

.call-footer {
  background-color: #fff;
  border-top: 1px solid #f0f0f0;
  padding: 15px;
}

.call-icons {
  text-align: center;
  position: relative;
}

.call-icons .call-items {
  border-radius: 5px;
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-block;
}

.call-icons .call-items .call-item,
.call-icons .call-items li.end-call-new {
  display: inline-block;
  text-align: center;
  margin-right: 5px;
}

.call-icons .call-items .call-item:last-child {
  margin-right: 0;
}

.call-icons .call-items .call-item a {
  color: #777;
  border: 1px solid #ddd;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50px;
  display: inline-block;
  font-size: 20px;
}

.call-icons .call-items .call-item a i {
  width: 18px;
  height: 18px;
}

.user-video {
  bottom: 0;
  left: 0;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}

.user-video img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  margin: 0 auto;
}

.user-video video {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  margin: 0 auto;
}

.my-video {
  position: absolute;
  z-index: 99;
  bottom: 20px;
  left: 20px;
}

.my-video ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.my-video ul li {
  float: left;
  width: 120px;
  margin-right: 10px;
}

.my-video ul li img {
  border: 3px solid #fff;
  border-radius: 6px;
}

.end-call {
  position: absolute;
  top: 7px;
  right: 0;
}

.end-call a,
.call-icons .call-items .end-call-new a {
  background-color: #f06060;
  border-radius: 50px;
  color: #fff;
  display: inline-block;
  line-height: 10px;
  padding: 8px 25px;
  text-transform: uppercase;
}

.call-users {
  position: absolute;
  z-index: 99;
  bottom: 20px;
  right: 20px;
}

.call-users ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.call-users ul li {
  float: left;
  width: 80px;
  margin-left: 10px;
}

.call-users ul li img {
  border-radius: 6px;
  padding: 2px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.call-mute {
  width: 80px;
  height: 80px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  text-align: center;
  line-height: 80px;
  border-radius: 6px;
  font-size: 30px;
  color: #fff;
  display: none;
  top: 0;
  border: 3px solid transparent;
}

.call-users ul li a:hover .call-mute {
  display: block;
}

.call-details {
  margin: 10px 0 0;
  display: flex;
}

.call-info {
  margin-left: 10px;
  width: 100%;
}

.call-user-details,
.call-timing {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.call-description {
  white-space: nowrap;
  vertical-align: bottom;
}

.call-timing {
  color: #727272;
  display: flex;
  font-size: 14px;
  margin-top: 1px;
  overflow: hidden;
  white-space: pre;
}

/*-----------------
	34. Video Call
-----------------------*/

.content-full {
  height: 100%;
  position: relative;
  width: 100%;
}

.video-window .fixed-header {
  padding: 0;
  border: 0;
}

.video-window .fixed-header .nav > li > a {
  padding: 18px 15px;
}

/*-----------------
	35. Outgoing Call
-----------------------*/

.call-box .call-wrapper {
  height: auto;
  text-align: center;
}

.call-box .call-wrapper .call-avatar {
  margin-bottom: 30px;
  cursor: pointer;
  -webkit-animation: ripple 2s infinite;
  animation: ripple 2s infinite;
}

.call-box .call-wrapper .call-user {
  margin-bottom: 30px;
}

.call-box .call-wrapper .call-user span {
  display: block;
  font-weight: 500;
  text-align: center;
}

.call-box .call-wrapper .call-items {
  align-items: center;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.call-box .call-wrapper .call-items .call-item {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid transparent;
  border-radius: 100%;
  color: #fff;
  line-height: 0;
  margin: 0 5px;
  padding: 15px;
}

.call-box .call-wrapper .call-items .call-item:hover {
  opacity: 0.9;
}

.call-box .call-wrapper .call-items .call-item:first-child {
  margin-top: -30px;
}

.call-box .call-wrapper .call-items .call-item:last-child {
  margin-top: -30px;
}

.call-box .call-wrapper .call-items .call-item.call-end {
  padding: 20px;
  margin: 30px 20px 0;
  background: #f06060;
  border: 1px solid #f06060;
  color: #fff;
  line-height: 0;
  border-radius: 100%;
}

.call-box .call-wrapper .call-items .call-item.call-start {
  padding: 20px;
  margin: 30px 20px 0;
  background: #55ce63;
  border: 1px solid #55ce63;
  color: #fff;
  line-height: 0;
  border-radius: 100%;
}

.call-box.incoming-box .call-wrapper .call-items .call-item.call-start {
  margin: 0 10px;
}

.call-box.incoming-box .call-wrapper .call-items .call-item.call-end {
  margin: 0 10px;
}

.call-box .call-avatar {
  border-radius: 100%;
  height: 140px;
  max-width: 140px;
  min-width: 140px;
  position: relative;
  width: 100%;
  border: 10px solid #fafafa;
}

.call-box .btn {
  background: rgba(0, 0, 0, 0);
  transition: all 0.3s ease 0s;
}

@-webkit-keyframes ripple {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  }

  100% {
    -webkit-box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
  }
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  }

  100% {
    box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
  }
}

/*-----------------
	36. Incoming Call
-----------------------*/

.incoming-btns {
  margin-top: 20px;
}

.call-wrapper {
  position: relative;
  height: calc(100vh - 145px);
}

.call-page .footer {
  display: none;
}

.dropdown-action .dropdown-toggle::after {
  display: none;
}

.call-modal .modal-body {
  padding: 40px;
}

.call-modal .modal-content {
  border: 0;
  border-radius: 10px;
}

.call-box .call-wrapper .call-user h4 {
  font-size: 24px;
}

/*-----------------
	37. Terms and Conditions
-----------------------*/

.terms-text {
  margin-bottom: 20px;
}

.terms-text h4 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}

.terms-text p {
  color: #666;
  display: inline-block;
  font-size: 16px;
}

/*-----------------
	38. Blog
-----------------------*/

.blog {
  border: 1px solid #f0f0f0;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 30px;
  padding: 1.5rem;
  position: relative;
}

.blog-image {
  overflow: hidden;
}

.blog-image,
.blog-image > a,
.blog-image img {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
}

.blog-image img {
  transform: translateZ(0);
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}

.blog-image a:hover img {
  transform: scale(1.15);
}

.blog-image {
  margin-bottom: 30px;
}

.blog-content {
  position: relative;
}

.blog-title {
  font-size: 22px;
  margin: 0 0 10px;
}

.blog-title a {
  color: #272b41;
}

.blog-title a:hover {
  color: #2f7ba4;
}

.blog-content p {
  color: #666;
  font-size: 14px;
  margin: 0 0 30px;
}

.read-more {
  display: inline-block;
  font-size: 16px;
  padding: 0;
  color: #032c4f;
  text-decoration: underline;
  font-weight: bold;
}

.blog-info {
  color: #757575;
  font-size: 14px;
  margin-bottom: 15px;
}

.blog-info a {
  color: #757575;
}

.blog-info a:hover {
  color: #757575;
}

.blog-info i {
  color: #032c4f;
  font-size: 14px;
  margin-right: 5px;
}

.entry-meta {
  color: #757575;
  font-size: 14px;
  list-style: none;
  margin-bottom: 15px;
  padding: 0;
}

.entry-meta li {
  display: inline-block;
  margin-right: 15px;
}

.entry-meta li:last-child {
  margin-right: 0;
}

.blog-pagination .pagination {
  margin-bottom: 30px;
}

.blog-pagination .pagination li a {
  padding: 12px 20px;
  font-weight: 600;
  color: #2e3842;
  margin-right: 10px;
  border-radius: inherit !important;
  border-color: #f0f0f0;
}

.blog-pagination .pagination li.disabled a {
  border-color: #f0f0f0;
}

.blog-pagination .pagination li.active a {
  background: #2f7ba4;
  border-color: transparent;
  color: #ffffff;
}

.post-left ul {
  margin: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.post-left ul li {
  margin-bottom: 5px;
  margin-right: 20px;
}

.post-left ul li:last-child {
  margin-right: 0;
}

.post-author a {
  display: flex;
  align-items: center;
}

.video {
  position: relative;
  padding-bottom: 56%;
  height: 0;
  overflow: hidden;
}

.video iframe,
.video object,
.video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.latest-posts {
  margin: 0;
  padding: 0;
}

.latest-posts li {
  display: table;
  width: 100%;
  margin-bottom: 20px;
}

.latest-posts li:last-child {
  padding-bottom: 0px;
  margin-bottom: 0px;
  border-bottom: none;
}

.post-thumb {
  border-radius: 4px;
  width: 80px;
  float: left;
  overflow: hidden;
}

.post-thumb a img {
  border-radius: 4px;
  transform: translateZ(0);
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}

.post-thumb a:hover img {
  transform: scale(1.15);
}

.post-info {
  margin-left: 95px;
}

.post-info h4 {
  font-size: 15px;
  margin: 0 0 5px;
}

.post-info h4 a {
  color: #272b41;
}

.post-info h4 a:hover {
  color: #2f7ba4;
}

.post-info p {
  color: #757575;
  font-size: 12px;
  margin: 0;
}

.post-info p i {
  color: #2f7ba4;
  font-size: 14px;
  margin-right: 4px;
}

.category-widget .categories {
  list-style: none;
  margin: 0;
  padding: 0;
}

.category-widget .categories li {
  margin-bottom: 20px;
}

.category-widget .categories li:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.category-widget .categories li a {
  color: #2e3842;
  font-size: 15px;
  font-weight: 500;
}

.category-widget .categories li a span {
  float: right;
  color: #757575;
}

.category-widget .categories li a:hover {
  color: #2f7ba4;
}

.category-widget .categories > li > a > i {
  color: #2f7ba4;
  font-size: 18px;
  margin-right: 10px;
}

.tags-widget .card-body {
  padding-bottom: 1rem;
}

.tags {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.tags li {
  float: left;
}

.tag {
  background-color: #fff;
  border-radius: 4px;
  color: #272b41;
  padding: 5px 10px;
  position: relative;
  margin: 0 0.5rem 0.5rem 0;
  font-size: 14px;
  border: 1px solid #ccc;
  display: inline-block;
}

.tag:hover {
  background-color: #2f7ba4;
  border-color: #2f7ba4;
  color: #fff;
}

.comment-by {
  display: block;
  font-size: 14px;
  line-height: 21px;
  margin: 0 0 10px;
}

.comments-list .comment-block p {
  font-size: 14px;
  margin-bottom: 5px;
}

/*-----------------
	39. Blog Details
-----------------------*/

.blog-view .blog-title {
  font-size: 24px;
}

.blog-content p:last-child {
  margin-bottom: 0;
}

.blog-view .blog-info {
  border: 0;
  margin-bottom: 20px;
  padding: 0;
}

.social-share {
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;
}

.social-share > li {
  display: inline-block;
  float: left;
  margin-left: 10px;
  text-align: center;
}

.social-share > li:first-child {
  margin-left: 0;
}

.social-share > li > a {
  border: 1px solid #dfdfdf;
  color: #666;
  display: inline-block;
  font-size: 22px;
  height: 40px;
  line-height: 40px;
  width: 40px;
}

.social-share > li > a:hover {
  background-color: #2f7ba4;
  color: #fff;
  border-color: #2f7ba4;
}

.blog-reply > a {
  color: #999;
  font-size: 12px;
  font-weight: 500;
}

.blog-date {
  color: #999;
  font-size: 12px;
}

.blog-comments .comments-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.blog-comments .comments-list li {
  clear: both;
  padding-left: 80px;
}

.blog-comments .comments-list li .comment {
  margin-bottom: 20px;
}

.blog-comments .comments-list li .comment-author {
  left: 0;
  position: absolute;
}

.blog-comments .comments-list li img.avatar {
  height: 58px;
  width: 58px;
  border-radius: 58px;
}

.blog-comments .comment-btn {
  color: #2f7ba4;
  display: inline-block;
  font-weight: 500;
  font-size: 15px;
}

.blog-author-name {
  color: #272b41;
  font-size: 16px;
  font-weight: 600;
}

.new-comment label {
  font-weight: 500;
}

.comment-submit .btn {
  background-color: #2f7ba4;
  border-color: #2f7ba4;
  border-radius: 0;
  font-size: 18px;
  padding: 8px 26px;
  color: #fff;
}

.about-author-img {
  background-color: #fff;
  height: 100px;
  overflow: hidden;
  position: absolute;
  width: 100px;
}

.author-details {
  margin-left: 120px;
}

.about-author {
  min-height: 100px;
}

.author-details .blog-author-name {
  display: inline-block;
  margin-bottom: 10px;
}

.post-author img {
  border-radius: 100%;
  width: 28px;
  margin-right: 5px;
}

/*-----------------
	40. Blog Grid
-----------------------*/

.grid-blog .blog-title {
  font-size: 18px;
  margin: 0 0 5px;
  min-height: 43px;
}

.grid-blog .blog-content p {
  margin: 0 0 15px;
}

.grid-blog .blog-info {
  margin-bottom: 15px;
}

.grid-blog .blog-image {
  margin-bottom: 20px;
}

.blog.grid-blog {
  padding: 20px;
}

.grid-blog .entry-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 0;
}

.grid-blog .entry-meta li {
  margin-bottom: 10px;
}

.grid-blog .entry-meta li:last-child {
  flex: 0 0 100px;
  max-width: 100px;
}

.grid-blog .post-author {
  width: 189px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.grid-blog .post-author a:hover {
  color: #2f7ba4;
}

/*-----------------
	41. Map List & Grid
-----------------------*/

.map-page .footer {
  display: none;
}

.map-page .header {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1030;
}

.map-page .content {
  padding-top: 115px;
}

.map-right {
  bottom: 0;
  height: 100%;
  min-height: 100%;
  padding-right: 30px;
  position: fixed;
  right: 0;
  top: 115px;
  z-index: 1029;
}

.map-listing {
  height: calc(100vh - 145px);
  width: 100%;
}

.map-listing .profile-widget {
  width: 200px !important;
  padding: 0;
  border: 0;
  margin-bottom: 0;
}

.map-right .available-info {
  margin-bottom: 0;
}

.view-icons {
  display: inline-flex;
  float: right;
}

.view-icons a {
  align-items: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #212529;
  display: flex;
  font-size: 20px;
  justify-content: center;
  padding: 4px 10px;
  text-align: center;
  margin-left: 10px;
  width: 44px;
  height: 44px;
}

.view-icons a:hover {
  border-color: #2f7ba4;
  color: #2f7ba4;
}

.view-icons a.active {
  background-color: #2f7ba4;
  border-color: #2f7ba4;
  color: #fff;
}

/*-----------------
	42. Loader
-----------------------*/

#loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 999999;
}

.loader {
  display: inline-block;
  height: 50px;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
  width: 50px;
  position: absolute;
  top: 50%;
}

.loader span {
  -webkit-animation: loader 1.3s linear infinite;
  animation: loader 1.3s linear infinite;
  background-color: #2f7ba4;
  border-radius: 100%;
  display: inline-block;
  height: 50px;
  position: absolute;
  width: 50px;
}

.loader span:last-child {
  animation-delay: -0.8s;
  -webkit-animation-delay: -0.8s;
}

@keyframes loader {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@-webkit-keyframes loader {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }
}

/*-----------------
	43. About Us
-----------------------*/

.about-section {
  background: url(../images/about-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 0;
  min-height: 700px;
}

.about-titile {
  font-size: 15px;
  color: #ffffff;
  background-color: #09e5ab;
  border: 1px solid #09e5ab;
  border-radius: 5px;
  padding: 12px 20px;
  display: inline-block;
}

.about-titile:hover {
  color: #fff;
}

.about-section h3 {
  color: #272b41;
  font-size: 25px;
  font-weight: 600;
}

.about-section p {
  font-size: 16px;
  color: #000;
}

.select-category {
  margin-top: -100px;
  padding: 50px 0;
  padding-top: 0;
}

.category-subox {
  background-color: #ffffff;
  box-shadow: 0px 4px 5px 5px #e5f1fb;
  border-radius: 8px;
  padding: 30px;
  text-align: center;
  overflow: hidden;
}

.category-subox h4 {
  color: #15558d;
  font-size: 18px;
  font-weight: 600;
  margin: 0 auto;
  margin-bottom: 20px;
}

.category-subox p {
  font-size: 16px;
  color: #000;
}

.subox-img {
  position: relative;
  padding-top: 90px;
  width: 100%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
}

.subox-img .subox-circle {
  width: 160px;
  height: 160px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: inset 0px 4px 5px 5px #e5f1fb;
  position: absolute;
  bottom: -70px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
}

.subox-img .subox-circle img {
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -30%);
  -webkit-transform: translate(-50%, -30%);
  -ms-transform: translate(-50%, -30%);
}

.category-col {
  position: relative;
}

.category-col:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -15px;
  width: 150px;
  height: 26px;
  background-color: #fff;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  border-radius: 10px 10px;
}

.about-page {
  background-color: #fff;
}

.about-page .section-specialities {
  background-color: #e5f1fb;
}

.about-page .section-specialities {
  padding: 50px 0;
}

.section-testimonial {
  padding: 50px 0;
}

.testimonial-slider .slick-slide {
  display: block;
  margin-left: 0;
  padding: 10px;
  width: 300px;
}

.section-testimonial .testimonial-item {
  position: relative;
  margin-bottom: 30px;
}

.testimonial-item .testimonial-img {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  margin-bottom: 30px;
}

.testimonial-item .testimonial-img img {
  border-radius: 50%;
}

.testimonial-item .testimonial-content {
  background: #ffffff;
  box-shadow: 0px 4px 5px 5px #e5f1fb;
  border-radius: 8px;
  padding: 20px;
  padding-top: 90px;
  margin-top: -100px;
}

.testimonial-item .testimonial-content p {
  font-size: 16px;
  color: #000;
}

.testimonial-item .testimonial-content p.user-name {
  color: #0071dc;
  font-weight: 600;
}

.testimonial-slider .slick-dots {
  margin-bottom: 30px;
}

.feature-subox {
  background: #fff;
  box-shadow: 0px 4px 5px 5px #e5f1fb;
  border-radius: 8px;
  padding: 15px 15px 35px;
  margin-bottom: 45px;
}

.feature-subox h4 {
  color: #15558d;
  font-size: 18px;
  margin: 25px 0 0;
  font-weight: 600;
}

.section-featurebox {
  padding: 35px 0 135px;
  background: url(../images/features/feature-box-bg.png) no-repeat bottom center;
}

.feature-subox img {
  width: 100%;
  border-radius: 5px;
}

.feature-col-list {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
}

.feature-col {
  width: 350px;
  margin: 0 15px;
}

/*-----------------
	44. Contact Us
-----------------------*/
.contact-section {
  padding: 50px 0 60px;
  background: linear-gradient(
    360deg,
    rgba(0, 113, 220, 0.06) 40.94%,
    rgba(229, 241, 251, 0) 100%
  );
}

.contact-section h3 {
  color: #272b41;
  font-size: 36px;
}

.contact-section p {
  color: #000000;
  max-width: 710px;
  margin: 0 auto;
}

.infor-img {
  position: relative;
  margin: 0 auto;
}

.infor-img .image-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #0de0fe;
  box-shadow: 0px 10px 4px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 30px;
  margin: 0 auto;
}

.contact-box .infor-details {
  background: #ffffff;
  box-shadow: 0px 4px 5px 5px #e5f1fb;
  border-radius: 8px;
  padding: 20px;
  padding-top: 80px;
  margin-top: -50px;
  width: 100%;
}

.contact-box .infor-details label {
  font-size: 16px;
  color: #000000;
  margin-bottom: 20px;
}

.contact-box .infor-details p {
  color: #0071dc;
  font-size: 16px;
  font-weight: 600;
}

.contact-form {
  padding: 50px 0 60px;
  background-color: #fff;
}

.contact-form .section-header {
  margin-bottom: 50px;
}

.contact-form form {
  background: #ffffff;
  box-shadow: 0px 4px 5px 5px #e5f1fb;
  border-radius: 8px;
  padding: 30px;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}

.contact-form form label span {
  color: #ff0000;
}

.contact-form form input,
.contact-form form textarea {
  background: #ffffff;
  border: 1px solid #e5f1fb;
  border-radius: 8px;
}

.contact-form form textarea {
  min-height: 200px;
  resize: none;
  padding: 6px 15px;
}

.contact-form .btn {
  font-size: 15px;
  font-weight: 600;
  padding: 11px 30px;
  color: #fff;
  border-radius: 8px;
  border: 1px solid #e5f1fb;
}

.contact-map iframe {
  width: 100%;
  border: none;
  height: 400px;
}

.MuiDropzoneArea-icon {
  color: rgba(0, 0, 0, 0.87);
  width: 40px;
  height: 51px;
  display: none !important;
}

.MuiGrid-spacing-xs-8 {
  width: calc(44% + 30px) !important;
  margin: 0 !important;
}

button.MuiButtonBase-root.MuiFab-root.MuiDropzonePreviewList-removeButton {
  position: relative;
  text-align: center;
  z-index: 20;
  left: 0;
  top: -65px;
  right: 0;
}

.MuiDropzoneArea-root {
  min-height: 150px !important;
}

.MuiTypography-h5 {
  font-size: 15px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
}

.dropdown-toggle {
  background: none !important;
  border: none !important;
}

.download-app-appoinment {
  color: #2f7ba4;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin: 0 10px;
}

.search-header .available-text {
  color: #001818;
  font-size: 18px;
  font-weight: 400;
}

.ap-qr-img {
  width: 150px;
  height: 150px;
}

.book-you-next-section .d-main-section {
  margin-top: 100px;
}

/*-----------------
	45. Responsive
-----------------------*/

@media only screen and (min-width: 768px) {
  .avatar-xxl {
    width: 8rem;
    height: 8rem;
  }

  .avatar-xxl .border {
    border-width: 4px !important;
  }

  .avatar-xxl .rounded {
    border-radius: 12px !important;
  }

  .avatar-xxl .avatar-title {
    font-size: 42px;
  }

  .avatar-xxl.avatar-away:before,
  .avatar-xxl.avatar-offline:before,
  .avatar-xxl.avatar-online:before {
    border-width: 4px;
  }
}

@media (min-width: 992px) {
  .main-nav > li {
    margin-right: 30px;
  }

  .main-nav > li:last-child {
    margin-right: 0;
  }

  .main-nav li {
    display: block;
    position: relative;
  }

  .main-nav > li > a {
    line-height: 70px;
    padding: 0 !important;
  }

  .main-nav > li > a > i {
    font-size: 12px;
    margin-left: 3px;
  }

  .main-nav li > ul {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    display: block;
    font-size: 14px;
    left: 0;
    margin: 0;
    min-width: 200px;
    opacity: 0;
    padding: 0;
    position: absolute;
    transition: all 0.2s ease;
    transform: translateY(20px);
    top: 100%;
    visibility: hidden;
    z-index: 1000;
  }

  .main-nav li ul.submenu::before {
    border: 7px solid #fff;
    border-color: transparent transparent #fff #fff;
    box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
    content: "";
    left: 45px;
    position: absolute;
    top: 2px;
    transform-origin: 0 0;
    transform: rotate(135deg);
  }

  .main-nav li.has-submenu:hover > .submenu {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
    transform: translateY(0);
  }

  .main-nav .has-submenu.active > a {
    color: #2f7ba4;
  }

  .main-nav .has-submenu.active .submenu li.active > a {
    color: #2f7ba4;
  }

  .main-nav > li .submenu li:first-child a {
    border-top: 0;
  }

  .main-nav
    > li.has-submenu:hover
    > .submenu
    > li.has-submenu:hover
    > .submenu {
    visibility: visible;
    opacity: 1;
    margin-top: -1px;
    margin-right: 0;
  }

  .main-nav > li .submenu > li .submenu {
    left: 100%;
    top: 0;
    margin-top: 10px;
  }

  .main-nav li .submenu a:hover {
    color: #2f7ba4;
    letter-spacing: 0.5px;
    padding-left: 20px;
  }

  .main-nav > .has-submenu > .submenu > .has-submenu > .submenu::before {
    top: 20px;
    margin-left: -35px;
    box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.15);
    border-color: transparent #fff #fff transparent;
  }

  .header-navbar-rht li.show > .dropdown-menu {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
    transform: translateY(0);
  }

  .header-navbar-rht li .dropdown-menu {
    border-radius: 5px;
    padding: 0;
    margin: 0;
    min-width: 200px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease;
    display: block;
    transform: translateY(20px);
  }
}

@media only screen and (max-width: 1399px) {
  .chat-cont-left
    .chat-users-list
    a.media
    .media-body
    > div:first-child
    .user-name,
  .chat-cont-left
    .chat-users-list
    a.media
    .media-body
    > div:first-child
    .user-last-chat {
    max-width: 180px;
  }

  .row.row-grid {
    margin-left: -8px;
    margin-right: -8px;
  }

  .row.row-grid > div {
    padding-right: 8px;
    padding-left: 8px;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .section-title {
    font-size: 28px;
  }

  .banner-wrapper .banner-header h1 {
    font-size: 42px;
  }

  .service-content-one p {
    min-height: 135px !important;
  }
}

@media only screen and (max-width: 1199px) {
  .header-nav {
    padding-left: 20px;
    padding-right: 20px;
  }

  .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
  }

  .chat-cont-left
    .chat-users-list
    a.media
    .media-body
    > div:first-child
    .user-name,
  .chat-cont-left
    .chat-users-list
    a.media
    .media-body
    > div:first-child
    .user-last-chat {
    max-width: 150px;
  }

  .chat-cont-left {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .chat-cont-right {
    flex: 0 0 60%;
    max-width: 60%;
  }

  .row.row-grid > div {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .dash-widget {
    flex-direction: column;
    text-align: center;
  }

  .circle-bar {
    margin: 0 0 15px;
  }

  .header-navbar-rht li.contact-item {
    display: none;
  }

  .map-right {
    height: 450px;
    position: static;
    min-height: inherit;
    margin-bottom: 30px;
    padding-right: 15px;
  }

  .map-listing {
    height: 450px;
  }

  .map-page .content {
    padding-top: 100px;
  }
}

@media only screen and (max-width: 991.98px) {
  .main-nav {
    padding: 0;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  .main-nav ul {
    background-color: #2f7ba4;
    list-style: none;
    margin: 0;
    padding-left: 0;
  }

  .main-nav > li {
    border-bottom: 1px solid #eaeaea;
    margin-left: 0;
  }

  .main-nav li + li {
    margin-left: 0;
  }

  .main-nav > li > a {
    line-height: 1.5;
    padding: 15px 20px !important;
    color: #000;
    font-size: 14px;
    font-weight: 500;
  }

  .main-nav > li > a > i {
    float: right;
    margin-top: 5px;
  }

  .main-nav > li .submenu li a {
    border-top: 0;
    color: #fff;
    padding: 10px 15px 10px 35px;
  }

  .main-nav > li .submenu ul li a {
    padding: 10px 15px 10px 45px;
  }

  .main-nav > li .submenu > li.has-submenu > a::after {
    content: "\f078";
  }

  .main-nav .has-submenu.active > a {
    color: #2f7ba4;
  }

  .main-nav .has-submenu.active .submenu li.active > a {
    color: #2f7ba4;
  }

  .login-left {
    display: none;
  }

  .main-menu-wrapper {
    order: 3;
    width: 260px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1060;
    transform: translateX(-260px);
    transition: all 0.4s;
    background-color: #fff;
  }

  .menu-header {
    display: flex;
  }

  .navbar-header {
    align-items: center;
    display: inline-flex;
  }

  #mobile_btn {
    display: inline-block;
  }

  .home-banner {
    min-height: 330px;
  }

  .section-specialities {
    padding: 50px 0;
  }

  .footer-widget {
    margin-bottom: 30px;
  }

  .chat-cont-left,
  .chat-cont-right {
    flex: 0 0 100%;
    max-width: 100%;
    transition: left 0.3s ease-in-out 0s, right 0.3s ease-in-out 0s;
    width: 100%;
  }

  .chat-cont-left {
    border-right: 0;
  }

  .chat-cont-right {
    position: absolute;
    right: -100%;
    top: 0;
    opacity: 0;
    visibility: hidden;
  }

  .chat-cont-right .chat-header {
    justify-content: start;
    -webkit-justify-content: start;
    -ms-flex-pack: start;
  }

  .chat-cont-right .chat-header .back-user-list {
    display: block;
  }

  .chat-cont-right .chat-header .chat-options {
    margin-left: auto;
  }

  .chat-window.chat-slide .chat-cont-left {
    left: -100%;
  }

  .chat-window.chat-slide .chat-cont-right {
    right: 0;
    opacity: 1;
    visibility: visible;
  }

  .day-slot li.left-arrow {
    left: -10px;
  }

  .container {
    max-width: 100%;
  }

  .appointments .appointment-action {
    margin-top: 10px;
  }

  .appointments .appointment-list {
    display: block;
  }

  .home-banner .media {
    display: none;
  }

  .search-box .search-info {
    flex: 0 0 410px;
    width: 410px;
  }

  .banner-wrapper .banner-header h1 {
    font-size: 30px;
  }

  .dct-border-rht {
    border-bottom: 1px solid #f0f0f0;
    border-right: 0;
    margin-bottom: 20px;
    padding-bottom: 15px;
  }

  .card-label > label {
    font-size: 12px;
  }

  .footer .footer-top {
    padding-bottom: 10px;
  }

  .review-listing .recommend-btn {
    float: none;
  }

  .dash-widget {
    flex-direction: unset;
    text-align: left;
  }

  .circle-bar {
    margin: 0 15px 0 0;
  }

  .call-wrapper {
    height: calc(100vh - 140px);
  }

  .sidebar-overlay.opened {
    display: block;
  }

  .about-content {
    margin-bottom: 30px;
  }

  .section-title {
    font-size: 26px;
  }

  .section-special-services .card h3 {
    font-size: 18px;
    height: 36px;
  }

  .top-header {
    display: none;
  }

  .banner-bottom .card h2 {
    font-size: 14px;
  }

  .video-sec .play-btn {
    font-size: 20px;
    padding: 13px 21px;
    border-radius: 50%;
    width: 75px;
    height: 75px;
  }

  .header .md-po-select-l {
    margin-left: auto;
    width: 100px;
  }

  .header .navbar {
    width: 100%;
  }

  .header .navbar-header {
    width: 100%;
  }
}

@media only screen and (max-width: 849.98px) {
  .row.row-grid > div {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767.98px) {
  body {
    font-size: 0.875rem;
  }

  h1,
  .h1 {
    font-size: 2rem;
  }

  h2,
  .h2 {
    font-size: 1.75rem;
  }

  h3,
  .h3 {
    font-size: 1.375rem;
  }

  h4,
  .h4 {
    font-size: 1rem;
  }

  h5,
  .h5 {
    font-size: 0.875rem;
  }

  h6,
  .h6 {
    font-size: 0.75rem;
  }

  .content {
    padding: 15px 0 0;
  }

  .account-page .content {
    padding: 15px 0;
  }

  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }

  .card {
    margin-bottom: 20px;
  }

  .profile-sidebar {
    margin-bottom: 20px;
  }

  .appointment-tab {
    margin-bottom: 20px;
  }

  .breadcrumb-bar {
    height: auto;
  }

  .login-right {
    margin: 0 15px;
    padding: 15px;
  }

  .chat-cont-left
    .chat-users-list
    a.media
    .media-body
    > div:first-child
    .user-name,
  .chat-cont-left
    .chat-users-list
    a.media
    .media-body
    > div:first-child
    .user-last-chat {
    max-width: 250px;
  }

  .chat-window .chat-cont-right .chat-header .media .media-body {
    display: none;
  }

  .banner-wrapper .banner-header h1 {
    font-size: 2rem;
  }

  .banner-wrapper .banner-header p {
    font-size: 1rem;
  }

  .section-header h2 {
    font-size: 1.875rem;
  }

  .section-header .sub-title {
    font-size: 0.875rem;
  }

  .speicality-item p {
    font-size: 0.875rem;
  }

  .section-header p {
    font-size: 0.9375rem;
  }

  .footer-title {
    font-size: 1.125rem;
  }

  .search-box {
    max-width: 535px;
    margin: 0 auto;
  }

  .search-box form {
    flex-direction: column;
    word-wrap: break-word;
    background-clip: border-box;
  }

  .search-box .search-location {
    flex: 0 0 100%;
    width: 100%;
  }

  .search-box .search-info {
    flex: 0 0 100%;
    width: 100%;
  }

  .search-box .search-btn {
    flex: 0 0 100%;
    min-height: 46px;
    width: 100%;
  }

  .search-box .search-btn span {
    display: inline-block;
    margin-left: 5px;
    text-transform: uppercase;
  }

  .home-banner {
    background: #032c4f;
  }

  .day-slot li span {
    font-size: 16px;
    text-transform: unset;
  }

  .time-slot li .timing span {
    display: block;
  }

  .submit-section.proceed-btn {
    margin-bottom: 20px;
  }

  .day-slot li small.slot-year {
    display: none;
  }

  .success-cont h3 {
    font-size: 22px;
  }

  .view-inv-btn {
    font-size: 14px;
    padding: 10px 30px;
  }

  .invoice-info.invoice-info2 {
    text-align: left;
  }

  .invoice-item .invoice-details {
    text-align: left;
  }

  .home-banner,
  .section-doctor,
  .section-features {
    padding: 50px 0;
  }

  /* .slick-prev,
  .slick-next {
    width: 45px;
  } */

  /* .slick-prev {
    right: 50px;
  } */

  .submit-section .submit-btn {
    padding: 10px 20px;
    font-size: 15px;
    min-width: 160px;
  }

  .policy-menu {
    margin-top: 10px;
    text-align: left;
  }

  .booking-doc-info .booking-doc-img {
    width: 75px;
  }

  .booking-doc-info .booking-doc-img img {
    height: 75px;
    width: 75px;
  }

  .btn.btn-danger.trash {
    margin-bottom: 20px;
  }

  .nav-tabs.nav-tabs-bottom > li > a.active,
  .nav-tabs.nav-tabs-bottom > li > a.active:hover,
  .nav-tabs.nav-tabs-bottom > li > a.active:focus {
    background-color: #f5f5f5;
  }

  .nav-tabs.nav-justified {
    border-bottom: 1px solid #ddd;
  }

  .nav-tabs.nav-justified > li > a.active,
  .nav-tabs.nav-justified > li > a.active:hover,
  .nav-tabs.nav-justified > li > a.active:focus {
    border-color: transparent transparent transparent #2f7ba4;
    border-left-width: 2px;
  }

  .nav-tabs {
    border-bottom: 0;
    position: relative;
    background-color: #fff;
    padding: 5px 0;
    border: 1px solid #ddd;
    border-radius: 3px;
  }

  .nav-tabs .nav-item {
    margin-bottom: 0;
  }

  .nav-tabs > li > a {
    border-width: 2px;
    border-left-color: transparent;
  }

  .nav-tabs .nav-link {
    border-width: 2px;
  }

  .nav-tabs > li > a:hover,
  .nav-tabs > li > a:focus {
    background-color: #fafafa;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active,
  .nav-tabs > li > a.active,
  .nav-tabs > li > a.active:hover,
  .nav-tabs > li > a.active:focus {
    border-color: transparent transparent transparent #2f7ba4 !important;
    border-left-width: 2px;
  }

  .nav-tabs > li.open:not(.active) > a,
  .nav-tabs > li.open:not(.active) > a:hover,
  .nav-tabs > li.open:not(.active) > a:focus {
    background-color: #fafafa;
  }

  .nav-tabs.nav-tabs-solid {
    padding: 5px;
  }

  .nav-tabs.nav-tabs-solid.nav-tabs-rounded {
    border-radius: 5px;
  }

  .nav-tabs.nav-tabs-solid > li > a {
    border-left-width: 0 !important;
  }

  .nav-tabs-justified {
    border-bottom: 1px solid #ddd;
  }

  .nav-tabs-justified > li > a.active,
  .nav-tabs-justified > li > a.active:hover,
  .nav-tabs-justified > li > a.active:focus {
    border-width: 0 0 0 2px;
    border-left-color: #2f7ba4;
  }

  .review-listing > ul li .comment .comment-body .meta-data span.comment-date {
    margin-bottom: 5px;
  }

  .review-listing > ul li .comment .comment-body .meta-data .review-count {
    position: unset;
  }

  .my-video ul li {
    width: 50px;
  }

  .call-users ul li {
    width: 50px;
  }

  .call-mute {
    font-size: 20px;
    height: 50px;
    line-height: 50px;
    width: 50px;
  }

  .call-duration {
    font-size: 24px;
  }

  .voice-call-avatar .call-avatar {
    height: 100px;
    width: 100px;
  }

  .user-tabs {
    margin-top: 1.5rem;
  }

  .user-tabs .nav-tabs > li > a {
    border-left: 2px solid transparent;
    border-bottom: 0;
    padding: 0.5rem 1rem;
  }

  .user-tabs .nav-tabs.nav-tabs-bottom > li > a.active,
  .user-tabs .nav-tabs.nav-tabs-bottom > li > a.active:hover,
  .user-tabs .nav-tabs.nav-tabs-bottom > li > a.active:focus {
    border-left-width: 2px;
    color: #2f7ba4;
  }

  .doctor-widget {
    flex-direction: column;
    text-align: center;
  }

  .doc-info-right {
    margin-left: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .doc-info-left {
    flex-direction: column;
  }

  .clinic-services {
    display: none;
  }

  .doctor-img {
    margin: 0 auto 20px;
  }

  .doctor-action {
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
  }

  .row.row-grid > div {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .breadcrumb-bar .breadcrumb-title {
    font-size: 18px;
  }

  .dash-widget h6 {
    font-size: 15px;
  }

  .dash-widget h3 {
    font-size: 20px;
  }

  .dash-widget p {
    font-size: 13px;
  }

  .doctor-widget .doc-name {
    font-size: 18px;
  }

  .exp-title,
  .booking-total ul li span,
  .booking-total ul li .total-cost {
    font-size: 14px;
  }

  .invoice-item .customer-text {
    font-size: 16px;
  }

  .call-wrapper {
    height: calc(100vh - 115px);
  }

  .appointment-tab .nav-tabs {
    padding: 1.5rem;
  }

  .submit-btn-bottom {
    margin-bottom: 20px;
  }

  .service-list ul li {
    width: 50%;
  }

  .grid-blog .blog-title {
    min-height: inherit;
  }

  .blog-title {
    font-size: 20px;
  }

  .blog-view .blog-title {
    font-size: 22px;
  }

  .see-doc {
    position: relative;
    top: -15px;
  }

  .about-us {
    padding: 20px 0px;
  }

  .section-title {
    font-size: 18px;
  }

  .card-testi h4 {
    font-size: 16px;
  }

  .footer .social-icon ul li a {
    font-size: 14px;
    width: 30px;
    height: 30px;
    padding: 8px;
  }

  .contact-us {
    background-image: none;
  }

  .title-top {
    font-size: 12px;
  }

  .banner-bottom .card h2 {
    font-size: 16px;
  }

  .btn-primary,
  .btn-outline-primary {
    font-size: 12px;
  }

  .banner-wrapper .banner-header {
    width: 100%;
  }

  .about-us {
    background-image: none;
  }

  .about-us img {
    margin-top: 30px;
  }

  .about-us .media {
    margin-top: -150px;
  }

  .our-customer-says img {
    display: none;
  }

  .counts h4 {
    margin: 0px 0px 20px;
  }

  .our-blog img {
    width: 100px;
  }

  .blog-img img {
    width: 100%;
  }
}

@media only screen and (max-width: 575.98px) {
  body {
    font-size: 0.8125rem;
  }

  h1,
  .h1 {
    font-size: 1.75rem;
  }

  h2,
  .h2 {
    font-size: 1.5rem;
  }

  h3,
  .h3 {
    font-size: 1.25rem;
  }

  h4,
  .h4 {
    font-size: 1rem;
  }

  h5,
  .h5 {
    font-size: 0.875rem;
  }

  h6,
  .h6 {
    font-size: 0.75rem;
  }

  .card {
    margin-bottom: 0.9375rem;
  }

  .card-body {
    padding: 1.25rem;
  }

  .card-header {
    padding: 0.75rem 1.25rem;
  }

  .card-footer {
    padding: 0.75rem 1.25rem;
  }

  .header-nav {
    padding-left: 15px;
    padding-right: 15px;
  }

  .header-navbar-rht {
    display: none;
  }

  .main-nav li.login-link {
    display: block;
  }

  .navbar-header {
    width: 100%;
  }

  #mobile_btn {
    left: 0;
    margin-right: 0;
    padding: 0 15px;
    position: absolute;
    z-index: 99;
    margin-top: 6px;
  }

  .navbar-brand.logo {
    width: 100%;
    text-align: center;
    margin-right: 0;
  }

  .navbar-brand.logo img {
    height: 30px;
  }

  .search-box form {
    display: block;
  }

  .search-box .search-location {
    width: 100%;
    flex: none;
  }

  .search-box .search-info {
    width: 100%;
    flex: none;
  }

  .banner-wrapper .banner-header h1 {
    font-size: 23px;
    line-height: 33px;
  }

  /* .slick-next {
    right: 0px;
  } */

  .section-header h2 {
    font-size: 1.5rem;
  }

  .section-header .sub-title {
    font-size: 0.875rem;
  }

  .speicality-item p {
    font-size: 0.875rem;
  }

  .section-header p {
    font-size: 0.9375rem;
  }

  .footer-title {
    font-size: 1.125rem;
  }

  .booking-schedule .day-slot ul {
    display: flex;
  }

  .booking-schedule .day-slot li {
    flex: 0 0 100px;
    width: 100px;
  }

  .booking-schedule .time-slot li .timing span {
    display: inline-block;
  }

  .booking-schedule .day-slot li.right-arrow {
    right: -20px;
  }

  .booking-doc-info .booking-doc-img {
    width: 70px;
  }

  .booking-doc-info .booking-doc-img img {
    height: 70px;
    width: 70px;
  }

  .voice-call-avatar .call-avatar {
    height: 80px;
    width: 80px;
  }

  .call-duration {
    display: block;
    margin-top: 0;
    margin-bottom: 10px;
    position: inherit;
  }

  .end-call {
    margin-top: 10px;
    position: inherit;
  }

  .user-tabs .med-records {
    min-width: 110px;
  }

  .pagination-lg .page-link {
    font-size: 1rem;
    padding: 0.5rem 0.625rem;
  }

  .row.row-grid > div {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .edit-link {
    font-size: 14px;
    margin-top: 2px;
  }

  .invoice-content {
    padding: 1.25rem;
  }

  .change-avatar .profile-img img {
    height: 80px;
    width: 80px;
  }

  .submit-btn-bottom {
    margin-bottom: 0.9375rem;
  }

  .service-list ul li {
    width: 100%;
  }

  .blog {
    padding: 1.25rem;
  }

  .blog-title {
    font-size: 18px;
  }

  .blog-view .blog-title {
    font-size: 20px;
  }

  .blog-info i {
    font-size: 14px;
  }

  .post-left ul li {
    margin-right: 10px;
  }

  .post-left ul li:last-child {
    margin-right: 10px;
  }

  .about-author-img {
    height: 60px;
    width: 60px;
  }

  .author-details {
    margin-left: 80px;
  }

  .blog-comments .comments-list li img.avatar {
    border-radius: 40px;
    height: 40px;
    width: 40px;
  }

  .blog-comments .comments-list li {
    padding: 10px 0 0 50px;
  }

  .read-more {
    font-size: 14px;
  }

  .download-app ul {
    flex-direction: column;
  }

  .home-banner .media {
    display: none;
  }

  .section-title:after {
    width: 50px;
  }

  .text-center .section-title:after {
    width: 50px;
    left: 41%;
  }

  .download-app ul li:not(:first-child) {
    padding-bottom: 10px;
    color: #fff;
  }

  .footer-title {
    margin-bottom: 10px;
  }

  .banner-header .btn-primary,
  .banner-header .btn-outline-primary {
    margin: 0px !important;
    font-size: 12px !important;
    padding: 8px 18px !important;
  }

  .home-banner {
    min-height: 270px !important;
    padding: 30px 15px !important;
  }

  .health-care .btn-primary {
    margin: 10px auto 0 0;
  }

  .video-title {
    padding: 60px 0 70px;
  }

  .video-sec img {
    width: 80%;
  }

  .video-sec {
    margin: -50px 0px 25px;
  }

  .counts h4 {
    font-size: 16px;
    margin: 0px 0px 20px;
  }

  .our-blog img {
    width: 100px;
  }

  .blog-img img {
    width: 100%;
  }

  .video-sec .play-btn {
    border: 5px solid #f1f1f1;
    font-size: 15px;
    padding: 16px 22px;
    border-radius: 50%;
    width: 60px;
    height: 60px;
  }

  .xs-b {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .video-sec iframe {
    width: 100%;
    position: relative;
    height: auto;
  }
}

@media only screen and (max-width: 479px) {
  .home-banner {
    min-height: 410px;
    padding: 30px 15px;
  }

  .speicality-img {
    width: 120px;
    height: 120px;
  }

  .speicality-img img {
    width: 50px;
  }

  .clinic-booking div.view-pro-btn {
    width: 100%;
    margin-bottom: 15px;
  }

  .ct-apt-btn {
    width: 100%;
  }

  .chat-cont-left
    .chat-users-list
    a.media
    .media-body
    > div:first-child
    .user-name,
  .chat-cont-left
    .chat-users-list
    a.media
    .media-body
    > div:first-child
    .user-last-chat {
    max-width: 160px;
  }

  .section-features,
  .section-features,
  .section-specialities {
    background-color: #fff;
    padding: 30px 0;
  }

  .login-header h3 a {
    color: #0de0fe;
    float: none;
    font-size: 15px;
    margin-top: 10px;
    text-align: center;
    display: block;
  }

  .login-header h3 {
    text-align: center;
  }

  .appointments .appointment-list {
    text-align: center;
  }

  .appointment-list .profile-info-widget {
    flex-direction: column;
  }

  .appointment-list .profile-info-widget {
    text-align: center;
  }

  .appointment-list .profile-info-widget .booking-doc-img {
    margin: 0 0 15px;
  }

  .appointment-list .profile-info-widget .booking-doc-img img {
    border-radius: 50%;
    height: 100px;
    width: 100px;
  }

  .appointment-list .profile-det-info {
    margin-bottom: 15px;
  }

  .appointments .appointment-action {
    margin-top: 0;
  }

  .user-tabs .nav-tabs .nav-item {
    flex: 0 0 100%;
  }

  .review-listing .recommend-btn span {
    display: block;
    margin-bottom: 10px;
  }

  .review-listing > ul li .comments-reply {
    margin-left: 0;
  }

  .schedule-nav .nav-tabs li {
    display: block;
    flex: 0 0 100%;
    margin-right: 0;
  }

  .fc-header-toolbar .fc-left {
    margin-bottom: 10px;
  }

  .fc-header-toolbar .fc-right {
    margin-bottom: 10px;
  }

  .about-us .media img {
    display: none;
  }

  .about-us .media {
    margin-top: -172px;
  }

  .mob-disp {
    flex-direction: column;
  }

  .mob-disp img {
    display: none;
  }

  .video-sec .play-btn {
    border: 5px solid #f1f1f1;
    font-size: 12px;
    padding: 7px 11px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
}

/* dependent styles */
.dependent .help-title {
  text-align: center;
  padding-top: 70px;
  padding-bottom: 50px;
  font-size: 30px;
}

.card-body.dependent .row {
  padding-bottom: 50px;
}

.depedent-name {
  /* text-align: center; */
}

.dependent-img {
  padding: 0px;
  width: 130px;
  border-radius: 50%;
  border: 4px solid #2f7ba4;
}

.add-dependent-link i.fa.fa-plus.add-dependent-img {
  font-size: 50px;
  border-radius: 50%;
  border: 4px solid #032c4f;
  padding: 30px 35px;
  outline: unset;
  color: #032c4f;
}

.btn-group-toggle > .btn:first-child {
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
}

.btn-group-toggle > .btn:last-child {
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  /* border-radius: 30px; */
  border: 1px solid #6c757d;
  color: #6c757d;
  background-color: #fff;
  outline: unset;
}

.btn-group.btn-toggle.gender {
  width: 95%;
}

select#CustomSelect {
  outline: unset;
  border: 1px solid #6c757d;
  border-radius: 4px;
  box-shadow: unset;
}

.add-dependent input[type="text"],
.add-dependent input[type="date"] {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  outline: unset;
}

.add-title-one {
  font-size: 22px;
  font-weight: 500;
}

.add-title-two {
  font-size: 16px;
  font-weight: 500;
}

@media only screen and (min-width: 736px) and (max-width: 768px) {
  .card-body.add-dependent .col-md-6 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .card-body.dependent .col-md-3 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

/* register */
.terms-and-policy span.terms,
.terms-and-policy a {
  color: #2f7ba4;
}

span.agree {
  font-size: 16px;
  margin-left: 6px;
}

input[type="checkbox"]#agree {
  vertical-align: middle;
}

.service-col .col-md-3 {
  text-align: center;
}

.service-col .service-btn {
  width: 100%;
  background-color: #032c4f;
  color: #fff;
  border-radius: 5px;
  padding: 10px 0px 7px 0px;
  font-size: 16px;
  border-top: 1px;
  border-left: 1px;
  border-right: 1px;
  font-weight: 600;
}

.service-col .card {
  margin-left: 4px;
  margin-right: 4px;
  height: 720px;
}

.learn-more {
  color: #032c4f;
}

.service-content-five .see-btn {
  background-color: #2f7ba4;
  border-radius: 30px;
  width: 90%;
  padding: 6px 12px;
  border: 1px;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
}

.service-content-six .schedule-btn {
  background-color: #fff;
  border-radius: 30px;
  width: 90%;
  padding: 6px 12px;
  border: 1px solid #2f7ba4;
  color: #2f7ba4;
  font-size: 13px;
  font-weight: 600;
}

.service-content-two span {
  font-size: 28px;
  margin-right: 10px;
}

.service-content-three .use {
  font-weight: 500;
}

.service-content-three p {
  margin-bottom: 4px;
}

.service-content-one p {
  min-height: 115px;
  overflow: hidden;
  padding: 0px 10px;
  font-size: 14px;
  line-height: 22px;
}

.service-content-two {
  background-color: #f7f7f7;
  min-height: 120px;
  overflow: hidden;
}

.service-content-two p {
  margin-bottom: 0px;
  font-size: 14px;
}

.service-content-two span.less {
  font-size: 16px;
  margin-right: 0px;
}

.choose-service .choose-text {
  font-size: 20px;
  text-align: center;
  font-weight: 500;
}

.visit-col .visit-btns {
  color: #272b41;
  background-color: #fff;
  width: 80%;
  margin-bottom: 15px;
  border: 1px solid #dcdcdc;
  border-radius: 30px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 8px 14px;
  outline: unset;
  cursor: pointer;
}

.visits input.form-check-input {
  margin-left: -40px;
}

.visits span.visit-rsn {
  text-align: center;
  display: block;
}

.visit-col .back-left {
  color: #2f7ba4;
}

.visit-questions p {
  text-align: center;
}

.visit-questions p.ques {
  font-size: 20px;
  font-weight: 600;
}

.progress-text {
  color: #02c04d;
  text-align: center;
  font-size: 12px;
  display: block;
  margin-bottom: 5px;
}

.visit-progress .col-md-2:first-child {
  padding-left: 15px;
}

.visit-progress .col-md-2 {
  padding-left: 0px;
  padding-right: 10px;
  flex: 0 0 14%;
  max-width: 14%;
}

.ques-icon i.fa.fa-question:before {
  border: 4px solid;
  border-radius: 50%;
  padding: 16px 24px;
}

.ques-icon i.fa.fa-question {
  font-size: 55px;
  margin: 0 auto;
  display: block;
  text-align: center;
  color: #bdbdbd;
}

.upload-btns {
  background-color: #15558d;
  border-radius: 30px;
  width: 60%;
  padding: 10px 12px;
  border: 1px;
  color: #fff;
  margin: 0 auto;
  display: block;
}

.photo {
  text-align: center;
}

.uploads p {
  text-align: center;
  padding: 0 50px;
}

.photo p.photo-help {
  font-size: 20px;
  font-weight: 500;
}

.bck-btn {
  background-color: transparent;
  border-radius: 50px;
  color: #2f7ba4;
  cursor: pointer;
  display: block;
  font-size: 13px;
  font-weight: 600;
  margin: 0 auto;
  padding: 10px 15px;
  position: relative;
  transition: 0.3s;
  text-align: center;
  width: 220px;
  border: 2px solid #2f7ba4;
}

.continue-btn {
  background-color: #2f7ba4;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 13px;
  font-weight: 600;
  margin: 0 auto;
  padding: 10px 15px;
  position: relative;
  transition: 0.3s;
  text-align: center;
  width: 220px;
  border: 2px solid;
}

.continue-btn:hover {
  color: #fff;
}

.health-questions p {
  text-align: center;
}

.health-questions p.ques {
  font-size: 20px;
  font-weight: 600;
}

.medications p {
  font-weight: 600;
}

.add-medical {
  color: #032c4f;
  cursor: pointer;
}

.next-btn {
  background-color: #032c4f;
  float: right;
  border: 1px solid #032c4f;
  color: #fff;
  padding: 3px 24px;
}

.doc-questions .ques {
  text-align: center;
  font-size: 22px;
  font-weight: 700;
}

.intake-box {
  border: 1px solid;
  text-align: center;
  padding: 4px 0px;
  margin-left: 6px;
}

.my-doc {
  margin-left: 60px;
}

.type-visit .visits {
  text-align: center;
}

.typess p {
  font-size: 18px;
  margin-bottom: 4px;
  font-weight: 500;
}

.visit-like {
  font-size: 20px;
  font-weight: 600;
}

.type-visit .visits i.fa,
.type-visit .visits i.fas {
  font-size: 30px;
  border: 1px solid;
  padding: 12px;
  border-radius: 40px;
}

.pay-ctn-btn {
  background-color: #032c4f;
  color: #fff;
  border: 1px solid;
  padding: 5px 24px;
}

.confirm-visit {
  text-align: center;
}

.google-cal,
.outlook-cal {
  background-color: #2f7ba4;
  border: 1px solid #2f7ba4;
  border-radius: 4px;
  padding: 4px 12px;
  color: #fff;
}

.yahoo-cal {
  background-color: transparent;
  border: 1px solid #2f7ba4;
  border-radius: 4px;
  padding: 4px 12px;
  color: #2f7ba4;
}

.video-learn,
.need-tel {
  color: #2f7ba4;
}

.appoint-start-time {
  font-size: 18px;
}

.visit-input-wrapper .input-icon {
  position: absolute;
  left: 3px;
  top: calc(50% - 0.5em);
  color: #2f7ba4;
}

.visit-input-wrapper input {
  padding-left: 30px;
  border-bottom: 2px solid #2f7ba4 !important;
  border: unset;
  width: 40%;
  outline: unset;
}

.visit-input-wrapper {
  position: relative;
  left: 30%;
}

.view-my-doc-profile .profile-doc-btn {
  border: 2px solid #2f7ba4;
  background: transparent;
  color: #2f7ba4;
  padding: 4px 18px;
  border-radius: 6px;
  float: right;
}

.doc-name i.fas.fa-check-circle.doc-check {
  color: #2f7ba4;
}

.my-acc .acc-detail {
  font-size: 20px;
  font-weight: 600;
}

.go-visit {
  text-align: center;
  color: #032c4f;
}

i.fas.fa-video.appoint-video {
  font-size: 26px;
  text-align: center;
  display: block;
}

.any-intake-box {
  border: 1px solid;
  text-align: center;
  padding: 4px 4px;
  margin-left: 6px;
  width: 10%;
}

span.call-duration.float-right {
  display: inline-block;
  font-size: 22px;
  margin-top: 0px;
  position: absolute;
  right: 0;
  left: unset;
  color: #40dda4;
}

.toggle-voice-video .switch {
  position: relative;
  display: inline-block;
  width: 90px;
  height: 34px;
}

.toggle-voice-video .switch input {
  display: none;
}

.toggle-voice-video .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2f7ba4;
  transition: 0.4s;
  border-radius: 34px;
}

.toggle-voice-video .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.toggle-voice-video input:checked + .slider {
  background-color: #2ab934;
}

.toggle-voice-video input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.toggle-voice-video input:checked + .slider:before {
  transform: translateX(55px);
}

/*------ ADDED CSS ---------*/
.toggle-voice-video .slider:after {
  content: "VOICE";
  color: white;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 57%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

.toggle-voice-video input:checked + .slider:after {
  content: "VIDEO";
}

.toggle-voice-video {
  display: inline-block;
  font-size: 30px;
  margin-top: 4px;
  position: absolute;
  left: 0;
}

.health-profile-share {
  text-align: center;
  display: block;
  margin: 19px auto 35px auto;
  background-color: #5be3fe54;
  padding: 18px 100px 2px 20px;
}

.health-profile-share a {
  color: #15558d;
}

.health-profile-share i.fa.fa-check.share-health-prof {
  background-color: #15558d;
  padding: 2px;
  color: #fff;
  border: 1px solid #15558d;
}

.find-doc .clinic-services span {
  border: 1px solid #f7f7f7;
  border-radius: 4px;
  display: inline-block;
  font-size: 12px;
  padding: 6px 10px;
  background: #f7f7f7;
}

.css-1pahdxg-control:hover {
  border-color: inherit !important;
}

.css-yk16xz-control,
.css-1pahdxg-control {
  width: 96px;
  border-width: 0px !important;
  box-shadow: inherit;
}

.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

.dashboard-menu .nav-item {
  display: block;
  width: 100%;
  padding: 16px 20px;
  position: relative;
  border-bottom: 1px solid #f0f0f0 !important;
}

.dashboard-menu .nav-item i {
  font-size: 16px;
  margin-right: 10px;
  width: 16px;
}

.dashboard-menu .nav-item a.active span {
  color: #2f7ba4;
}

.appointment-tab .nav-tabs a.active {
  text-align: center;
  background: #2f7ba4;
  color: #ffffff;
  border-bottom: 3px solid #2f7ba4;
  padding: 0px;
  border-radius: 30px;
  /* padding: 5px; */
  width: 130px;
  height: 45px;
  line-height: 45px;
  font-weight: normal;
}

.table-action .btn-sm {
  margin-right: 4px;
}

.circle-bar > div canvas {
  width: 90px !important;
  height: 90px !important;
}

.RCP svg {
  width: 100px;
  position: relative;
  height: 100px;
}

.RCP {
  margin-right: 5px;
  width: 105px !important;
}

.indicator-volume {
  position: absolute;
  top: 30px;
  margin-top: 0px;
  left: 35px;
  width: 28px;
}

.user-tabs .nav-tabs > a {
  border: 0;
  border-bottom: 3px solid transparent;
  color: #3e3e3e;
  font-weight: 600;
  padding: 20px;
  width: 25%;
  text-align: center;
}

.user-tabs .nav-tabs > a.active {
  border-bottom-width: 3px;
  color: #2f7ba4;
  border-bottom: 3px solid #2f7ba4;
  background-color: #fff;
}

.ReactTags__tags span {
  color: #fff;
  opacity: 1;
  cursor: move;
  background: #2f7ba4;
  padding: 6px;
  margin: 5px;
}

.ReactTags__remove {
  border: none;
  background: #2f7ba4;
}

.ReactTags__tagInput {
  border: 1px solid #bfbfbf;
  margin-top: 10px;
}

.ReactTags__tagInput {
  border: 1px solid #bfbfbf;
  margin-top: 10px;
}

.ReactTags__tagInputField {
  width: 100%;
  border: none;
  height: 40px;
}

.ReactTags__tagInputField:focus {
  outline: none;
}

.datepicker-icon {
  max-width: 253px;
  float: right;
  position: relative;
}

.datepicker-icon i {
  position: absolute;
  left: 6px;
  z-index: 1;
  top: 14px;
  width: 20px;
}

.datepicker-icon input {
  max-width: 253px;
  position: relative;
  padding-left: 40px;
  padding-right: 26px;
}

.datepicker-icon i.fas {
  right: 6px !important;
  left: auto;
}

.terms-accept label {
  margin-left: 7px;
}

.fc .fc-toolbar {
  display: flex;
}

.fc-button.fc-prev-button,
.fc-button.fc-prev-button,
.fc-next-button.fc-button {
  background: #f1f1f1;
  border: none;
  color: #797979;
}

.fc-button {
  background-color: #d6d6d6 !important;
  border: #f1f1f1 !important;
  color: #545454 !important;
}

.fc-toolbar h2 {
  font-size: 18px !important;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  line-height: 30px;
  text-transform: uppercase;
}

.fc-media-screen .fc-button-active {
  color: #fff !important;
  text-transform: capitalize;
  background-color: #032c4f !important;
}

.css-1hb7zxy-IndicatorsContainer {
  display: none;
}

/* .slick-next,
.slick-prev {
  top: 13px;
}

.slick-prev {
  top: 7px;
  transform: rotate(180deg);
} */

.user-drop .btn-primary {
  background-color: inherit;
  border: inherit;
}

.schedule-header .schedule-nav .nav-tabs .nav-item.active {
  color: #fff;
  background-color: #2f7ba4;
  border: 1px solid #2f7ba4;
}

.schedule-header .schedule-nav .nav-tabs .nav-link {
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  padding: 6px 15px;
  text-transform: uppercase;
  font-size: 14px;
  margin: 0px 5px;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.input-container input {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: 0.75rem;
  position: relative;
  width: 100%;
}

.date-picker-input {
  display: inline-block;
  position: relative;
}

.date-picker-input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.modal-body {
  overflow: auto;
}

/* ------------------------------------------------------------------ New CSS ------------------------------------------------------------------ */
.main-search-section {
  /* background-color: #f0f6f9; */
}

.booking-appointment-box .inner-sub-input-box .form-control {
  margin-right: 30px;
}

.main-body {
  background-color: #2f7ba412;
}

.main-body .card {
  background-color: transparent;
}

.main-body .card.appoin-date-section {
  background-color: #fff;
  border-radius: 10px;
  margin: 0 0 20px 0;
}

html.menu-opened {
  position: relative;
  overflow: hidden;
}

html.menu-opened:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: #000;
  z-index: 1;
  opacity: 0.4;
}

.main-body .card {
  border: none;
  margin: 0;
}

.main-body .card.appoin-date-section .schedule-cont {
  padding: 20px 0px;
}

.appoi-detail-left {
  height: 100%;
  background-color: #2f7ba412;
  padding: 80px 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  min-height: 100vh;
}

.appoi-detail-inner-left {
  padding: 0px 37px 0px 25px;
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
  left: 0;
}

.appoi-detail-inner-left .booking-doc-info {
  display: block;
}

.appoi-detail-inner-left .booking-doc-info .booking-doc-img {
  display: block;
  border-radius: 50%;
  overflow: hidden;
  width: 130px;
  height: 130px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: 5px solid #2f7ba4;
  margin: auto auto 15px auto;
}

.appoi-detail-inner-left .booking-doc-info .booking-doc-img img {
  width: auto;
  height: auto;
  max-width: 100%;
}

.appoi-detail-inner-left .booking-doc-info .booking-info > h4 {
  font-size: 28px;
  margin-bottom: 5px;
}

.booking-doc-info .time-content h5 {
  font-size: 20px;
  color: #2e2e2e;
  font-weight: 500;
}

.booking-doc-info .time-content p {
  font-size: 16px;
}

.booking-info .rating {
  border: none;
}

.booking-info .rating i {
  padding-right: 5px;
}

.appoi-detail-right {
  max-width: 1050px;
  padding: 5px 15px 30px 15px;
  margin: 0 auto;
}

.appoi-detail-inner-right {
  background-color: #2f7ba412;
  padding: 25px;
  margin-top: 25px;
  border-radius: 10px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}

.appoi-detail-inner-right h3 {
  font-size: 22px;
  margin-bottom: 15px;
}

.appoi-detail-inner-right .appoi-detail-buttons {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 5px;
}

.appoi-detail-inner-right .appoi-detail-buttons button {
  margin: 4px;
  font-size: 14px;
  padding: 6px 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #dbdbdb !important;
  transition: all 0.6s ease;
}

.appoi-detail-inner-right .appoi-detail-buttons button:hover,
.appoi-detail-inner-right .appoi-detail-buttons button.activeBtn {
  background-color: #2f7ba4;
  color: #fff;
  border-color: #2f7ba4 !important;
}

.appoi-detail-inner-right .form-group label.form-label {
  margin: 0;
  background-color: transparent;
  font-size: 16px;
  color: #000;
  font-weight: 300;
  padding: 0;
  margin-bottom: 5px;
}

.appoi-detail-inner-right .form-group input {
  margin-top: 0;
  width: 100%;
  display: block;
  padding: 10px 15px;
  border-radius: 7px;
}

.appoi-detail-inner-right .form-group input:valid {
  background-color: #fff !important;
  border: 1px solid #dbdbdb;
}

.appoi-detail-inner-right .form-group input:invalid:focus {
  box-shadow: none;
}

.appoi-detail-inner-right .info-widget {
  padding: 0;
}

.appoi-detail-inner-right .info-widget:last-child {
  margin: 0;
}

.appoi-detail-inner-right .form-check-input {
  margin-top: 1px;
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.appoi-detail-inner-right .form-check-input:focus {
  box-shadow: none;
}

.appoi-detail-inner-right label.form-check-label {
  position: relative;
  padding-left: 10px;
  cursor: pointer;
}

.appoi-detail-inner-right label.form-check-label::before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #0079bf;
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%),
    inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
  padding: 10px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  left: -25px;
  position: absolute;
}

.appoi-detail-inner-right
  .form-check-input:checked
  + label.form-check-label:after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: -17px;
  width: 6px;
  height: 14px;
  border: solid #2f7ba4;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.appoi-booking-modal .modal-dialog {
  max-width: 850px;
  padding: 0 15px;
  margin: auto;
  display: flex;
  align-items: center;
  height: 100%;
}

.appoi-booking-modal .modal-dialog .modal-body {
  height: 500px;
}

.appoi-booking-modal .modal-dialog .modal-body::-webkit-scrollbar-track {
  border-radius: 10px;
}

.appoi-booking-modal .modal-dialog .modal-body::-webkit-scrollbar {
  width: 5px;
}

.appoi-booking-modal .modal-dialog .modal-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #2f7ba4;
}

.appoi-booking-modal .modal-dialog .modal-body .form-group .modal-input {
  min-height: 44px;
  margin-top: 0;
  padding: 10px 15px;
  height: 100%;
}

.appoi-booking-modal .modal-dialog .modal-body .form-group .modal-input:focus {
  box-shadow: none;
}

.appoi-booking-modal .modal-dialog .modal-body h5.modal-header {
  padding: 0;
  border: none;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 18px;
  color: #272b41;
}

.appoi-booking-modal .modal-dialog .modal-body p.appoi-not-p {
  color: #757575;
  font-size: 14px;
  margin-bottom: 0;
}

.appoi-booking-modal .modal-dialog .modal-footer .ct-apt-btn {
  color: #fff;
  padding: 10px;
}

.appoi-booking-modal .modal-dialog .modal-footer .ct-apt-btn:hover {
  color: #000 !important;
}

.appoi-booking-modal .modal-dialog .modal-content > div {
  border: none;
}

.appoi-detail-inner-right form {
  padding: 5px;
}

.appoi-detail-right h5.modal-header {
  font-size: 18px;
  margin-bottom: 8px;
  color: #272b41;
  font-weight: 600;
}

.appoi-detail-right .card.appoin-date-section {
  padding: 0;
  border: none;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border-radius: 8px;
}

.success-page-cont {
  padding: 20px 0 30px 0;
  min-height: auto;
  height: 100%;
}

.success-page-cont .success-card {
  padding: 0;
  border: none;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border-radius: 8px;
  width: 100%;
  max-width: 510px;
  margin: 0 auto;
}

.success-page-cont .success-card .card-body {
  padding: 45px 38px;
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(47, 123, 164, 0.11);
}

.success-page-cont .success-card .success-img {
  margin-bottom: 38px;
}

.reverse-spinner {
  position: relative;
  height: 80px;
  width: 80px;
  border: 4px solid transparent;
  border-top-color: #2f7ba47a;
  border-left-color: #2f7ba47a;
  border-radius: 50%;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

.reverse-spinner::before {
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  content: "";
  border: 4px solid transparent;
  border-top-color: #2f7ba4;
  border-left-color: #2f7ba4;
  border-radius: 50%;
  -webkit-animation: spinBack 1s linear infinite;
  animation: spinBack 1s linear infinite;
}

@-webkit-keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spinBack {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-720deg);
  }
}

@keyframes spinBack {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-720deg);
  }
}

.main-loader {
  height: calc(100vh - 221px);
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: center;
}

.no-session {
  padding: 10px 10px 10px 10px;
  color: lightgrey;
}

.appoi-detail-right button.ct-apt-btn {
  margin-top: 25px;
  max-width: 180px;
  margin-left: auto;
}

.appoi-detail-right button[disabled].ct-apt-btn {
  margin-top: 25px;
  opacity: 0.6;
  cursor: not-allowed;
  background-color: #ccc;
  border-color: #ccc;
  color: #000;
}

.appoi-detail-right button[disabled].ct-apt-btn:hover {
  background-color: #ccc;
  border-color: #ccc;
  color: #000;
}

.appoi-booking-modal .modal-dialog .modal-content {
  min-width: auto;
}

.appoi-nav-main {
  padding: 15px;
}

.back-button-container {
  max-width: 1320px;
  margin: 0 auto;
}

.appoi-nav-main .appoi-nav-inner-main {
  border-bottom: 1px solid rgb(230, 233, 233);
}

.appoi-nav-main .appoi-nav-inner-main .back-button {
  color: #2f7ba4;
  font-size: 18px;
}

.appoi-nav-main .appoi-nav-inner-main .back-button i {
  margin-right: 10px;
}

.appoi-detail-right .card.appoi-not-ava {
  padding: 20px;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.appoi-detail-right p.appoi-not-p {
  color: #757575;
  font-size: 14px;
  margin-bottom: 0;
}

.success-page-cont .success-cont img.img-fluid {
  border-radius: 50%;
  max-width: 110px;
  width: 100%;
  margin-bottom: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.success-page-cont .success-cont strong {
  display: block;
}

.success-page-cont .success-cont p span {
  font-weight: 700;
  color: #2f7ba4;
  text-decoration: underline;
}

.success-page-cont .success-cont p {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 600;
}
.confirm-app-modal-list-ul {
  max-width: 300px;
  margin: 0 auto 19px !important;
}
.success-page-cont .success-cont h2 {
  margin-bottom: 0px;
}

.success-page-cont .success-cont h4 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: #000;
}
.close-btn {
  min-width: 200px;
}

.success-page-cont .success-cont h3 {
  font-size: 19px;
}

.success-page-cont .success-cont .ct-apt-btn {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.success-page-cont .success-cont h1 {
  margin-bottom: 29px;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #2f7ba4;
}

.success-page-cont .success-cont .details {
  padding: 14px 0;
}

.success-page-cont .success-cont h5 {
  font-size: 20px;
  margin: 5px 0 20px 0;
}

.success-page-cont .success-cont h4 span {
  color: #272b41;
}

.success-page-cont .doctor-detail {
  display: flex;
  align-items: center;
  padding-bottom: 40px;
  border-bottom: 1px solid #2f7ba4;
}

.success-page-cont .doctor-detail .doctor-detail-left {
  margin: 0;
  margin-right: 25px;
  border: 3px solid #2f7ba4;
  border-radius: 50%;
  overflow: hidden;
  width: 114px;
  height: 114px;
}

.success-page-cont .doctor-detail .doctor-detail-left img.img-fluid {
  margin: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.success-page-cont .doctor-detail .doctor-detail-right p {
  display: flex;
  justify-content: center;
}

.success-page-cont .doctor-detail .doctor-detail-right p span:first-child {
  margin-right: 17px;
}

.success-page-cont .doctor-detail .doctor-detail-right p span {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  text-decoration: none;
}

.success-page-cont .doctor-detail .doctor-detail-right h2 {
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  color: #47466c;
  margin-bottom: 6px;
}

.success-page-cont .doctor-detail .doctor-detail-right {
  text-align: left;
}

.success-cont .main-details-block {
  padding: 15px 0 0;
}

.success-cont .main-details-block .details {
  padding: 0 0 10px 0;
}
.main-details-block-image {
  width: 190px;
  height: auto;
  margin-bottom: 15px;
}

.success-cont .main-details-block .details h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: #000000;
  margin-left: 17px;
}

.success-page-cont .success-cont .ct-apt-btn {
  box-shadow: rgba(47, 123, 164, 0.69) 0px 0px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -24px;
  border: none !important;
  padding: 14px 22px;
}

.success-page-cont .success-cont .ct-apt-btn:hover {
  background-color: #2f7ba4;
  color: #fff;
}

.appoi-booking-modal
  .modal-dialog
  .modal-body
  .card.appoi-not-ava.appoin-date-section {
  padding: 20px;
}

.appoi-booking-modal
  .modal-dialog
  .modal-body
  .card.booking-schedule.schedule-widget.appoin-date-section {
  padding: 0;
}

.booking-page-inner-section {
  margin-bottom: -90px;
}

.booking-left-inner-content h1 {
  font-weight: 600;
  font-size: 52px;
  line-height: 78px;
  margin-bottom: 50px;
  color: #000;
}

.booking-left-inner-content p {
  font-weight: 400;
  font-size: 21px;
  line-height: 32px;
  color: #7f8384;
  margin-bottom: 40px;
}

.booking-page-section .booking-appointment-box {
  position: relative;
  z-index: 1;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(47, 123, 164, 0.26);
  border-radius: 8px;
  width: 100%;
  max-width: 1030px;
  padding: 11px 26px 27px 30px;
  /* bottom: 10px; */
}

.booking-appointment-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

/*
.booking-appointment-right-items .owl-item {
  width: auto !important;
}

.booking-appointment-right-items {
  position: relative;
}

.slick-active{
  width:auto !important;
}

.booking-appointment-right-items .owl-nav button span {
  font-size: 30px;
  line-height: 20px;
}

.booking-appointment-right-items .owl-theme .owl-nav [class*="owl-"]:hover {
  background: transparent;
  color: #7f8384;
}

.booking-appointment-right-items .owl-nav button {
  position: absolute;
  top: 0px;
  margin: 0px !important;
}

.booking-appointment-right-items .owl-nav .owl-prev {
  left: -10px;
  background-color: #fff !important;
}

.booking-appointment-right-items .owl-nav .owl-prev img {
  transform: scaleX(-1);
}

.booking-appointment-right-items .owl-nav .owl-next {
  right: -30px;
  background-color: #fff !important;
}

.booking-appointment-right-items .owl-nav .owl-prev.disabled,
.booking-appointment-right-items .owl-nav .owl-prev.disabled {
  display: none;
}

.booking-appointment-right-items .appointment-items {
  display: flex;
  align-items: center;
  margin-top: 5px;
} */

.booking-appointment-inner-body {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.booking-appointment-inner-body .booking-appointment-body-item {
  width: 50%;
  position: relative;
}

/* .book-form-input .css-1fdsijx-ValueContainer {
  padding: 11px 20px 11px 10px !important;
  min-height: 46px;
  font-size: 15px;
  border-radius: 7px;
  height: 50px;
} */

.book-form-input .css-hlgwow {
  padding: 11px 20px 11px 10px !important;
  min-height: 46px;
  font-size: 15px;
  border-radius: 7px;
  height: 50px;
}

.book-form-input .css-13cymwt-control {
  min-height: 46px;
  font-size: 15px;
  border-radius: 7px;
}

.book-form-input .css-qbdosj-Input input {
  background-color: transparent !important;
}

.appoi-detail-inner-right .form-group input:valid {
  background-color: transparent !important;
}

.filled .css-13cymwt-control div:first-child,
.booking-appointment-box .css-t3ipsp-control div:first-child {
  background-color: #f0f6f9 !important;
}

.css-13cymwt-control div:first-child:focus,
.css-13cymwt-control:focus,
.booking-appointment-box .css-t3ipsp-control div:first-child:focus,
.css-13cymwt-control div:first-child:focus {
  background-color: #f0f6f9 !important;
}

.filled .css-13cymwt-control {
  background-color: #f0f6f9 !important;
}

.filled:focus .css-13cymwt-control div:first-child,
.booking-appointment-box .css-t3ipsp-control div:first-child {
  background: #f0f6f9 !important;
}

.ct-app-modal .modal-dialog {
  display: flex;
  height: 100vh;
  margin: auto;
  padding: 15px;
  align-items: center;
  max-width: 540px;
}

.ct-app-modal .modal-dialog .modal-content {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.ct-app-modal .modal-dialog .modal-content .modal-header {
  border-bottom: none;
}

.ct-app-modal .modal-dialog .modal-content .modal-footer {
  border-top: 0;
}

.ct-app-modal .modal-dialog .modal-content .modal-footer .ct-apt-btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 100px;
}

.ct-app-modal .modal-dialog .modal-content .modal-footer .ct-apt-btn:hover {
  color: #2f7ba4 !important;
}

.ct-app-modal .modal-dialog .modal-content .modal-body p {
  color: #757575;
  font-size: 14px;
  margin-bottom: 0;
}

.ct-app-modal .modal-dialog .modal-content .modal-body p span {
  font-weight: 600;
}

.ct-app-modal .modal-dialog .modal-content .modal-body i {
  width: auto;
  height: auto;
  font-size: 15px;
  margin-bottom: 0px;
  margin-right: 10px;
  background: transparent;
  border-radius: 25px;
  text-align: center;
  line-height: 26.5px;
  color: #2f7ba4;
}

.hilight-content {
  border-radius: 8px;
  background-color: #fff3cd;
  padding: 14px;
  margin: 15px 0 35px 0;
}

.hilight-content p {
  color: #856404 !important;
}

.ct-popup-details {
  background-color: #2f7ba412;
  border-radius: 8px;
  padding: 15px;
}

.ct-popup-details .ct-popup-inner-details {
  max-width: 250px;
  margin: 0 auto;
}

.search-inner-banner {
  background-image: url(../../../../public/assets/images/search-banner-img.png);
  max-height: 425px;
  padding-top: 20px;
  position: relative;
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
}

.search-inner-banner:before {
  content: "";
  background-color: #2f7ba4;
  opacity: 0.95;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.search-inner-banner .container {
  position: relative;
  color: #fff;
}

.search-inner-banner .search-header h2 {
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #ffffff;
  margin-bottom: 15px;
}

.search-inner-banner .search-header p {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  margin-bottom: 0;
}

.footer-stick .btn-primary {
  border: none;
  background-color: transparent;
  padding: 0;
}

.footer-stick .btn-primary:active:not(:disabled):not(.disabled) {
  background-color: none;
  border: none;
}

.footer-padding {
  padding: 50px 0;
}

/* Responsive */
@media (max-height: 870px) {
  /* .success-page-cont .success-card .success-img {
    margin-bottom: 10px;
  }

  .success-page-cont .success-card .card-body {
    padding: 20px;
  } */

  /* .success-page-cont .success-card .success-img img {
    max-width: 100px;
  } */

  /* .success-page-cont .success-card {
    max-width: 480px;
  }

  .success-page-cont .doctor-detail {
    padding-bottom: 10px;
  } */

  /* .success-page-cont .success-cont h1 {
    margin-bottom: 10px;
  } */
}

@media (max-height: 730px) {
  /* .success-page-cont .success-cont h2 {
    margin-bottom: 0px;
  }

  .success-page-cont .success-cont .details {
    padding: 0;
  }

  .success-cont .main-details-block {
    padding: 10px 0 0;
  }

  .success-page-cont .doctor-detail {
    justify-content: flex-start;
  }

  .success-page-cont .doctor-detail .doctor-detail-left {
    width: 80px;
    height: 80px;
  } */

  /* .success-page-cont .doctor-detail .doctor-detail-right h2 {
    font-size: 24px;
    line-height: normal;
  } */
}

@media (max-height: 670px) {
  /* .success-page-cont .success-card .details h4 {
    margin: 0;
    margin-left: 10px;
  } */

  /* .success-page-cont .success-cont h1 {
    font-size: 20px;
  } */

  /* .success-page-cont .success-card .success-img img {
    max-width: 80px;
  } */

  /* .success-cont .main-details-block {
    padding-bottom: 10px;
  } */
  .ct-app-modal .modal-dialog .modal-content .modal-body {
    padding: 0 16px;
  }

  .hilight-content {
    margin: 10px 0;
  }
}

@media (max-height: 650px) {
  /* .success-page-cont .success-card .card-body {
    padding: 20px;
  } */

  .appoi-booking-modal .modal-dialog .modal-body {
    height: 350px;
  }
}

@media (max-height: 610px) {
  /* .success-page-cont .doctor-detail .doctor-detail-left {
    width: 60px;
    height: 60px;
  } */
}

@media (max-width: 1080px) {
  .doc-info-right {
    flex: 0 0 500px;
    max-width: 500px;
  }

  .success-page-cont .doctor-detail .doctor-detail-left {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 991px) {
  .doc-info-right {
    flex: 0 0 350px;
    max-width: 350px;
  }

  .doc-info-left {
    flex-wrap: wrap;
    margin-top: 0;
  }

  .doctor-img {
    flex: 0 0 auto;
    margin-right: 0;
    width: 100%;
    margin-bottom: 20px;
  }

  .main-body .card.appoin-date-section {
    overflow-x: auto;
  }

  .main-body .card.appoin-date-section .schedule-cont {
    width: 500px;
  }

  .doctor-img img {
    width: 100%;
  }

  .doctor-widget > div {
    flex: auto;
    max-width: 100%;
    width: 50%;
  }

  .footer-widget {
    margin-bottom: 0;
  }

  .appoi-detail-inner-left {
    padding: 0 15px;
  }

  .main-loader {
    height: calc(100vh - 231px);
  }

  .sm-width-25 .css-13cymwt-control {
    padding: 6px 0px;
  }
}

@media (max-width: 767px) {
  .doctor-widget {
    text-align: left;
  }

  .doc-info-right {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .main-body .card.appoin-date-section {
    margin: 0;
    margin-bottom: 20px;
  }

  .doc-info-left {
    margin-right: 0;
  }

  .time-slot ul {
    text-align: left;
  }

  .doctor-widget > div {
    width: 100%;
  }

  .main-body .card.appoin-date-section .schedule-cont {
    width: 100%;
  }

  .main-body > .card > .card-body {
    padding: 15px;
  }

  .appoi-detail-left {
    min-height: auto;
    padding: 15px 0;
    margin: 20px 0;
  }

  .appoi-detail-right {
    padding: 15px 0;
    margin: 40px auto;
  }

  .appoi-detail-inner-right {
    padding: 15px;
    margin: 20px 0px;
  }

  .appoi-detail-inner-left .booking-doc-info .booking-doc-img {
    width: 100px;
    height: 100px;
  }

  .breadcrumb-bar {
    min-height: 340px;
  }

  .breadcrumb-bar .breadcrumb-title {
    font-size: 28px;
  }

  .appoi-detail-inner-left {
    position: relative;
    top: 0;
  }

  .breadcrumb-bar:after {
    -webkit-filter: blur(6px);
    filter: blur(6px);
  }

  .main-loader {
    height: calc(100vh - 292px);
  }

  .appoi-booking-modal .modal-dialog .modal-body h5.modal-header {
    margin-top: 0px;
    font-size: 16px;
  }

  .appoi-booking-modal .modal-dialog .modal-body .card.appoin-date-section {
    padding: 0;
    overflow: auto;
    margin: 15px 0;
  }

  .time-slot li .timing.selected::before {
    left: 6px;
  }

  .appoi-booking-modal .modal-dialog .modal-body .card.appoi-not-ava {
    padding: 20px;
  }

  .success-cont .main-details-block {
    padding: 15px 0px;
  }

  .success-cont .main-details-block .details h4 {
    margin-left: 5px;
  }

  .success-page-cont .doctor-detail {
    justify-content: flex-start;
    padding-bottom: 15px;
  }

  .success-page-cont .doctor-detail .doctor-detail-left {
    width: 80px;
    height: 80px;
  }

  .sm-width-25 .css-13cymwt-control {
    padding: 6px 10px;
  }
}

@media (max-width: 576px) {
  .form-group .col-sm-3.sm-width-25 {
    width: 25%;
  }

  .form-group .col-sm-5.sm-width-25 {
    width: 41.66666667%;
  }

  .form-group .col-sm-4.sm-width-25 {
    width: 33.33333333%;
  }
}

@media (max-width: 570px) {
  .main-loader {
    height: calc(100vh - 277px);
  }

  .appoi-booking-modal
    .modal-dialog
    .modal-body
    .card.appoin-date-section
    .schedule-cont {
    width: 560px;
    padding: 15px;
  }

  .appoi-booking-modal
    .modal-dialog
    .modal-body
    .card.appoin-date-section
    .schedule-cont
    li
    .timing {
    font-size: 12px;
  }

  .download-app-appoinment {
    font-size: 30px;
  }

  .appoi-booking-modal
    .modal-dialog
    .modal-body
    .card.appoi-not-ava.appoin-date-section {
    padding: 15px;
  }
}

@media (max-width: 500px) {
  /* .main-body .card.appoin-date-section .schedule-cont {
    width: 500px;
  } */

  .footer-title {
    margin-bottom: 0;
    margin: 10px 0;
    font-size: 16px;
  }

  .appoi-detail-inner-left .booking-doc-info .booking-info > h4 {
    font-size: 22px;
  }

  .appoi-detail-inner-right h3 {
    font-size: 18px;
  }

  .appoi-detail-inner-right .appoi-detail-buttons button {
    margin: 4px;
  }

  .appoi-detail-inner-right .form-group input {
    height: 100%;
  }

  .booking-doc-info .time-content p {
    font-size: 14px;
  }

  .appoi-detail-right h5.modal-header {
    font-size: 18px;
  }

  .appoi-detail-right .card.appoin-date-section {
    overflow-x: auto;
  }

  .appoi-detail-right .card.appoin-date-section .schedule-cont {
    width: 500px;
    padding: 10px;
  }

  .success-page-cont .success-card .success-cont i {
    margin-bottom: 10px;
  }

  .success-page-cont .success-card .success-cont p {
    margin-bottom: 10px;
    text-align: center;
  }

  .success-page-cont .success-card .success-cont h3 {
    font-size: 18px;
    text-align: center;
  }

  .main-details-block-container {
    max-width: 280px !important;
    margin: 0 auto 10px !important;
  }

  .main-loader {
    height: calc(100vh - 255px);
  }

  .appoi-detail-right h5.modal-header {
    font-size: 16px;
  }

  .success-page-cont .success-cont h1 {
    font-size: 20px;
  }

  .success-page-cont .success-cont .details {
    padding: 10px 0;
  }

  .success-page-cont .success-cont h4 {
    font-size: 15px;
  }

  .success-page-cont .success-cont .main-details-block .details h4 {
    margin-left: 5px;
    font-size: 14px;
    line-height: normal;
  }

  .success-page-cont .success-cont h5 {
    font-size: 18px;
  }

  .main-details-block {
    max-width: 100%;
  }

  .success-page-cont .success-card .success-img img {
    max-width: 120px;
  }

  .success-page-cont .doctor-detail .doctor-detail-right h2 {
    font-size: 26px;
    margin-bottom: 0;
    text-align: center;
  }

  .success-page-cont .doctor-detail .doctor-detail-right p span {
    font-size: 14px;
  }

  .success-page-cont .doctor-detail .doctor-detail-right p span:first-child {
    margin-right: 5px;
  }

  .success-page-cont .doctor-detail .doctor-detail-left {
    margin-right: 5px;
    margin: auto;
  }

  .success-page-cont .doctor-detail {
    display: block;
  }

  .success-page-cont .success-card .success-img {
    margin-bottom: 20px;
  }
}

/* -----------css------ */
.appoi-detail-inner-section .appoi-detail-left .booking-doc-info {
  text-align: center;
}

.appoi-detail-inner-section .appoi-detail-left .booking-info .inner-box-book {
  max-width: 202px;
  text-align: left !important;
  margin: auto;
}

.appoi-detail-inner-left .ct-apt-btn {
  max-width: inherit !important;
  width: 100%;
}

.appoi-detail-inner-section .appoi-detail-left .booking-doc-info .text-muted i,
.appoi-detail-inner-section
  .appoi-detail-left
  .booking-doc-info
  .time-content
  i {
  width: auto;
  height: auto;
  font-size: 15px;
  margin-bottom: 0px;
  margin-right: 10px;
  background: transparent;
  border-radius: 25px;
  text-align: center;
  line-height: 26.5px;
  color: #2f7ba4;
}

.appoi-detail-inner-left .booking-info h4 a {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 18px;
  display: block;
  text-align: center !important;
}

.appoi-detail-inner-left .booking-doc-info p {
  font-size: 14px;
  color: #757575;
  margin-bottom: 15px;
  line-height: 26px;
}

.booking-doc-info .time-content h5 {
  font-size: 15px;
  color: #2f7ba4;
  margin-bottom: 15px;
  line-height: 26px;
}

.success-page-cont .success-card .details i {
  width: 30px;
  height: 30px;
  font-size: 16px;
  margin-bottom: 0px;
  margin-right: 15px;
}

.success-page-cont .success-card .details {
  text-align: left !important;
  display: flex;
  align-items: center;
}
.main-details-block-container {
  max-width: 300px;
  margin: 0 auto 10px;
}
.btn:hover .spinner-border-sm {
  color: #2f7ba4 !important;
}
.btn-outline-danger:hover .spinner-border-sm {
  color: #fff !important;
}
.cn-loader-btn {
  min-width: 110px;
}

.rt-btn {
  min-width: 85px;
}

.rt-spacing{
  padding: 35px 20px;
}

/* .success-page-cont .success-card .details h4 {
  width: calc(100% - 30px);
} */

@media (max-height: 720px) {
  /* .success-page-cont .success-cont img.img-fluid {
    max-width: 90px;
  } */

  /* .success-page-cont .success-cont h1 {
    font-size: 20px;
    margin-bottom: 9px;
  } */

  .success-page-cont .success-cont h2 {
    font-size: 27px;
  }
}

@media (max-height: 670px) {
  /* .success-page-cont .success-cont img.img-fluid {
    max-width: 75px;
  } */
}

@media (max-width: 1100px) {
  .appoi-detail-inner-right .appoi-detail-buttons button {
    width: calc(50% - 8px);
  }
}

@media (max-width: 767px) {
  .success-page-cont .success-cont .details {
    padding: 9px 0;
  }

  .success-page-cont .success-card .card-body {
    padding: 17px;
  }

  .appoi-detail-inner-left .ct-apt-btn {
    max-width: inherit !important;
    width: 250px;
    margin: auto;
  }
}

@media (max-width: 575px) {
}

@media (max-width: 500px) {
  .success-page-cont .success-card .card-body {
    padding: 15px;
  }

  .success-page-cont .success-cont img.img-fluid {
    max-width: 70px;
  }

  .success-page-cont .success-cont h2 {
    font-size: 20px;
  }

  .success-page-cont .success-cont h1 {
    font-size: 22px;
    margin-bottom: 15px;
    line-height: normal;
  }

  .success-page-cont .success-cont .details {
    padding: 5px 0 !important;
  }

  .success-page-cont .ct-apt-btn {
    max-width: 125px;
    font-size: 14px;
    padding: 7px 14px;
  }

  .appoi-detail-inner-right .appoi-detail-buttons button {
    margin: 3px;
    font-size: 13px;
    padding: 6px 5px;
    width: calc(50% - 6px);
  }
}

.search-card-title {
  align-items: baseline;
  color: #343b48;
  display: flex;
  font-family: Lato-Bold, "Trebuchet MS", sans-serif;
  justify-content: space-between;
  line-height: 20px;
}

.search-card-title:after {
  color: #2f7ba4;
  content: "";
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 10px;
  -webkit-font-smoothing: antialiased;
  width: 26px;
  height: 26px;
  background: #edf9ff;
  border-radius: 4px;
  transition: all 0.2s;
  background-image: url("../../../../public/assets/images/doctors/right-artrow-c.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.search-result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 660px;
}

.open-time {
  color: #198751;
}

.open-time:before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin-right: 2px;
  margin-bottom: 0.1em;
  vertical-align: middle;
  background-color: #198751;
}

.selected-clinic-chip {
  border-bottom: 2px solid #2f7ba4;
  color: #2f7ba4;
  font-weight: 500;
}

.popular-clinic-chip {
  text-align: center;
}

.popular-clinic-chip:hover {
  border-bottom: 2px solid #2f7ba4;
  color: #2f7ba4;
  font-weight: 500;
  cursor: pointer;
}

.css-1pahdxg-control {
  width: 300px;
}

.css-26l3qy-menu {
  width: 300px;
}

/*
@media (max-width: 400px) and (max-height: 620px) {
  .success-page-cont .success-card .card-body {
    height: 290px;
    overflow-y: auto;
  }
} */

/* --------------------------------------------------
--------------------------------------------------------------------------- */
.booking-page-section .booking-left-content .booking-left-inner-content p {
  margin-bottom: 80px;
}

.booking-appointment-box .booking-appointment-header h4 {
  margin-bottom: 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  color: #000000;
}

/* .booking-appointment-box .booking-appointment-right-items .popular-clinic-chip {
  margin: 0px 2px;
  padding: 0px 0px 5px 0px;
  border: 0px;
  border-bottom: 2px solid transparent;
  border-radius: 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.booking-appointment-box
  .booking-appointment-right-items
  .popular-clinic-chip
  .tiems-p {
  padding: 0px 0px 0px 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #7f8384;
  text-transform: lowercase !important;
  margin-left: 2px;
  text-align: center;
}

.booking-appointment-box
  .booking-appointment-right-items
  .popular-clinic-chip.selected-clinic-chip,
.booking-appointment-box
  .booking-appointment-right-items
  .popular-clinic-chip:hover {
  border-bottom: 2px solid #2f7ba4;
  color: #2f7ba4;
  font-weight: 500;
}

.booking-appointment-box
  .booking-appointment-right-items
  .popular-clinic-chip.selected-clinic-chip
  .tiems-p,
.booking-appointment-box
  .booking-appointment-right-items
  .popular-clinic-chip:hover
  p {
  color: #2f7ba4;
} */

.css-13cymwt-control:hover,
.css-13cymwt-control:focus {
  border-color: #2f7ba4 !important;
  box-shadow: none;
}

.booking-appointment-box .css-13cymwt-control div:first-child,
.booking-appointment-box .css-t3ipsp-control div:first-child {
  width: 100%;
  height: 57px;
  background: #ffffff;
  border: 0.5px solid #ccc;
  border-radius: 4px;
  border: 0px;
  padding: 11px 20px 11px 68px !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #7f8384;
}

.booking-appointment-box .css-13cymwt-control div:first-child div,
.booking-appointment-box .css-t3ipsp-control div:first-child div {
  height: auto;
  padding: 0px !important;
}

/* .css-1fdsijx-ValueContainer {
  width: 100%;
  height: 57px;
  background: #ffffff;
  border: 0.5px solid #ccc;
  border-radius: 4px;
}

.css-1fdsijx-ValueContainer {
  border: 0px;
  padding: 11px 20px 11px 68px !important;
}

.css-1fdsijx-ValueContainer {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #7f8384;
} */

.booking-appointment-box .booking-appointment-body {
  margin-top: 25px;
}

.booking-appointment-body-item {
  margin-right: 30px;
}

.booking-appointment-body .ct-search-button {
  height: 57px;
  background: #2f7ba4;
  border-radius: 4px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  width: 152px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.booking-appointment-body .ct-search-button img {
  margin-right: 18px;
}

.doctor-search-section .doctor-search {
  margin: 0px auto 50px auto;
}

.content.pad-top {
  padding-top: 0px !important;
}

.doctor-search-section .doctor-search .card-practices-box {
  width: 100%;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(47, 123, 164, 0.11);
  border-radius: 8px;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}

.card-appointment-box {
  width: 100%;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(47, 123, 164, 0.11);
  border-radius: 20px;
  border-left: 8px solid #2f7ba4;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}

.doctor-search-section .doctor-search .card-practices-box::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0;
  bottom: 0;
  height: 100%;
  transition: all 0.5s;
  background-color: #2f7ba4;
  width: 0px;
  opacity: 0;
  width: 7px;
}

.doctor-search-section .doctor-search .card-practices-box:hover::before {
  opacity: 1;
}

.doctor-search-section
  .doctor-search
  .card-practices-box:hover
  .search-card-title:after {
  transform: scale(1.2);
}

.doctor-search-section .doctor-search .card-practices-box:last-child {
  margin-bottom: 0px;
}

.doctor-search-section .doctor-search .Pract-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 36px;
  text-align: left;
  color: #000000;
  margin-bottom: 0;
}

.title-spacing {
  margin-top: 40px;
}

.Pract-title-margin {
  margin-top: 45px;
  margin-bottom: 32px;
}

.doctor-search-section .doctor-search .card-pra-img {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  margin-right: 20px;
}

.doctor-search-section .doctor-search .img-content-c h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  margin-bottom: 0px;
  width: calc(100% - 50px);
  position: relative;
}

.doctor-search-section .doctor-search .search-result {
  margin-right: 0px;
  width: 100%;
  max-width: 100%;
  margin-top: 17px;
}

.doctor-search-section .doctor-search .search-result p:first-child {
  color: #000000;
  padding-left: 24px;
}

.doctor-search-section .doctor-search .search-result p:first-child:before {
  content: "";
  position: absolute;
  left: 0px;
  background-image: url("../../../../public/assets/images/doctors/location-b.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 14px;
  height: 14px;
  margin-top: 3px;
}

.doctor-search-section .doctor-search .search-result p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 10px;
  padding-left: 16px;
  color: #7f8384;
  position: relative;
}

.doctor-search-section .doctor-search .search-result p:last-child {
  margin-bottom: 0px;
}

.doctor-search-section .doctor-search .search-result .open-time {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #249e4d;
  margin-right: 10px;
}

.doctor-search-section .doctor-search .search-result .open-time:before {
  position: absolute;
  left: 0px;
  width: 6px;
  height: 6px;
  top: 7px;
}

.booking-appointment-body-item .book-img-icon {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background: #2f7ba4;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 10px;
  z-index: 1;
  transform: translateY(-50%);
}

.booking-page-inner-section .booking-img-box {
  /* padding-left: 50px; */
}

.booking-page-inner-section .booking-img-box img {
  width: 100%;
  height: 100%;
}

.booking-page-inner-section .booking-left-content {
  padding-top: 100px;
}

.lan-main li {
  font-size: 13px;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid transparent;
  transition: all 0.6s ease;
  cursor: pointer;
}

.lan-main li.active,
.lan-main li:hover {
  border: 1px solid #fff;
  background-color: #fff;
  color: #2f7ba4;
}

.booking-appointment-header-right {
  margin-right: 30px;
}

/* .slick-track{
  width:auto !important;
} */

.css-1jqq78o-placeholder,
.css-1dimb5e-singleValue {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
}

/* media */
@media (max-width: 1400px) {
  /* .booking-page-section .booking-appointment-box {
    bottom: 44px;
  } */
}

@media (max-width: 1300px) {
  .booking-page-inner-section .booking-left-content {
    padding-top: 33px;
  }

  .booking-left-inner-content h1 {
    font-size: 40px;
    line-height: 52px;
    margin-bottom: 30px;
  }

  .booking-page-section .booking-left-content .booking-left-inner-content p {
    margin-bottom: 29px;
  }

  .booking-appointment-header {
    flex-direction: column;
  }

  .booking-appointment-header .booking-appointment-header-right {
    margin-top: 20px;
  }
}

@media (max-width: 1200px) {
  .booking-appointment-inner-body {
    flex-wrap: wrap;
  }

  .booking-appointment-box .inner-sub-input-box .form-control {
    margin-right: 10px;
  }

  .booking-appointment-inner-body .booking-appointment-body-item {
    width: calc(50% - 10px);
  }

  .booking-appointment-body-item {
    margin-right: 10px;
  }

  .booking-appointment-body .ct-search-button {
    margin-top: 20px;
    height: 51px;
  }
}

@media (max-width: 1100px) {
  /* .booking-page-section .booking-appointment-box {
    bottom: -30px;
  } */
}

@media (max-width: 991px) {
  .booking-page-section .booking-appointment-box {
    /* position: relative; */
    max-width: 100%;
    padding: 30px;
    /* bottom: 0px; */
    /* margin-top: 40px; */
  }

  .booking-appointment-box .booking-appointment-header {
    flex-wrap: wrap;
  }

  .doctor-search-section .doctor-search .Pract-title {
    margin-top: 51px;
    margin-bottom: 21px;
  }

  .booking-page-inner-section {
    margin-top: 40px;
  }

  /*
  .booking-appointment-right-items .owl-nav .owl-next {
    right: 0px;
  }

  .booking-appointment-right-items .owl-nav .owl-prev {
    left: 0;
  }

  .booking-appointment-right-items {
    max-width: 100%;
  }

  .booking-appointment-right-items .owl-nav .owl-next,
  .booking-appointment-right-items .owl-nav .owl-prev {
    min-height: 30px;
  } */
}

@media (max-width: 870px) {
  .booking-appointment-box .booking-appointment-header h4 {
    width: 100%;
    display: block;
  }

  .booking-appointment-header-right {
    width: 100%;
    /* justify-content: flex-end; */
    justify-content: center;
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .slick-slider {
    margin: 0 !important;
  }
}

@media (max-width: 767px) {
  .booking-page-inner-section .row .col-md-6:nth-child(1) {
    order: 2;
  }

  .booking-page-inner-section .row .col-md-6:nth-child(2) {
    order: 1;
  }

  .booking-page-inner-section .row .col-lg-12:nth-child(3) {
    order: 3;
  }

  .booking-page-inner-section .col-md-8 {
    order: 2;
  }

  .booking-page-inner-section .col-md-4 {
    order: 1;
  }

  .booking-appointment-box .inner-sub-input-box .form-control:last-child {
    margin-right: 0px;
  }

  .booking-page-inner-section .booking-img-box img {
    max-width: 300px;
    margin: auto;
  }

  .booking-page-section .booking-left-content .booking-left-inner-content p {
    margin-bottom: 15px;
  }

  .booking-page-section .booking-appointment-box {
    padding: 18px;
    margin-top: 0px;
  }

  .booking-appointment-body-item .book-img-icon {
    width: 30px;
    height: 30px;
    left: 8px;
  }

  .booking-appointment-box .css-13cymwt-control div:first-child,
  .booking-appointment-box .css-t3ipsp-control div:first-child {
    padding: 11px 20px 11px 50px !important;
  }

  .booking-appointment-box .css-13cymwt-control div:first-child,
  .booking-appointment-box .css-t3ipsp-control div:first-child div {
    height: auto;
  }

  .booking-appointment-inner-body .booking-appointment-body-item {
    width: calc(50% - 5px);
  }

  .booking-appointment-inner-body {
    flex-wrap: wrap;
  }

  .booking-appointment-inner-body .booking-appointment-body-item:nth-child(1) {
    margin-right: 5px;
  }

  .booking-appointment-inner-body .booking-appointment-body-item:nth-child(2) {
    margin-right: 0px;
    margin-left: 5px;
  }

  .booking-appointment-body .ct-search-button {
    height: 45px;
    margin-left: auto;
    margin-top: 10px;
  }

  .doctor-search-section .doctor-search .card-practices-box {
    padding: 20px;
  }

  .doctor-search-section .doctor-search {
    max-width: 100%;
    margin: 0px auto 30px auto;
  }

  .search-inner-banner .search-header h2 {
    font-size: 22px;
    line-height: normal;
  }

  .search-inner-banner .search-header p {
    font-weight: 400;
    font-size: 14px;
    line-height: normal;
  }

  .booking-page-section .booking-appointment-box {
    padding: 15px;
  }
}

@media (max-width: 600px) {
  /* .booking-appointment-right-items {
    max-width: 100%;
  } */

  .inner-appointment-body {
    flex-wrap: wrap;
  }

  .inner-sub-input-box {
    margin-top: 5px !important;
  }

  .booking-page-inner-section .owl-stage {
    width: 800px !important;
  }

  .booking-page-inner-section {
    padding: 30px 0 0px 0px;
  }

  .booking-left-inner-content h1 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 18px;
  }

  .booking-left-inner-content p {
    font-size: 18px;
    line-height: 29px;
    margin-bottom: 20px !important;
  }

  .booking-appointment-inner-body .booking-appointment-body-item {
    width: 100%;
    margin: 10px 0px !important;
  }

  .doctor-search-section .doctor-search .Pract-title {
    margin-top: 34px;
    margin-bottom: 18px;
  }

  .doctor-search-section .doctor-search .card-pra-img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .doctor-search-section .doctor-search .search-result {
    margin-top: 15px;
  }

  .doctor-search-section .doctor-search .img-content-c h5 {
    font-size: 16px;
    line-height: 24px;
    width: calc(100% - 40px);
  }

  .booking-appointment-header-right {
    justify-content: center;
    flex-wrap: wrap;
  }

  .booking-appointment-box .booking-appointment-body {
    margin-top: 17px;
  }

  /* .booking-appointment-right-items .appointment-items {
    flex-wrap: wrap;
  } */

  /* .booking-appointment-box
    .booking-appointment-right-items
    .popular-clinic-chip {
    margin: 4px 20px 4px 0px;
  } */

  .doctor-search-section .doctor-search .search-result p {
    white-space: unset;
    word-break: break-all;
    word-wrap: normal;
  }

  .booking-appointment-box .booking-appointment-header h4 {
    font-size: 20px;
    line-height: 30px;
  }

  .hilight-content {
    margin: 10px 0;
  }
}

.booking-appointment-right-items .slick-slide {
  width: auto !important;
  margin: 0 20px;
}

.booking-appointment-right-items .slick-slider {
  max-width: 550px;
}

.booking-appointment-right-items .slick-list {
  max-width: 550px;
}

.slick-disabled {
  display: none !important;
}

.slick-prev {
  rotate: 180deg;
  top: -5px;
}

.slick-next {
  top: 15px;
}

.booking-appointment-right-items .slick-track {
  width: 600px !important;
}
@media (min-width:767px) {
  .w-md-75{
    width: 75%;
  }
}
@media (max-width: 375px) {
  .booking-appointment-right-items .slick-slide {
    width: 110px !important;
    margin: 0 5px;
  }
}

@media (max-width: 768px) {
  .booking-appointment-right-items .slick-list {
    max-width: 530px;
  }
}

@media (max-width: 636px) {
  .booking-appointment-right-items .slick-track {
    width: 775px !important;
  }

  .booking-appointment-right-items .slick-list {
    max-width: 376px;
  }
}

@media (max-width: 450px) {
  .booking-appointment-right-items .slick-list {
    max-width: 240px;
  }
}

/* footer stick bottom */
.footer-stick {
  min-height: calc(100vh - 230px);
}

.res-error.h4 {
  color: #b40303;
}

button.res-error {
  background-color: #b40303;
}

.modal-body.res-error {
  color: #9c3838;
}

/* card appointment-details-cnf */
.appoi-detail-section-form-conf .appoi-detail-inner-section {
  /* max-width: 1141px; */
  width: 100%;
  margin: 0 auto;
  min-height: 75vh;
}

.appoi-detail-section-form-conf .card {
  width: 100%;
  padding: 30px 20px;
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(47, 123, 164, 0.11);
  border: 0;
  margin: 30px 0 0 0;
  border-radius: 0;
}

.appoi-detail-section-form-conf .card.appointment-details-cnf {
  border-top: 6px solid #2f7ba4;
}

.appoi-detail-section {
  padding-top: 8px;
}

.appoi-detail-section-form-conf .card h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  padding: 11px 0px 11px 20px;
  background: linear-gradient(90deg, #cee8ef 0%, rgba(236, 244, 246, 0) 59.85%);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 20px;
}

.appoi-detail-section-form-conf .card strong {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 6px;
  display: block;
}

.appoi-detail-section-form-conf .card p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 30px;
}

.appoi-detail-section-form-conf
  .appoi-detail-inner-section
  .appoi-detail-section-button {
  margin-top: 40px;
  margin-bottom: 30px
}

.app-confirm-btn{
  min-width: 115px;
}

.appoi-detail-section-form-conf
  .appoi-detail-inner-section
  .appoi-detail-section-button
  .btn-primary {
  /* width: 213px; */
  background: #266793;
  border-radius: 10px;
  padding: 13px 43px;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}

.appoi-detail-section-form-conf
  .appoi-detail-inner-section
  .appoi-detail-section-button
  .btn-primary:hover {
  background-color: #47466c;
}

.appoi-detail-section-form-conf
  .appoi-detail-inner-section
  .appoi-detail-section-button
  .border-secondary {
  padding: 12px 0 !important;
  background-color: transparent !important;
  border: 0 !important;
  color: #181824 !important;
}

.appoi-detail-section-form-conf
  .appoi-detail-inner-section
  .appoi-detail-section-button
  .border-secondary
  img {
  width: 7px;
  height: 14px;
  margin-right: 15px;
}

@media (max-width: 500px) {
  .appoi-detail-section-form-conf .card {
    padding: 15px 16px;
    margin: 20px 0 0 0;
  }

  .appoi-detail-section-form-conf .card h5 {
    font-size: 18px;
    line-height: 23px;
    padding: 8px 0px 8px 13px;
  }

  .appoi-detail-section-form-conf .card strong {
    font-size: 16px;
  }

  .appoi-detail-section-form-conf .card p {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .appoi-detail-section-form-conf
    .appoi-detail-inner-section
    .appoi-detail-section-button
    .btn-primary {
    height: 45px;
    padding: 6px 23px;
    margin-bottom: 15px;
    width: 100%;
  }

  .appoi-detail-section-form-conf
    .appoi-detail-inner-section
    .appoi-detail-section-button {
    margin-top: 10px;
    flex-direction: column;
  }
}

.datepicker-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.datepicker-modal .datepicker {
  width: 30%;
  min-width: 380px;
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 295px;
  min-height: 295px;
}

.privacy-page-main h3 {
  font-size: 38px;
  margin-bottom: 45px !important;
}

.privacy-page-main h5 {
  font-size: 18px;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  text-decoration: underline;
  text-underline-offset: 6px;
  text-decoration-thickness: 2px;
  line-height: 32px;
}

.privacy-page-main p {
  font-size: 14px;
  line-height: normal;
  margin-bottom: 25px;
}

.privacy-page-main p a {
  color: #2f7ba4;
  font-weight: 600;
  text-decoration: underline;
}

@media (max-width: 520px) {
  .privacy-page-main h3 {
    font-size: 28px;
    margin-bottom: 25px !important;
  }
}

/* ----------------- UK New Start CSS ----------------- */

.book-you-next-inner {
  background-color: #266793;
  color: #fff;
  padding-top: 40px;
  position: relative;
  min-height: 634px;
  display: flex;
  align-items: center;
}

.book-you-next-inner .book-you-next-img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 51%;
  text-align: right;
}

.book-you-next-left .book-you-next-header h6 {
  color: #fff;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 110.5%;
  margin: 0;
}

.book-you-next-left .book-you-next-header h2 {
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 110.5%;
  margin-bottom: 31px;
}

.book-you-next-left p {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 30px;
}

.book-you-next-left > .btn-primary {
  border-radius: 4px;
  background-color: #fff;
  border-color: #fff;
  padding: 18px 22px;
  color: #266793 !important;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 37px;
}

.book-you-next-left > .btn-primary:hover {
  background-color: transparent;
  color: #fff !important;
}

.book-you-next-left .book-you-next-store-button {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 13px;
}

.book-you-next-left .book-you-next-store-button .btn-primary {
  border: none;
  background-color: transparent;
  padding: 0;
}

.footer-stick .search-header h3 {
  color: #001818 !important;
  font-size: 22px;
  font-weight: 500;
  line-height: 222.5%;
  max-width: 560px;
}

.doctor-search-section .main-popular-section .Pract-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 118.5%;
  color: #001818;
  margin-bottom: 46px;
}

.doctor-search-section .main-popular-inner-box .card-practices-box {
  border-radius: 20px;
  transition: all 0.4s;
}

.doctor-search-section .main-popular-inner-box .card-practices-box:hover {
  box-shadow: 0px 0px 34px 0px rgba(47, 123, 164, 0.1),
    5px 0px 0px 0px #2f7ba4 inset;
}

.doctor-search-section .main-popular-inner-box .card-practices-box::before {
  display: none;
}

.doctor-search-section
  .main-popular-inner-box
  .card-practices-box
  .card-pra-img {
  width: 158px;
  height: 48px;
  margin-right: 22px;
}

.doctor-search-section
  .main-popular-inner-box
  .card-practices-box
  .card-pra-img
  img {
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  height: 100%;
  max-width: 100%;
}

.doctor-search-section
  .main-popular-inner-box
  .card-practices-box
  .search-card-title {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #0b2432;
}

.doctor-search-section
  .main-popular-inner-box
  .card-practices-box
  .search-card-title::after {
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0px 0px 7px 0px rgba(47, 123, 164, 0.36) inset;
  width: 30px;
  height: 30px;
}

.doctor-search-section
  .main-popular-inner-box
  .card-practices-box
  .search-result
  ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 41px;
  margin-bottom: 16px;
}

.doctor-search-section
  .main-popular-inner-box
  .card-practices-box
  .search-result
  ul
  li {
  display: flex;
  align-items: center;
}

.doctor-search-section
  .main-popular-inner-box
  .card-practices-box
  .search-result
  ul
  li
  span {
  display: flex;
  align-items: center;
}

.doctor-search-section
  .main-popular-inner-box
  .card-practices-box
  .search-result
  ul
  li
  p {
  color: #3c454a;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 8px;
}

.doctor-search-section
  .main-popular-inner-box
  .card-practices-box
  .search-result
  > p {
  color: #718a97;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 163.5%;
  margin-bottom: 24px;
  padding: 0;
}

.main-popular-inner-box .main-button-section {
  gap: 16px;
}

.main-popular-inner-box .main-button-section .btn-outline-primary {
  border-radius: 5px;
  border: 1px solid rgba(47, 123, 164, 0.25);
  background: #fff;
  box-shadow: 0px 0px 19px 0px rgba(47, 123, 164, 0.1);
  padding: 10px 17px;
  color: #667781 !important;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  transition: all 0.6s ease;
}

.main-popular-inner-box .main-button-section .btn-outline-primary:hover {
  background-color: #2f7ba4;
  border: 1px solid #2f7ba4;
  color: #fff !important;
}

.cancel-form-modal-btn .btn-outline-primary {
  border-radius: 5px;
  border: 1px solid rgba(47, 123, 164, 0.25);
  background: #fff;
  box-shadow: 0px 0px 19px 0px rgba(47, 123, 164, 0.1);
  padding: 10px 17px;
  color: #667781 !important;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  transition: all 0.6s ease;
}

.cancel-form-modal-btn .btn-outline-primary:hover {
  background-color: #2f7ba4;
  border: 1px solid #2f7ba4;
  color: #fff !important;
}

.main-popular-inner-box .main-button-section .btn-info {
  padding: 10px 17px;
  color: #fff !important;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  transition: all 0.6s ease;
  border-radius: 5px;
  border: 1px solid #2f7ba4;
  background: #2f7ba4;
  box-shadow: 0px 0px 19px 0px rgba(47, 123, 164, 0.1);
}

.main-popular-inner-box .main-button-section .btn-info:hover {
  background-color: #fff;
  color: #2f7ba4 !important;
}

.main-manage-appointment-section {
  /* height: calc(100dvh - 230px);
  overflow-x: hidden;
  overflow-y: auto; */
  padding: 20px 0;
}

.main-manage-appointment-section h3 {
  color: #001818;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 118.5%;
  margin-bottom: 0px;
}

.page-title-text {
  margin-bottom: 48px;
}

.main-manage-appointment-section form {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 34px 0px rgba(47, 123, 164, 0.1);
  padding: 50px 43px;
}

.main-manage-appointment-section form .form-group.card-label .form-label {
  display: block;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
  color: #0b2432;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.main-manage-appointment-section form .form-group.card-label .form-control {
  margin-top: 0;
  border-radius: 5px;
  border: 1px solid rgba(47, 123, 164, 0.25);
  background-color: #fff !important;
  color: #667781;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 8px 16px;
  min-height: 45px;
  height: auto;
}

.main-manage-appointment-section .main-manage-appointment-button {
  gap: 20px;
  margin-top: 40px;
}

.main-manage-appointment-section
  .main-manage-appointment-button
  .ct-button-cancel {
  border-radius: 5px;
  border: 1px solid rgba(220, 84, 106, 0.25);
  background: #fff;
  box-shadow: 0px 0px 19px 0px rgba(220, 84, 106, 0.1);
  padding: 16px 50px;
  color: #dc546a;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: all 0.6s ease;
}

.main-manage-appointment-section
  .main-manage-appointment-button
  .ct-button-cancel:hover {
  background-color: #dc546a;
  color: #fff;
}

.cancel-form-modal-btn .ct-button-cancel {
  border-radius: 5px;
  border: 1px solid rgba(220, 84, 106, 0.25);
  background: #fff;
  box-shadow: 0px 0px 19px 0px rgba(220, 84, 106, 0.1);
  padding: 10px 17px;
  color: #dc546a;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: all 0.6s ease;
}

.cancel-form-modal-btn .ct-button-cancel:hover {
  background-color: #dc546a;
  color: #fff;
}

.main-manage-appointment-section
  .main-manage-appointment-button
  .ct-button-next {
  border-radius: 5px;
  border: 1px solid rgba(47, 123, 164, 0.25);
  background: #2f7ba4;
  box-shadow: 0px 0px 19px 0px rgba(47, 123, 164, 0.1);
  padding: 16px 73px;
  text-transform: capitalize;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: all 0.6s ease;
}

.main-manage-appointment-section
  .main-manage-appointment-button
  .ct-button-next:hover {
  background-color: transparent;
  color: #2f7ba4;
}

.main-date-section .app-date-input-main {
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid rgba(47, 123, 164, 0.25);
  background: #fff;
  min-height: 45px;
  padding: 0 11px;
  position: relative;
}

.main-date-section .app-date-input-main .app-date-input-d {
  min-width: 70px;
}
.main-date-section .app-date-input-main .app-date-input {
  min-width: 120px;
}
.main-date-section .app-date-input-main .app-date-input-y {
  min-width: 100px;
}

.main-date-section .app-date-input-main .css-13cymwt-control,
.main-date-section .app-date-input-main .css-t3ipsp-control {
  min-height: auto;
  border: none !important;
  box-shadow: none;
}

.main-date-section .app-date-input-main .css-1fdsijx-ValueContainer {
  padding: 0;
}

.main-date-section
  .app-date-input-main
  .css-1fdsijx-ValueContainer
  .css-qbdosj-Input,
.main-date-section
  .app-date-input-main
  .css-t3ipsp-control
  .css-1fdsijx-ValueContainer
  .css-qbdosj-Input {
  margin: 0;
  color: #667781;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
}

.main-date-section .app-date-input-main span {
  display: flex;
  align-items: center;
  padding-left: 9px;
  border-left: 1px solid rgba(47, 123, 164, 0.25);
  color: #667781;
  position: absolute;
  right: 11px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.main-date-section {
  gap: 16px;
}

.cursor-pointer {
  cursor: pointer;
}

/* .book-app-main-section {
  margin-top: 46px;
} */

.book-app-main-section .card {
  display: flex;
  /* flex-direction: row; */
  align-items: center;
  position: relative;
  border: none;
  border-radius: 20px;
  background-color: #e7f1f7;
  background-image: url(../images/card.book-app-card-3.png);
  background-size: cover;
  /* background-position: right; */
  background-repeat: no-repeat;
  padding-top: 17px;
  box-shadow: 0px -5px 0px 0px currentColor inset;
  color: #2f7ba4;
  height: 100%;
  margin-bottom: 0 !important;
}

.book-app-main-section .card:hover {
  color: #22506a;
  transition: all 0.4s;
}

.book-app-main-section .card.book-app-card-2 {
  background-color: #e7f1f7;
  box-shadow: 0px -5px 0px 0px #3e92b9 inset;
  background-image: url(../images/card.book-app-card-2.png);
  color: #3e92b9;
}

.book-app-main-section .card.book-app-card-3 {
  background-color: #e7f1f7;
  box-shadow: 0px -5px 0px 0px #2f7ba4 inset;
  background-image: url(../images/card.book-app-card-3.png);
  color: #2f7ba4;
}

.book-app-main-section .card.book-app-card-4 {
  background-color: #f3f8fc;
  box-shadow: 0px -5px 0px 0px #214359 inset;
  background-image: url(../images/card.book-app-card-4.png);
  color: #214359;
}

.book-app-main-section .card .book-shape {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  text-align: right;
}

.book-app-main-section .card .card-img-top {
  width: 80px;
  height: auto;
  /* position: relative; */
}

/* .book-app-main-section .card .card-body {
  padding: 39px;
  position: relative;
} */

.book-app-main-section .card .card-body .card-title {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  text-align: center;
}

.book-app-main-section .card .book-shape svg {
  height: 100%;
  max-width: 100%;
}

.main-book-app-main-section > h3 {
  color: #001818;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 118.5%;
}

.account-list-table-section {
  padding: 20px 0;
}

.account-list-table {
  border: 1px solid #2f7ba412;
  border-radius: 20px;
  margin-bottom: 30px;
}

.account-list-table-section > h3 {
  color: #001818;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 118.5%;
  margin-bottom: 67px;
}

.account-list-table .table {
  border-radius: 20px;
  background: #fff;
  /* box-shadow: 0px 0px 34px 0px rgba(47, 123, 164, 0.1); */
  overflow: hidden;
}
.account-list-table .table-responsive {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.account-list-table .table thead th {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 15px 25px;
  background-color: #2f7ba412;
  vertical-align: middle;
}

.account-list-table .table tbody td {
  color: #667781;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  padding: 15px 25px;
  background-color: #fff;
  vertical-align: middle;
}

.account-list-table .table tbody td:first-child,
.account-list-table .table tbody td.fw-bold {
  color: #0b2432;
  font-size: 18px;
  font-style: normal;
  font-weight: 500 !important;
  vertical-align: middle;
}

.account-list-table .btn-outline-info {
  border-radius: 3px;
  background-color: #2f7ba4;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: 1px solid #2f7ba4;
  transition: all 0.6s ease;
  min-width: 85px;
  text-align: center;
}

.account-list-table .btn-outline-info:hover {
  background-color: #fff;
  color: #2f7ba4;
}

.main-check-in-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  height: 100%;
  min-height: 640px;
}

.main-check-in-inner {
  max-width: 630px;
  width: 100%;
  margin: 0 auto;
  border-radius: 20px;
  background: #fff;
  overflow: hidden;
  box-shadow: 0px 0px 34px 0px rgba(47, 123, 164, 0.1);
}

.main-check-in-inner h3 {
  margin: 0;
  background-color: #2f7ba4;
  text-align: center;
  padding: 14px;
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 118.5%;
}

.main-check-in-inner form {
  padding: 46px 34px;
}

.main-check-in-inner form .main-check-form {
  margin-bottom: 40px;
}

.main-check-form .main-check-form-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-check-form .main-check-form-inner .form-label {
  margin: 0;
  color: #0b2432;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.main-check-form .main-check-form-inner .main-check-form-check {
  display: flex;
  align-items: center;
  gap: 42px;
}

.main-check-form .main-check-form-inner .main-check-form-check .form-check {
  margin: 0;
  display: flex;
  align-items: center;
  padding: 0;
}

.main-check-form
  .main-check-form-inner
  .main-check-form-check
  .form-check
  .form-check-label {
  margin: 0;
  padding-left: 13px;
  color: #667781;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.main-check-form
  .main-check-form-inner
  .main-check-form-check
  .form-check
  .form-check-input[type="radio"],
.main-check-form
  .main-check-form-inner
  .main-check-form-check
  .form-check
  .form-check-input[type="radio"]:focus {
  float: none;
  margin: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #667781;
  box-shadow: none;
}

.main-check-form
  .main-check-form-inner
  .main-check-form-check
  .form-check
  .form-check-input[type="radio"]:checked {
  border-color: transparent;
  background-color: transparent;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><circle cx="8" cy="8" r="7.5" fill="white" stroke="%232F7BA4"/><circle cx="8" cy="8" r="4.5" fill="%232F7BA4" stroke="%232F7BA4"/></svg>');
  background-size: 16px;
}

.main-check-in-inner form .btn-outline-primary {
  border-radius: 5px;
  border: 1px solid rgba(47, 123, 164, 0.25);
  background-color: #2f7ba4;
  box-shadow: 0px 0px 19px 0px rgba(47, 123, 164, 0.1);
  color: #fff !important;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding: 16px 60px;
  display: block;
  margin: 0 auto;
  margin-top: 60px;
  border: 1px solid #2f7ba4;
  transition: all 0.6s ease;
}

.main-check-in-inner form .btn-outline-primary:hover {
  background-color: transparent;
  color: #2f7ba4 !important;
  border-color: #2f7ba4;
}

.main-check-in-inner form .btn-outline-primary .text-primary {
  color: currentColor !important;
}

.checking-modal-section .checking-modal-body {
  padding: 15px;
}

.checking-modal-section .checking-modal-body h4 {
  color: #0b2432;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
}

.checking-modal-section
  .checking-modal-body
  .checking-modal-button
  .btn-primary {
  border-radius: 5px;
  border: 1px solid #2f7ba4;
  background-color: #2f7ba4;
  box-shadow: 0px 0px 19px 0px rgba(47, 123, 164, 0.1);
  color: #fff !important;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  transition: all 0.6s ease;
}

.checking-modal-section
  .checking-modal-body
  .checking-modal-button
  .btn-primary:hover {
  background-color: transparent;
  color: #2f7ba4 !important;
}

.appointments-main-section {
  padding: 20px 0;
}

.appointments-main-section h3 {
  color: #001818;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 118.5%;
}

.appointments-main-section .card-appointment-box {
  border: none;
  border-radius: 20px;
  background: #fff;
  padding: 46px 28px;
  transition: all 0.4s;
}

.appointments-main-section .card-appointment-box:hover {
  box-shadow: 0px 0px 34px 0px rgba(47, 123, 164, 0.1),
    5px 0px 0px 0px #2f7ba4 inset;
}

.appointments-main-section .card-appointment-box .card-appointmen-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
  gap: 32px;
}

.appointments-main-section .card-appointment-box .card-appointmen-list ul li {
  gap: 12px;
  color: #0b2432;
}

.appointments-main-section
  .card-appointment-box
  .card-appointmen-list
  ul
  li
  span {
  display: flex;
  align-items: center;
}

.appointments-main-section .card-appointment-box .card-appointmen-list ul li p {
  color: #0b2432;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 156.5%;
}

.no-data-msg {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 34px 0px rgba(47, 123, 164, 0.1);
  padding: 35px;
}
.card-appointment-margin {
  margin-bottom: 15px;
}

.title-back-arrow {
  width: 30px;
  height: 30px;
  rotate: 180deg;
  cursor: pointer;
}

.card-appointment-button {
  gap: 24px;
}

.card-appointment-button .btn-outline-primary {
  border-radius: 5px;
  border: 1px solid #2f7ba4 !important;
  background-color: #2f7ba4;
  box-shadow: 0px 0px 19px 0px rgba(47, 123, 164, 0.1);
  color: #fff !important;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 12px 8px;
  transition: all 0.6s ease;
  text-transform: capitalize;
}

.card-appointment-button .btn-outline-primary:hover {
  background-color: transparent;
  color: #2f7ba4 !important;
}

.card-appointment-button .btn-outline-success {
  border-radius: 5px;
  border: 1px solid rgba(55, 156, 83, 0.25) !important;
  background-color: #fff;
  box-shadow: 0px 0px 19px 0px rgba(47, 123, 164, 0.1);
  color: #379c53;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 12px 6px;
  transition: all 0.6s ease;
}

.card-appointment-button .btn-outline-success:hover {
  background-color: #379c53;
  color: #fff;
}

.card-appointment-button .btn-outline-danger {
  border-radius: 5px;
  border: 1px solid rgba(220, 84, 106, 0.25) !important;
  background-color: #fff;
  box-shadow: 0px 0px 19px 0px rgba(47, 123, 164, 0.1);
  color: #dc546a;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 12px 6px;
  transition: all 0.6s ease;
}

.card-appointment-button .btn-outline-danger:hover {
  background-color: #dc546a;
  color: #fff;
}

.confirm-app-modal-section .confirm-app-modal-inner {
  padding: 15px;
  text-align: center;
}

.confirm-app-modal-section .confirm-app-modal-inner > h4 {
  color: #001818;
  font-size: 22px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}

.confirm-app-modal-section .confirm-app-modal-inner .confirm-app-modal-dr {
  background-color: #2f7ba4;
  width: fit-content;
  margin: 0 auto;
  padding: 10px 15px;
  border-radius: 8px;
  color: #fff;
  margin-bottom: 15px;
}

.confirm-app-modal-section .confirm-app-modal-inner .confirm-app-modal-dr h4 {
  color: #fff;
}

.confirm-app-modal-section .confirm-app-modal-inner .confirm-app-modal-dr p {
  margin: 0;
}

.confirm-app-modal-section .confirm-app-modal-inner .confirm-app-modal-list ul {
  gap: 20px;
}

.confirm-app-modal-section
  .confirm-app-modal-inner
  .confirm-app-modal-list
  ul
  li {
  gap: 8px;
}

.confirm-app-modal-section
  .confirm-app-modal-inner
  .cancel-form-modal-btn
  .ct-button-cancel
  .text-danger {
  color: currentColor !important;
}

.confirm-app-modal-section .confirm-app-modal-button .btn-outline-primary,
.confirm-app-modal-section .confirm-app-modal-button .btn-primary {
  border: 1px solid #2f7ba4;
  border-radius: 5px;
}

.confirm-app-modal-section .confirm-app-modal-button .btn-primary .text-light {
  color: currentColor !important;
}

.confirm-app-modal-section .confirm-app-modal-button .btn-primary:hover {
  background-color: transparent;
  color: #2f7ba4 !important;
}

.confirm-app-modal-section {
  padding: 0 !important;
}

.account-list-table .btn-outline-info .text-primary {
  color: currentColor !important;
}

.modal-backdrop {
  background: rgb(11 36 50);
}

.modal-backdrop.show {
  opacity: 0.7;
}

.confirm-app-main .modal-content {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 38px 0px rgba(255, 255, 255, 0.34);
  border: none;
  overflow: hidden;
}

.confirm-app-main .modal-body:before {
  content: "";
  width: 100%;
  height: 134px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #2f7ba4;
}

.confirm-app-main .confirm-app-inner {
  position: relative;
}

.confirm-app-main .modal-body {
  padding: 28px 38px;
  padding-top: 80px;
  position: relative;
}

.confirm-app-main .modal-body .confirm-app-icon {
  border-radius: 50%;
  border: 1px solid #fff;
  width: 104px;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 9px;
  margin-bottom: 13px;
}

.confirm-app-main .modal-body .confirm-app-icon .confirm-app-icon-inner {
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0px 1px 34px rgba(0, 0, 0, 0.08);
  padding: 24px;
}

.confirm-app-main .confirm-app-inner h3 {
  color: #3c454a;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 16px;
}

.confirm-app-main .confirm-app-inner .confirm-app-modal-box {
  border-radius: 10px;
  border: 1px solid rgba(102, 119, 129, 0.1);
  background: #fff;
  padding: 20px;
  margin-bottom: 24px;
}

.confirm-app-main
  .confirm-app-inner
  .confirm-app-modal-box
  .confirm-app-modal-dr
  h4 {
  color: #0b2432;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 3px;
}

.confirm-app-main
  .confirm-app-inner
  .confirm-app-modal-box
  .confirm-app-modal-dr
  p {
  color: #667781;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 14px;
}

.confirm-app-main
  .confirm-app-inner
  .confirm-app-modal-box
  .confirm-app-modal-list
  ul {
  gap: 35px;
}

.confirm-app-main
  .confirm-app-inner
  .confirm-app-modal-box
  .confirm-app-modal-list
  ul
  li {
  color: #718a97;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  gap: 8px;
}

.confirm-app-main .modal-dialog {
  border-radius: 16px;
}

.confirm-app-main .modal-body .confirm-app-modal-button {
  gap: 16px;
}

.confirm-app-main .modal-body .confirm-app-modal-button .btn-primary {
  border-radius: 5px;
  border: 1px solid #2f7ba4 !important;
  background-color: #2f7ba4;
  box-shadow: 0px 0px 19px 0px rgba(55, 156, 83, 0.1);
  padding: 9px 24px;
  color: #fff !important;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  transition: all 0.6s ease;
}

.confirm-app-main .modal-body .confirm-app-modal-button .btn-primary:hover {
  background-color: transparent;
  color: #2f7ba4 !important;
}

.confirm-app-main .modal-body .confirm-app-modal-button .btn-outline-primary {
  border-radius: 5px;
  border: 1px solid rgba(60, 69, 74, 0.25) !important;
  background-color: #fff;
  padding: 9px 34px;
  color: #3c454a !important;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  transition: all 0.6s ease;
}

.confirm-app-main
  .modal-body
  .confirm-app-modal-button
  .btn-outline-primary:hover {
  border-color: #2f7ba4 !important;
  background-color: #2f7ba4;
  color: #fff !important;
}

.confirm-app-main .modal-body .confirm-app-modal-button .ct-button-close {
  border-radius: 50%;
  border: 1px solid rgba(220, 84, 106, 0.25) !important;
  background: #fff;
  box-shadow: 0px 0px 19px 0px rgba(220, 84, 106, 0.1);
  color: #dc546a !important;
  padding: 5px;
  display: flex;
  font-size: 22px;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  transition: all 0.6s ease;
}

.confirm-app-main .modal-body .confirm-app-modal-button .ct-button-close:hover {
  color: #fff !important;
  background-color: #dc546a;
}

.confirm-app-main
  .modal-body
  .confirm-app-modal-button
  .btn-primary
  .text-light {
  color: currentColor !important;
}
.card-padding {
  padding-top: 90px !important;
}
.cancel-form-modal-btn .ct-button-cancel .text-danger {
  color: currentColor !important;
}
.book-app-main-section {
  transform: translateY(-50%);
  position: absolute;
  width: 83.33%;
}
@media (max-width: 1535px) {
  .book-you-next-inner {
    flex-wrap: wrap;
  }

  .book-you-next-inner .book-you-next-img {
    position: unset;
    width: 100%;
  }
}
@media (max-width: 1200px) {
  .book-app-main-section .card {
    padding: 40px;
  }

  .book-app-main-section .card .card-body .card-title {
    font-size: 18px;
    margin-top: 10px;
  }

  .book-app-main-section .card .card-body {
    padding: 0;
    padding-left: 0;
  }
  .search-inner-banner {
    min-height: 100% !important;
    max-height: unset !important;
  }
  .book-app-main-section {
    transform: unset !important;
  }
  .card-padding {
    padding-top: 0 !important;
  }
  .book-app-main-section {
    transform: translateY(-50%);
    position: unset;
    width: unset;
  }
}

@media (max-width: 1120px) {
  .book-app-main-section .card {
    padding: 40px;
  }
}

@media (max-width: 991px) {
  .footer-stick-margin {
    margin-bottom: 30px;
  }

  .book-app-main-section .card {
    padding: 20px;
  }

  .book-app-main-section .card .card-img-top {
    width: 80px;
    height: 80px;
  }

  .account-list-table .table thead th,
  .account-list-table .table tbody td:first-child,
  .account-list-table .table tbody td.fw-bold,
  .account-list-table .table tbody td {
    font-size: 15px;
    padding: 9px 18px;
  }

  .account-list-table .btn-outline-info {
    font-size: 15px;
    min-width: auto;
  }

  .account-list-table .table {
    width: 990px;
    max-width: 990px;
  }

  .account-list-table-section > h3 {
    margin-bottom: 25px;
    font-size: 22px;
    line-height: normal;
  }

  .appointments-main-section
    .card-appointment-box
    .card-appointmen-list
    ul
    li
    p {
    font-size: 16px;
    line-height: normal;
  }

  .card-appointment-button {
    gap: 14px;
  }

  .card-appointment-button .btn-outline-primary,
  .card-appointment-button .btn-outline-success,
  .card-appointment-button .btn-outline-danger {
    font-size: 14px;
    padding: 8px;
  }
}

@media (max-width: 767px) {
  .doctor-search-section
    .main-popular-inner-box
    .card-practices-box
    .search-result
    ul {
    gap: 15px;
  }

  .main-manage-appointment-section {
    padding: 20px 0;
  }

  .main-manage-appointment-section h3 {
    font-size: 24px;
    line-height: normal;
    margin-bottom: 0px;
  }

  .page-title-text {
    margin-bottom: 16px;
  }

  .main-manage-appointment-section form {
    padding: 25px;
  }

  .main-manage-appointment-section .main-manage-appointment-button {
    margin-top: 20px;
  }

  /* .book-app-main-section .card {
    min-height: 180px;
  } */

  .book-app-main-section .card .card-body .card-title {
    font-size: 18px;
  }

  .book-app-main-section .card .card-img-top {
    width: 60px;
    height: 60px;
  }

  .book-app-main-section {
    margin-top: 25px;
  }

  .main-book-app-main-section > h3 {
    font-size: 22px;
    line-height: normal;
  }

  .account-list-table .table thead th,
  .account-list-table .table tbody td:first-child,
  .account-list-table .table tbody td.fw-bold,
  .account-list-table .table tbody td {
    font-size: 14px;
    padding: 8px 12px;
  }

  .account-list-table .btn-outline-info {
    font-size: 14px;
    min-width: auto;
  }

  .account-list-table-section > h3 {
    margin-bottom: 15px;
  }

  .account-list-table-section {
    padding: 30px 0;
  }

  .main-check-in-inner h3 {
    font-size: 24px;
    line-height: normal;
  }

  .main-check-in-inner form {
    padding: 25px;
  }

  .main-check-in-inner form .main-check-form {
    margin-bottom: 20px;
  }

  .main-check-in-inner form .btn-outline-primary {
    font-size: 16px;
    padding: 12px 40px;
    margin-top: 20px;
  }

  /* .card-appointment-button {
    justify-content: start !important;
  } */

  .appointments-main-section .card-appointment-box .card-appointmen-list ul {
    gap: 15px;
    flex-wrap: wrap;
  }

  .appointments-main-section
    .card-appointment-box
    .card-appointmen-list
    ul
    li
    p {
    font-size: 14px;
  }

  .appointments-main-section .card-appointment-box {
    padding: 20px;
  }
}

@media (max-width: 600px) {
  .book-you-next-left .book-you-next-header h2 {
    color: #fff;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 110.5%;
    margin-bottom: 18px;
  }

  .book-you-next-left p {
    font-size: 16px;
  }

  .book-you-next-inner {
    min-height: unset;
  }

  .book-you-next-left {
    margin-bottom: 15px;
  }

  .footer-stick .search-header h3 {
    font-size: 18px;
    line-height: normal;
  }

  .doctor-search-section
    .main-popular-inner-box
    .card-practices-box
    .img-content-c {
    flex-wrap: wrap;
    gap: 15px;
  }

  .doctor-search-section
    .main-popular-inner-box
    .card-practices-box
    .search-card-title {
    width: 100%;
    font-size: 18px;
    padding-right: 30px;
    position: relative;
  }

  .doctor-search-section
    .main-popular-inner-box
    .card-practices-box
    .search-card-title::after {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .doctor-search-section
    .main-popular-inner-box
    .card-practices-box
    .search-result
    ul
    li
    p {
    font-size: 14px;
  }

  .doctor-search-section
    .main-popular-inner-box
    .card-practices-box
    .search-result
    > p {
    font-size: 14px;
    line-height: normal;
    margin-bottom: 15px;
  }

  .main-popular-inner-box .main-button-section .btn-outline-primary,
  .main-popular-inner-box .main-button-section .btn-info {
    font-size: 14px;
  }

  .main-manage-appointment-section form .form-group.card-label .form-label {
    margin-bottom: 8px;
    font-size: 14px;
  }

  .main-manage-appointment-section
    .main-manage-appointment-button
    .ct-button-cancel,
  .main-manage-appointment-section
    .main-manage-appointment-button
    .ct-button-next {
    padding: 10px 30px;
    font-size: 14px;
  }

  .confirm-app-modal-section .confirm-app-modal-inner > h4 {
    font-size: 20px;
  }

  .confirm-app-modal-section .confirm-app-modal-inner {
    padding: 15px 0;
  }

  .confirm-app-modal-section
    .confirm-app-modal-inner
    .confirm-app-modal-list
    ul {
    gap: 8px;
    flex-wrap: wrap;
  }

  .confirm-app-main .modal-body {
    padding: 20px;
    padding-top: 80px;
  }

  .confirm-app-main .confirm-app-inner h3 {
    font-size: 16px;
  }

  .confirm-app-main
    .confirm-app-inner
    .confirm-app-modal-box
    .confirm-app-modal-dr
    h4 {
    font-size: 18px;
  }

  .confirm-app-main
    .confirm-app-inner
    .confirm-app-modal-box
    .confirm-app-modal-dr
    p {
    font-size: 13px;
  }

  .confirm-app-main
    .confirm-app-inner
    .confirm-app-modal-box
    .confirm-app-modal-list
    ul
    li {
    font-size: 13px;
    gap: 5px;
  }

  .confirm-app-main
    .confirm-app-inner
    .confirm-app-modal-box
    .confirm-app-modal-list
    ul {
    gap: 15px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .confirm-app-main .modal-body .confirm-app-modal-button .btn-outline-primary,
  .confirm-app-main .modal-body .confirm-app-modal-button .btn-primary {
    padding: 9px 28px;
  }
}

@media (max-width: 500px) {
  .main-check-form .main-check-form-inner {
    flex-wrap: wrap;
  }

  .main-check-form .main-check-form-inner .form-label,
  .main-check-form .main-check-form-inner .main-check-form-check {
    width: 100%;
  }

  .main-check-form .main-check-form-inner .main-check-form-check {
    gap: 15px;
  }

  .main-check-form .main-check-form-inner .form-label,
  .main-check-form
    .main-check-form-inner
    .main-check-form-check
    .form-check
    .form-check-label {
    font-size: 14px;
  }

  .main-check-in-inner h3 {
    font-size: 22px;
    line-height: normal;
    padding: 10px;
  }

  .main-check-in-inner form .btn-outline-primary {
    padding: 8px 30px;
  }
}

@media (max-width: 395px) {
  .ct-cancel-spacing {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.card-margin {
  margin-bottom: 15px;
}
.clinic-slide {
  padding: 24px;
  border-radius: 10px;
  background: #f0f6f9;
}
.clinic-slide .slick-slide {
  padding: 0 10px;
}

.book-app-card-3 .card .card-body {
  text-align: center;
}
.back-btn {
  width: 10px;
  height: auto;
  
}

/* ----------------- UK New End CSS ----------------- */

/* ----- Mobile Device Start CSS ------*/
@media (max-width: 500px) {
  .main-search-section .search-img-fa,
  .book-you-next-section .book-you-next-img{
    display:none;
  }
  .navbar-brand.logo img {
    height: 26px;
    margin-bottom: 5px;
}

.bar-icon span {
  margin-bottom: 5px;
  width: 26px;
}
.menu-logo img {
  height: 32px;
}
  /* .search-inner-banner {
    background-image:none;
  } */
  .main-search-section .search-inner-banner .container{
      margin:10px 0 10px 0 !important;
  }
  .search-inner-banner .search-header p {
    font-size: 15px;
  }
  .book-app-main-section .card {
    padding: 16px;
    border-radius: 12px;
  }
  .doctor-search-section .main-popular-section .Pract-title {
    margin-bottom: 27px;
  }
  .doctor-search-section .doctor-search .card-practices-box {
    box-shadow: 0px 0px 18px rgba(47, 123, 164, 0.20);
    border-radius: 10px;
  }
  .doctor-search-section
    .main-popular-inner-box
    .card-practices-box
    .search-card-title {
    padding-right: 41px;
  }
  .doctor-search-section
  .main-popular-inner-box
  .card-practices-box
  .search-result
  ul
  li {
    align-items: flex-start;
  }
  .doctor-search-section
  .main-popular-inner-box
  .card-practices-box
  .search-result
  ul
  li
  span {
    display: block;
  }
  .main-popular-inner-box .main-button-section .btn-outline-primary {
    box-shadow: 0px 0px 14px 0px rgba(47, 123, 164, 0.1);
    padding: 6px 10px;
  }
  .main-popular-inner-box .main-button-section {
    gap: 9px;
  }
  .doctor-search-section
  .doctor-search
  .card-practices-box:hover
  .search-card-title:after {
    transform: translateY(-50%);
 }
 .booking-page-section .booking-appointment-header .booking-appointment-header-left{
  width:100%;
  text-align: center;
 }
 .booking-appointment-header .booking-appointment-header-right {
  margin-right: 0px;
}
.slick-prev {
  left: -20px;
}
.slick-next {
  right: -20px;
}
.booking-page-inner-section {
  margin-top: 0;
}
.booking-appointment-body .ct-search-button {
  height: 40px;
  width: fit-content;
}
.btn-primary {
  padding: 12px 17px 9px;
}
.booking-appointment-body .ct-search-button img {
  margin-right: 11px;
  width:18px;
}
.booking-appointment-inner-body .booking-appointment-body-item:nth-child(1){
  z-index: 9;
}
.book-you-next-left .book-you-next-header h6 {
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
}
.doctor-search-section .main-popular-inner-box .card-practices-box:hover {
  box-shadow: 0px 0px 34px 0 rgba(47, 123, 164, 0.1),
  0px 4px 0px 0px #2f7ba4 inset;
}
.book-you-next-left .book-you-next-header h2 {
  font-size: 26px;
  line-height: 36px;
  margin:12px 0 25px 0;
}
.book-you-next-left > .btn-primary {
  padding: 10px 18px;
  margin-bottom: 32px;
}
.book-you-next-inner {
  padding: 20px 0;
}
.footer {
  padding: 13px 0 0 0;
}
.footer-title {
  margin: 8px 0;
  font-size: 14px;
  font-weight: 400;
}
.footer-bottom {
  padding: 9px 0px 5px;
}
/* .doctor-search-section .doctor-search .Pract-title {
  margin-top: 13px;
} */
.appoi-nav-main .appoi-nav-inner-main .back-button {
  font-size: 16px;
  padding: 0 0 10px 0;
}
.doctor-search-section{
  padding:0;
}
.main-popular-section .appoi-nav-main {
  padding: 0;
}
.book-you-next-section .d-main-section {
  margin-top: 10px;
}
.book-you-next-section .d-main-section.mt-spacing{
  margin-top: 100px;
}
.doc-info-left .doctor-img img{
    aspect-ratio: 1;
    object-fit: cover;
}
.main-body .card.appoin-date-section .schedule-cont {
  width: 100%;
  max-width: 500px;
}
.main-body .card.appoin-date-section .schedule-cont .time-slot ul {
  margin-right: 0;
  margin-left: 0;
  display:flex;
  flex-wrap: wrap;
  gap:6px;
}
.main-body .card.appoin-date-section .schedule-cont .time-slot ul  h5{
  width:100%;
  font-size: 16px;
}
.main-body .card.appoin-date-section .schedule-cont .time-slot li {
  float: inherit;
  width: fit-content;
  /* min-width:60px; */
}
.main-body .card.appoin-date-section .schedule-cont .time-slot li .timing {
  margin: 0;
  padding: 4px 10px;
}
.appoi-detail-left {
  box-shadow: rgba(100, 100, 111, 0.3) 0px 0px 5px 0px;
  padding: 23px  0;
  height:auto;
}
.appoi-detail-inner-section .appoi-detail-left .booking-info .inner-box-book {
  max-width: 100%;
  text-align: center !important;
}
.appoi-detail-inner-section .appoi-detail-right {
  padding: 0 0 15px 0;
  margin: 0px auto;
}
.appoi-detail-right .card.appoin-date-section,
.main-manage-appointment-section form,
.appoi-detail-inner-right {
  box-shadow: rgb(100 100 111 / 35%) 0px 0px 11px 0px;
}
.appoi-detail-right .card.appoin-date-section .schedule-cont {
  padding: 20px 15px;
}
.appoi-detail-section-form-conf .card h5 {
  font-size: 16px;
  line-height: 28px;
}
.appoi-detail-inner-section .appoi-detail-left .booking-info  .inner-box-book .time-content .d-flex{
  justify-content: center;
}
.appoi-booking-modal .modal-dialog .modal-body .form-group select.modal-input {
  padding-right: 35px;
}
.main-manage-appointment-section form {
  padding: 20px;
  border-radius: 11px;
}
.main-manage-appointment-section form .form-group.card-label .form-control {
  min-height: 40px;
}
.main-date-section .app-date-input-main {
  min-height: 40px;
  padding: 0 7px;
  flex: 1 1 100px;
}
.main-date-section .app-date-input-main .app-date-input-y,
.main-date-section .app-date-input-main .app-date-input-d,
.main-date-section .app-date-input-main .app-date-input {
  width: 100%;
}
.appoi-detail-inner-right {
  padding: 20px;
}
.modal-footer .ct-apt-btn {
  width: fit-content;
}
}